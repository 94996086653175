export interface MatchedProfileInfo {
  id: string;
  firstName: string;
  fileName?: string;
  jobTitle: string;
  middleName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
}

export interface CommonResponse {
  message: string;
  status: number;
  total: number;
}
export interface MatchedProfileResponse extends CommonResponse {
  data: Array<MatchedProfileInfo>;
}

export interface PaginationParams {
  skip: number;
  take: number;
}

export interface SortingParams {
  sortby: string;
  order: 'asc' | 'desc';
}

export interface MatchedProfileParams extends SortingParams, PaginationParams {
  resumeId: string;
  search: string;
}

export interface SKilsAliasInfo {
  id: string;
  skillId: string;
  aliasName: string;
  status: boolean;
}
export interface SkillInfo {
  createdAt: string;
  id: string;
  name: string;
  status: boolean;
  primary: boolean;
  alias: Array<SKilsAliasInfo>;
}
export interface SkillReponse extends CommonResponse {
  data: Array<SkillInfo>;
}
export interface LoadersState {
  tableLoader: boolean;
  filterLoader: boolean;
}

export interface TableInfo {
  list: any[];
  total: number;
  searchText: string;
}

export interface UserFilterList {
  error: string;
  list: any[];
  open: boolean;
  uploadedBySearch: string;
}

export type CheckBoxId = string;

export interface SourceInfo {
  id: number;
  name: string;
  order: number;
  status: boolean;
}
export interface SourceResponse extends CommonResponse {
  data: Array<SourceInfo>;
}
export type DocumentTypeInfo = SourceInfo;

export interface DocumentTypeRespone extends CommonResponse {
  data: Array<DocumentTypeInfo>;
}
export interface WorkAuthInfo {
  id: string;
  name: string;
  status: boolean;
}
export interface WorkAuthResponse extends CommonResponse {
  data: Array<WorkAuthInfo>;
}

export enum StatusData {
  INPROGRESS = 'inProgress',
  READYFORREVIEW = 'readyForReview',
  REJECTED = 'rejected',
  PARSINGFAILED = 'parsingFailed',
  ACCEPTED = 'accepted',
  REVIEWED = 'reviewed',
  PUBLISHED = 'published',
  CLOSED = 'closed',
  DISCARDED = 'discarded',
}

export interface Discard {
  createdAt: string;
  id: string;
  fileId: string;
  candidateId: string;
  firstName: string;
  lastName: string;
  type: string;
  sourceId: number;
  jobPostingId: string;
  status: string;
  overallScore: number | null;
  analyticsReceivedAt: string;
  reviewedAt: string | null;
  file: {
    fileName: string;
  };
  source: {
    id: number;
    name: string;
  };
  job: {
    id: string;
    jobTitle: string;
    jobDescription: string;
    customer: {
      id: string;
      name: string;
      imageId: string | null;
    };
  };
  uploadedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
}
