import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          color: '#000000 !important',
          fontFamily: 'Red Hat Display SemiBold !important',
          fontSize: '18px !important',
          height: '200px',
          maxWidth: '500px',
          width: '600px',
        },
      }}>
      <DialogTitle
        sx={{
          color: '#000000 !important',
          fontFamily: 'Red Hat Display SemiBold !important',
          fontSize: '18px !important',
        }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            color: '#000000 !important',
            fontSize: '16px !important',
            marginTop: '1rem !important',
          }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            height: '30px !important',
          }}
          onClick={onClose}
          className="rec-add-btn cancel-btn-notes mr-10">
          No
        </Button>
        <Button
          sx={{
            height: '30px !important',
          }}
          onClick={onConfirm}
          className="rec-add-btn ad-btn-notes mr-10">
          Yes, proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};
