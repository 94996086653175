import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  custominput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f3f3f3 !important',
      padding: '12.5px 14px 12.5px 20px !important',
    },
    width: '100%',
  },
  logout: {
    color: '#BF0000 !important',
  },
  optiondetails: {
    '& li': {
      borderBottom: '1px solid #E0E0E0',
      color: '#292929',
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      lineHeight: '55px',
      listStyle: 'none',
      padding: '0',
    },
    margin: '0px',
    padding: '0 15px',
  },
  passwordinput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f3f3f3 !important',
      padding: '12.5px 50px 12.5px 20px !important',
    },
    width: '100%',
  },
  profilebox: {
    '& img': {
      background: '#eee',
      borderRadius: '50%',
      height: '80px',
      padding: '5px',
      width: '80px',
    },
    display: 'flex',
  },
  profilecard: {
    '& h6': {
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      margin: '10px 0',
    },

    '& p': {
      color: '#979598',
      fontFamily: 'Red Hat Display SemiBold',
      fontSize: '14px',
      textAlign: 'right',
      textDecoration: 'underline',
    },
    margin: '0 auto !important',
    marginRight: '25px !important',
    width: '442px',
  },
  profilecard1: {
    '& h6': {
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      margin: '10px 0',
    },

    '& p': {
      color: '#979598',
      fontFamily: 'Red Hat Display SemiBold',
      fontSize: '14px',
      textAlign: 'right',
      textDecoration: 'underline',
    },
    margin: '0 auto !important',
    marginRight: '25px !important',
    width: '400px',
  },

  profiledetails: {
    '& li': {
      '& span': {
        display: 'inline-block',
        fontFamily: 'Red Hat Display SemiBold',
        width: '55px',
      },
      color: '#292929',
      display: 'flex',
      fontFamily: 'Red Hat Display Bold',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '18px',
      listStyle: 'none',
      margin: '0 0 5px 0',
    },
    margin: '0px',
    padding: '0 0px 0 30px',
  },
}));

export default useStyles;
