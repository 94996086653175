import { CircularProgress, Typography } from '@mui/material';
import React from 'react';

// import imageLoader from '../assets/images/Liberty-loader.gif';
type Props = {
  pageCenterLoadingHide?: boolean;
  className?: string;
  loaderColor?: string;
};
export const Loader: React.FC<Props> = (props: Props) => {
  const { pageCenterLoadingHide = false, className = '', loaderColor } = props;
  return (
    <Typography
      component={'div'}
      className={className}
      sx={
        pageCenterLoadingHide
          ? {}
          : {
              left: ' 50%',
              position: 'fixed',
              top: ' 50%',
              transform: 'translate(-50%, -50%)',
            }
      }>
      <CircularProgress
        sx={{ color: loaderColor ? loaderColor : '#340000' }}
        size={80}
      />
    </Typography>
  );
};
