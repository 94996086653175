import React from 'react';

export default function userIconAvatar({ Color }: { Color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      fill="none"
      viewBox="0 0 14 16">
      <path
        fill={Color ? Color : '#fff'}
        d="M11.79 15.837H1.776A1.78 1.78 0 010 14.062v-.784a6.8 6.8 0 012.223-5.033c.59-.536 1.47-.61 2.14-.178.7.45 1.537.69 2.421.69.885 0 1.72-.24 2.422-.69a1.765 1.765 0 012.14.178 6.789 6.789 0 012.223 5.033v.784a1.78 1.78 0 01-1.779 1.775zm-8.383-7.44c-.287 0-.563.108-.774.3a6.195 6.195 0 00-2.024 4.58v.785c.003.643.52 1.16 1.164 1.163h10.014c.643 0 1.163-.52 1.163-1.163v-.784a6.2 6.2 0 00-2.023-4.58 1.152 1.152 0 00-1.396-.116c-.8.514-1.752.786-2.753.786a5.068 5.068 0 01-2.752-.786 1.126 1.126 0 00-.619-.184z"
      />
      <path
        fill={Color ? Color : '#fff'}
        d="M6.785 8.061a3.955 3.955 0 01-3.95-3.95c0-2.176 1.77-3.949 3.95-3.949a3.955 3.955 0 013.95 3.95 3.957 3.957 0 01-3.95 3.95zm0-7.283a3.337 3.337 0 100 6.674 3.337 3.337 0 000-6.674z"
      />
    </svg>
  );
}
