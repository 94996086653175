import {
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  styled,
  Typography,
} from '@mui/material';
import DustBinIcon from 'components/SvgAsCode/dustBinIcon';
import ReadyForReviewIcon from 'components/SvgAsCode/readyForReviewIcon';
import UnknownIcon from 'components/SvgAsCode/unknownIcon';
import React, { useEffect, useRef, useState } from 'react';
import { ReplaceData } from 'services/CommonServices';
import { RESUME_ENDPOINT } from 'utils/Constant';
import { StatusData } from 'utils/type';

const style = {
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  bottom: '0%',
  boxShadow: 24,
  height: 768,
  p: 4,
  padding: '10px',
  position: 'fixed' as const,
  right: '0%',
  top: '0%',
  width: 544,
};
// Custom styled Backdrop
const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0)',
}));

export default function DiscardModalPopup({
  open,
  ClickFn,
  headerShown,
  reloadCall,
  Loader,
  LoaderFn,
  JobTitle,
  OpenUploadModal,
  Customer,
  DiscardValue,
  setOpenPdfPreview,
}: {
  open: boolean;
  ClickFn: () => void;
  reloadCall: () => void;
  headerShown?: boolean;
  SubmitFn?: () => void;
  Success?: boolean;
  Loader?: boolean;
  LoaderFn?: () => void;
  JobTitle?: string;
  Customer?: string;
  OpenUploadModal?: () => void;
  DiscardValue?: { id: string; fileId: string };
  setOpenPdfPreview: (src: any) => void;
}) {
  const [opn, setOpn] = useState(false);
  const [Discard, setDiscard] = useState(false);
  const ref = useRef<any>();
  useEffect(() => {
    setOpn(open);
  }, [open]);

  function handleClose() {
    setDiscard(false);
    ClickFn();
  }

  function handleReason() {
    LoaderFn && LoaderFn();
    ReplaceData(
      DiscardValue?.id,
      {
        comments: '',
        status: StatusData.DISCARDED,
      },
      RESUME_ENDPOINT.ResumeValidationStatus,
      true,
    )
      .then((er: any) => {
        handleClose();
        reloadCall && reloadCall();
      })
      .catch((er) => console.warn(er))
      .finally(() => {
        LoaderFn && LoaderFn();
      });
  }

  return (
    <div>
      <Modal
        open={opn}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        BackdropComponent={CustomBackdrop}>
        <>
          <div className="modalBackdrop" onClick={handleClose} />
          <Box
            ref={ref}
            sx={style}
            className="Readyforreviewinnerbox"
            style={Loader ? { cursor: 'progress' } : {}}>
            {Loader && (
              <Typography
                component={'div'}
                sx={{
                  position: 'absolute',
                  right: '50%',
                  top: ' 50%',
                  zIndex: 1500,
                }}>
                <CircularProgress sx={{ color: '#bf0000' }} size={40} />
              </Typography>
            )}
            {Loader && (
              <div
                style={{
                  backgroundColor: '#00000033',
                  bottom: '0px',
                  left: '0px',
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  zIndex: 2,
                }}
              />
            )}
            <header>
              <div className="heading">
                <span>Ready for Review</span>{' '}
                <span
                  style={{ marginLeft: '11px', marginTop: '2px' }}
                  className="reviewicon">
                  <ReadyForReviewIcon />
                </span>
              </div>
              <div
                className="closebtn"
                onClick={handleClose}
                style={{ zIndex: 3 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18">
                  <path
                    fill="#000"
                    d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
                  />
                </svg>
              </div>
            </header>
            <div
              className="customertitle"
              style={
                headerShown
                  ? {
                      marginBottom: '15.5px',
                      paddingBottom: '18px',
                    }
                  : {
                      marginBottom: '15.5px',
                      paddingBottom: '13.5px',
                    }
              }>
              {headerShown && (
                <>
                  <div className="company">
                    <span className="title">Customer</span>
                    <span className="value">{Customer ? Customer : ''}</span>
                  </div>
                  <div className="company">
                    <span className="title">Job Title</span>
                    <span className="value">{JobTitle ? JobTitle : ''}</span>
                  </div>
                </>
              )}
            </div>
            <main>
              <div
                className="dashedbox"
                style={
                  Discard
                    ? { paddingBottom: '32px', paddingTop: '24px' }
                    : { paddingBottom: '37px', paddingTop: '31px' }
                }>
                {Discard ? (
                  <>
                    <div className="DustbinIcon">
                      <DustBinIcon />
                    </div>
                    <div className="dustbinTxt">
                      <div className="txt">
                        You are about to discard the invalid Resume file{' '}
                      </div>
                    </div>
                    <div className="mainBtn">
                      <div className="btn discard" onClick={handleClose}>
                        cancel
                      </div>
                      <div
                        className="btn upload btn-red"
                        onClick={handleReason}>
                        Discard
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="icon">
                      <UnknownIcon />
                    </div>
                    <div className="btnContainer">
                      <div
                        className="btn"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setOpenPdfPreview &&
                          setOpenPdfPreview((pre) => ({
                            ...pre,
                            candidateResume: true,
                            fileId: DiscardValue?.fileId,
                            open: true,
                            resumeId: DiscardValue?.id,
                          }))
                        }>
                        Download File
                      </div>
                    </div>
                    <div className="para">
                      <div className="txt">
                        The document you uploaded doesn&apos;t look like a
                        resume or the system could not read the document. You
                        can either upload a proper document or discard the
                        current file. Please choose from the options below.
                      </div>
                    </div>
                    <div className="mainBtn">
                      <div
                        className="btn discard"
                        onClick={() => setDiscard(true)}>
                        Discard Resume
                      </div>
                      <div className="btn upload" onClick={OpenUploadModal}>
                        Upload Resume
                      </div>
                    </div>
                  </>
                )}
              </div>
            </main>
          </Box>
        </>
      </Modal>
    </div>
  );
}
