import { Box, FormHelperText, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { ReactElement } from 'react';

type DatePickerProps = {
  placeHolder?: string;
  onChange?: (src: any) => void;
  value?: any;
  error?: any;
  name?: string;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
  className?: string;
  views?: string;
};

const CustomDatePicker = (props: DatePickerProps): ReactElement => {
  const [openDatePicker, setOpenDatePicker] = React.useState<boolean>(false);
  const [showingFormatedValue, setShowingFormatedValue] =
    React.useState<boolean>(true);
  const {
    placeHolder,
    onChange,
    value,
    error,
    name,
    minDate,
    maxDate,
    disabled = false,
    className = '',
    views,
  } = props;

  const handleChange = (e: any) => {
    const formattedValue = e ? moment(new Date(e)).format('YYYY-MM-DD') : '';
    onChange && onChange(formattedValue);
  };

  return (
    <Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Custom input"
          // value={value ? value : ''}
          value={value || null}
          minDate={minDate}
          maxDate={maxDate}
          // onChange={(e) => {
          //   onChange &&
          //     onChange(e ? moment(new Date(e)).format('YYYY-MM-DD') : '');
          // }}
          onChange={handleChange}
          openTo={
            views && views === 'MM/YYYY'
              ? 'month'
              : views === 'YYYY'
              ? 'year'
              : undefined
          }
          views={
            views === 'MM/YYYY'
              ? ['month', 'year']
              : views === 'YYYY'
              ? ['year']
              : undefined
          }
          disabled={disabled}
          open={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          disableOpenPicker={true}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <Box
              sx={{
                '& .MuiInputAdornment-positionEnd': {
                  alignItems: 'center',
                  alignSelf: 'center',
                  justifyContent: 'center',
                },
                '& svg': {
                  color: 'grey !important',
                },
                alignItems: 'center',
                background: '#f3f3f3',
                display: 'flex',
              }}>
              {InputProps?.endAdornment}
              <input
                {...inputProps}
                onClick={() => setOpenDatePicker(true)}
                ref={inputRef}
                value={
                  !openDatePicker &&
                  !showingFormatedValue &&
                  inputProps.value &&
                  views !== 'YYYY'
                    ? moment(inputProps.value, 'MMM YYYY').format('MM-YYYY')
                    : !openDatePicker &&
                      inputProps.value &&
                      views &&
                      views === 'MM/YYYY' &&
                      showingFormatedValue
                    ? moment(inputProps.value, 'MMM YYYY').format('MMM-YYYY')
                    : !openDatePicker &&
                      inputProps.value &&
                      views &&
                      views === 'YYYY' &&
                      showingFormatedValue
                    ? moment(inputProps.value).format('YYYY')
                    : inputProps.value !== 'invalid date'
                    ? inputProps.value
                    : ''
                }
                name={name}
                placeholder={placeHolder}
                className={className}
                onMouseMoveCapture={() => setShowingFormatedValue(false)}
                onMouseLeave={() => setShowingFormatedValue(true)}
              />
            </Box>
          )}
        />
      </LocalizationProvider>
      {error && (
        <FormHelperText className="errorMessage">{error}</FormHelperText>
      )}
    </Typography>
  );
};

export default CustomDatePicker;
