import React from 'react';

export default function ProjectSkillsAddSvg({
  Selected,
}: {
  Selected?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      fill="none"
      viewBox="0 0 26 20"
      style={{ cursor: 'pointer' }}>
      <rect
        width="25.5"
        height="19.5"
        x="0.25"
        y="0.25"
        fill="#F8F8FA"
        rx="3.75"
      />
      <rect
        width="25.5"
        height="19.5"
        x="0.25"
        y="0.25"
        stroke="#0262B9"
        strokeWidth="0.5"
        rx="3.75"
      />
      <path
        stroke="#0262B9"
        strokeLinecap="round"
        strokeWidth="1.25"
        d="M8 10h10m-5-5v10"
      />
    </svg>
  );
}
