import RecruiterAndMarketerLayout from 'components/RecruiterAndMarketerLayout';
import { Candidates } from 'pages/marketer/Candidates';
import { JobPosting } from 'pages/marketer/JobPosting';
import { ViewJob } from 'pages/marketer/JobPosting/ViewJob';
import Myprofile from 'pages/marketer/Myprofile';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MarketerRoute } from '../index';

const MarketerRoutes = () => {
  const { CANDIDATES, JOBPOST, VIEWJOB, MYPROFILE } = MarketerRoute;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={JOBPOST} />} />
      <Route
        path={JOBPOST}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: JOBPOST,
              show: false,
              title: 'Job Posting',
            }}
            from={'jobpost'}
            showHeader={true}>
            <JobPosting />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={VIEWJOB}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: VIEWJOB,
              show: false,
              title: 'View Job',
            }}
            from={'viewjob'}
            showHeader={true}>
            <ViewJob />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={CANDIDATES}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: CANDIDATES,
              show: false,
              title: 'Candidates',
            }}
            from={'candidates'}
            showHeader={true}>
            <Candidates />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: MYPROFILE,
              show: false,
              title: 'Job Posting',
            }}
            from={'profile'}
            showHeader={true}>
            <Myprofile />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default MarketerRoutes;
