import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Grid, Paper, Typography } from '@mui/material';
import Loginbg from 'assets/images/login-bg.png';
import Loginlogo from 'assets/images/login-logo.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import { PublicRoutes } from 'config';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllListData, updateData } from 'services/CommonServices';
import { CreateSubmitForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { createPasswordSchema } from 'utils/ValidatorSchema';

import useStyles from '../publicStyles';

const CreatePassword: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [screen, setScreen] = useState<number>(1);

  const { handleSubmit, control, formState } = useForm<CreateSubmitForm>({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(createPasswordSchema),
  });

  useEffect(() => {
    const search = location.search;
    const hash = new URLSearchParams(search).get('hash');
    validateEmail(hash);
    if (location.pathname !== '/password') {
      setScreen(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const validateEmail = (hash: string) => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const path = `${MASTER_ENDPOINT.ValidateHash}?hash=${hash}`;
    getAllListData(path, true)
      .then((res: any) => {
        setEmail(res?.data?.email);
      })
      .catch((err: any) => {
        navigate('/login');
      });
  };

  const submitForm = (data: any) => {
    data['email'] = email;
    setLoading(true);
    updateData('', data, MASTER_ENDPOINT.SetPassword, true)
      .then((resp: any) => {
        setLoading(false);
        navigate('/login');
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography
          component="div"
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          style={{ backgroundImage: `url(${Loginbg})` }}
          className={classes.info}>
          <Paper className={classes.createPasswordWrap}>
            <form onSubmit={handleSubmit(submitForm)}>
              <a
                href="javascript:void(0);"
                style={{
                  height: 20,
                  left: 20,
                  position: 'absolute',
                  top: 20,
                  width: 20,
                }}
                onClick={() => navigate(PublicRoutes.LOGIN)}>
                <ArrowBackIosNewIcon sx={{ color: '#292929', fontSize: 20 }} />
              </a>
              <Typography>
                <img
                  src={Loginlogo}
                  alt="signup-logo"
                  style={{ display: 'table', margin: '51px auto 30px' }}
                />
              </Typography>
              <Typography style={{ paddingBottom: 3 }}>
                <label
                  style={{
                    fontFamily: 'Red Hat Display Bold',
                    fontSize: 18,
                  }}>
                  {screen === 1 ? 'Create' : 'Reset'} New Password
                </label>
              </Typography>
              <Typography>
                <label>Enter New Password</label>
                <Controller
                  control={control}
                  defaultValue=""
                  name="password"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder="New Password"
                        type={'password'}
                        value={value}
                        className={classes.passwordinput}
                        name={name}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        error={formState.errors?.password?.message}
                        extraStyle1={{ right: '13%' }}
                      />
                    );
                  }}
                />
              </Typography>
              <Typography style={{ paddingTop: 10 }}>
                <label>Confirm Password</label>
                <Controller
                  control={control}
                  defaultValue=""
                  name="confirmPassword"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder="Confirm Password"
                        type={'password'}
                        value={value}
                        className={classes.passwordinput}
                        name={name}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        error={formState.errors?.confirmPassword?.message}
                        extraStyle1={{ right: '13%' }}
                      />
                    );
                  }}
                />
              </Typography>
              <Typography style={{ paddingTop: 10 }}>
                <p className={classes.passwordText}>Password Must Contain</p>
                <p className={classes.passwordText} style={{ marginLeft: 10 }}>
                  • 1 uppercase letter
                </p>
                <p className={classes.passwordText} style={{ marginLeft: 10 }}>
                  • 1 lowercase letter
                </p>
                <p className={classes.passwordText} style={{ marginLeft: 10 }}>
                  • 1 numeric character
                </p>
                <p className={classes.passwordText} style={{ marginLeft: 10 }}>
                  • 1 unique character (!@#$%&*)
                </p>
              </Typography>
              <Typography>
                <CustomButton
                  variant="contained"
                  type="submit"
                  loading={loading}
                  name={'Submit'}
                  extraStyle={{ textTransform: 'capitalize' }}
                />
              </Typography>
            </form>
          </Paper>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CreatePassword;
