import React from 'react';

export default function DropDownArrowSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="10"
      fill="none"
      viewBox="0 0 13 10"
      {...props}>
      <path fill="#CD1717" d="M6.5 10L.87.25h11.26L6.5 10z" />
    </svg>
  );
}
