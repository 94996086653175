/* eslint-disable react/react-in-jsx-scope */
function DropzopneComponent({ baseColor }: { baseColor?: any }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      fill="none"
      viewBox="0 0 37 37">
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.4"
        strokeWidth="1.51"
        d="M24.539 24.156L18.5 18.117l-6.039 6.04M18.5 18.117v13.588"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.4"
        strokeWidth="1.51"
        d="M31.167 27.764a7.549 7.549 0 00-3.609-14.176h-1.902A12.079 12.079 0 104.912 24.609"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.4"
        strokeWidth="1.51"
        d="M24.539 24.156L18.5 18.117l-6.039 6.04"
      />
    </svg>
  );
}

export default DropzopneComponent;
