import React from 'react';

export default function ResumevaliodationFlagSvg({
  Color,
}: {
  Color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="15"
      fill="none"
      viewBox="0 0 12 15">
      <path
        fill={Color ? Color : '#00BA00'}
        d="M9.907 4.038l1.91-2.571a.946.946 0 00.094-.957.897.897 0 00-.32-.372.842.842 0 00-.46-.138H.895a.863.863 0 00-.633.274A.974.974 0 000 .937V13.73c0 .248.094.486.263.662a.877.877 0 00.633.275.877.877 0 00.634-.275.959.959 0 00.263-.662V8.072h9.337a.843.843 0 00.46-.137.897.897 0 00.321-.372.946.946 0 00-.093-.957L9.907 4.038z"
      />
    </svg>
  );
}
