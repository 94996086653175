import { Loader } from 'components/loader';
import React, { useEffect } from 'react';

const SSOLogin: React.FC = () => {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_SSO_PORTAL_URL}/auth?redirectUrl=${window.location.origin}/auth`; // Redirect to an external URL
  }, []);
  return <Loader />;
};

export default SSOLogin;
