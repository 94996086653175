import { ViewJobConstants } from 'config';
import { JobView } from 'pages/common/job/JobView';
import { Resumes } from 'pages/common/Resumes/Resumes';
import React from 'react';
import { useParams } from 'react-router-dom';

export const ViewJob: React.FC<any> = () => {
  const { jobtab } = useParams();
  if (jobtab === ViewJobConstants.Resumes) {
    return <Resumes />;
  } else {
    return <JobView />;
  }
};
