import axios from 'axios';
// import { useCustomNavigation } from 'hooks';
import store from 'store';
import {
  logout,
  refreshToken,
  updateNotAuthorized,
} from 'store/actions/UserAction';
import Storage from 'utils/Storage';

const { dispatch }: any = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
    'x-riseit-application': '2',
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const TokenStatus: any = Storage.getTokens();

    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      if (
        err.response.status === 406 ||
        responseData?.message === 'No Access To User'
      ) {
        Storage.clear();
        dispatch(updateNotAuthorized(true));
      }
      // Access Token was expired
      if (err.response.status === 401) {
        originalConfig._retry = true;
        try {
          const TokenStatus: any = Storage.getTokens();
          const rs = await instance.post('tokens/exchange', {
            refreshToken:
              TokenStatus && TokenStatus.refreshToken
                ? TokenStatus.refreshToken
                : '',
          });

          const { token } = rs.data;
          dispatch(refreshToken(token));
          Storage.updateAccessToken(token);
          return instance(originalConfig);
        } catch (_error) {
          dispatch(logout());
          Storage.clear();
          // toast.error(
          //   'Refresh token not getting! Please login again... ',
          //   alertOptions,
          // );
          window.location.replace('/');
        }
      }

      if (err.response.status === 403) {
        // dispatch(logout());
        Storage.clear();
        window.location.replace('/');
      }

      if (
        responseData.error?.message &&
        responseData.error?.message === 'Invalid Token!'
      ) {
        dispatch(logout());
        Storage.clear();
        // toast.error('Session Expired! Please login again... ', alertOptions);
        window.location.replace('/');
      }
    }

    return Promise.reject(err);
  },
);
export default instance;
