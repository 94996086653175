import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, TextField } from '@mui/material';
import React, { ReactElement, useState } from 'react';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  onBlur?: (src: any) => void;
  onFocus?: (src: any) => void;
  extraStyle?: any;
  extraStyle1?: any;
  extraInputStyle?: any;
  extraInputBaseStyle?: any;
  value?: any;
  variant?: any;
  error?: any;
  name?: string;
  type?: string;
  inputProps?: any;
  id?: string;
  maxLength?: number;
  isTextArea?: boolean;
  disabled?: boolean;
  minRows?: number;
  showTickIcon?: string;
};

const CustomInput = React.forwardRef(
  (props: InputProps, ref: any): ReactElement => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };

    const {
      placeHolder,
      className,
      onChange,
      onBlur,
      onFocus,
      extraStyle,
      extraInputStyle,
      extraInputBaseStyle,
      value,
      variant,
      error,
      name = '',
      type = 'text',
      inputProps = {},
      id = '',
      maxLength,
      isTextArea = false,
      disabled,
      minRows = 3,
    } = props;

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(event);
      }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(event);
      }
    };

    return (
      <>
        <TextField
          placeholder={variant ? '' : placeHolder}
          label={variant ? placeHolder : ''}
          className={className}
          onChange={onChange && onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          name={name}
          disabled={disabled}
          ref={ref}
          id={id}
          style={extraStyle}
          value={value}
          variant={variant}
          // fullWidth
          error={error}
          helperText={error}
          type={showPassword ? 'text' : type}
          InputProps={{
            ...inputProps,
          }}
          inputProps={{
            maxLength: maxLength,
          }}
          multiline={isTextArea ? true : false}
          minRows={isTextArea ? (minRows ? minRows : 3) : 1}
          maxRows={isTextArea ? 3 : 1}
          sx={{
            '& .MuiInputBase-formControl': isTextArea
              ? {
                  padding: '0px !important',
                }
              : extraInputBaseStyle
              ? extraInputBaseStyle
              : {},
            '& .MuiOutlinedInput-input': isTextArea
              ? {
                  height: 'auto !important',
                }
              : {},
            '& input': extraInputStyle ? extraInputStyle : {},
          }}
        />
        {type === 'password' && (
          <IconButton
            style={{ position: 'absolute', right: '15%', ...props.extraStyle1 }}
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        )}
        {/* {
          showTickIcon &&
            // <IconButton
            //   style={{ position: 'absolute' }}
            //   aria-label="toggle password visibility"
            //   // onClick={handleClickShowPassword}
            //   // onMouseDown={handleMouseDownPassword}
            //   edge="end">
            (value ? (
              <span>
                <CheckCircleIcon color="success" />
              </span>
            ) : (
              <span>
                <CancelIcon color="warning" />
              </span>
            ))
          // {/* </IconButton> */}
        {/* } */}
      </>
    );
  },
);

export default CustomInput;
