/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Box,
  Checkbox,
  CircularProgress,
  Drawer,
  FormHelperText,
  Modal,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import closefilter from 'assets/images/closefilter.svg';
import dangerIcon from 'assets/images/dangerIcon.svg';
import discardgrey from 'assets/images/discardgrey.svg';
import documentDownload from 'assets/images/document-download.svg';
import CheckIcon from 'assets/images/lightgreencheck.svg';
import redcheckbox from 'assets/images/redcheckbox.svg';
import redfilter from 'assets/images/redfilter.svg';
import InfinityScroll from 'components/InfinityScroll';
import SyncSvgIcon from 'components/SvgAsCode/SyncSvgIcon';
import { ViewJobConstants } from 'config';
import enUS from 'date-fns/locale/en-US'; // Ensure locale import
import { capitalize } from 'lodash';
import _ from 'lodash';
import debouce from 'lodash.debounce';
import moment from 'moment';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateRange } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addNewData, getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { timeSince } from 'utils/ArrayConstants';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';
import { Formatify } from 'utils/Helpers';
import {
  CheckBoxId,
  Discard,
  LoadersState,
  StatusData,
  TableInfo,
  UserFilterList,
} from 'utils/type';

import DiscardModalPopup from '../DiscardModalPopup';
import { ResumeReview } from '../ResumeReview';
import { PdfViewer } from '../ResumeReview/viewer';
import UploadResumeModal from '../UploadResumeModal';
import ResumeSearchBar from './ResumeSearchBar';
import ResumeValActionModal from './ResumeValidationActionModal';
import ResumeValSearchFilter from './ResumeValSearchFilter';

const staticParam = {
  fromDate: '',
  order: 'desc',
  reload: true,
  skip: 0,
  sortby: '',
  take: 50,
  toDate: '',
  uploadedBy: '',
};
interface Props {
  placeHolder?: string;
  type?: string;
  jobPostingId?: string;
  resumes?: boolean;
  jobData?: any;
}

type JobListing =
  | {
      id: number;
      name: string;
      order: number;
      status: boolean;
    }
  | {
      label: string;
      value;
    };

export interface childProps {
  getUpdatedResume: () => void;
}

export const ResumeValidationTable = forwardRef<childProps, Props>(
  (props: Props, ref: Ref<childProps>) => {
    const { placeHolder } = props;

    const [viewModal, setViewModal] = useState<{
      fileId: string | null;
      show: boolean;
      resumeId: string | null;
      candidateId?: string;
      readOnly?: boolean;
      fileNameResValTable?: string;
      salesReviewEnable: boolean;
    }>({
      candidateId: '',
      fileId: null,
      fileNameResValTable: '',
      readOnly: false,
      resumeId: null,
      salesReviewEnable: false,
      show: false,
    });

    const [checkedIds, setCheckedIds] = useState<CheckBoxId[]>([]);
    const [dateSelection, setDateSelection] = useState([
      {
        endDate: null,
        key: 'selection',
        startDate: null,
      },
    ]);
    const [DiscardVal, setDiscardVal] = useState<Discard | null>(undefined);
    const [openPdfPreview, setOpenPdfPreview] = useState({
      analyticalCandidateName: '',
      candidateResume: false,
      fileId: null,
      fileNameAnalytical: '',
      open: false,
      resumeId: null,
    });

    const isDateSelected =
      dateSelection[0].startDate && dateSelection[0].endDate;
    const [opening1, setopening1] = useState<boolean>(false);
    const [OpenAction, setOpenAction] = useState<boolean>(false);
    const [JobListingData, setJobListingData] = useState<JobListing[]>([
      { label: 'Select a source of the resume', value: undefined },
    ]);

    const [params, setParams] = useState<{
      fromDate: string;
      order: string | 'asc' | 'desc';
      reload: boolean;
      skip: number;
      sortby: string;
      take: number;
      toDate: string;
      uploadedBy: string;
    }>(staticParam);
    const [opening, setopening] = useState<boolean>(false);
    const [loading, setLoading] = useState<LoadersState>({
      filterLoader: false,
      tableLoader: false,
    });
    const [SyncState, setSyncState] = useState<boolean>(false);
    const [DiscardLoader, setDiscardLoader] = useState<boolean>(false);
    const [SuccessUPRESLoader, setSuccessUPRESLoader] =
      useState<boolean>(false);
    const [ResumeuploadLoader, setResumeuploadLoader] =
      useState<boolean>(false);
    const [tableInfo, setTableInfo] = useState<TableInfo>({
      list: [],
      searchText: '',
      total: 10,
    });
    const [userFilterList, setUserFilterList] = useState<UserFilterList>({
      error: '',
      list: [],
      open: false,
      uploadedBySearch: '',
    });
    const [Mount, setMount] = useState<boolean>(false);
    const history = useNavigate();
    const jobData = useRef(null);
    const { userInfo, jobTab }: any = useAppSelector(
      (store: any) => store.userLogin,
    );
    function handleOpenAction() {
      setOpenAction((p: boolean) => !p);
    }
    function handlesetDiscardLoader() {
      setDiscardLoader((p: boolean) => !p);
    }
    function CloseFn() {
      setopening((p) => !p);
      setSuccessUPRESLoader(false);
      if (jobData && jobData.current) {
        jobData.current = null;
      }
    }

    const handleClickView = (id?: string, item?: any, resumes1?: boolean) => {
      const isJobEdit = {
        id: id && id !== '' ? id : undefined,
        item: item && !_.isEmpty(item) ? item : undefined,
      };
      const Resumes1 = resumes1
        ? ViewJobConstants.Resumes
        : ViewJobConstants.myPosting;

      if (userInfo.roleId === 4) {
        history(`/marketer/job-post/view-job/${Resumes1}/` + isJobEdit.id);
      }
      if (userInfo.roleId === 3) {
        if (isJobEdit.item?.status === StatusData.PUBLISHED) {
          history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
        } else {
          history(`/recruiter/job-post/view-job/${Resumes1}/` + isJobEdit.id);
        }
      }
      if (userInfo.roleId === 5) {
        if (
          isJobEdit.item?.user?.id === userInfo.id &&
          jobTab === 0 &&
          isJobEdit.item?.status === StatusData.PUBLISHED
        ) {
          history(`/sales/job-post/view-job/${Resumes1}/${isJobEdit.id}`);
        } else {
          history(`/sales/job-post/view-job/${Resumes1}/` + isJobEdit.id);
        }
      }
    };

    function CloseFn1() {
      setopening1((p) => !p);
    }

    async function getResumeValidationsources() {
      setResumeuploadLoader(true);
      try {
        const resp = await getAllListData(
          `${RESUME_ENDPOINT.ResumeValidationsources}`,
        );
        if (resp && resp.data) {
          setJobListingData(resp.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setResumeuploadLoader(false);
      }
    }
    function handleSync() {
      getResumeValidations();
    }
    async function ResumeSubmit(sourceId: any, Files: any, ResumeId?: any) {
      const formData = new FormData();
      formData.append('resumeId', ResumeId && ResumeId.id ? ResumeId.id : '');
      formData.append('sourceId', sourceId);
      formData.append('file', Files && Files[0]);
      formData.append(
        'jobPostingId',
        ResumeId && ResumeId.type === 'jobResume' && ResumeId.jobPostingId
          ? ResumeId.jobPostingId
          : '',
      );
      formData.append('type', ResumeId && ResumeId.type ? ResumeId.type : '');
      setResumeuploadLoader(true);
      setSuccessUPRESLoader(false);

      try {
        const resp = await addNewData(
          formData,
          RESUME_ENDPOINT.ResumeValidation,
          {},
          false,
        );
        if (resp) {
          setSuccessUPRESLoader(true);
          setResumeuploadLoader(false);
          getResumeValidations();
        }
      } catch (error) {
        setSuccessUPRESLoader(false);
        toast.error(error, alertOptions);
      } finally {
        setResumeuploadLoader(false);
      }
    }

    const resetDefaultValue = (type?: string) => {
      if (type === 'date') {
        setParams((pre) => ({
          ...pre,
          fromDate: '',
          skip: 0,
          toDate: '',
          uploadedBy: '',
        }));
        setUserFilterList((pre) => ({
          ...pre,
          open: false,
          uploadedBySearch: '',
        }));
      } else if (type === 'close') {
        setCheckedIds([]);
        setDateSelection([
          {
            endDate: null,
            key: 'selection',
            startDate: null,
          },
        ]);
        setParams((pre) => ({
          ...pre,
          fromDate: '',
          skip: 0,
          toDate: '',
          uploadedBy: '',
        }));
        setUserFilterList((pre) => ({
          ...pre,
          error: '',
          open: false,
          uploadedBySearch: '',
        }));
      } else {
        setCheckedIds([]);
        setDateSelection([
          {
            endDate: null,
            key: 'selection',
            startDate: null,
          },
        ]);
        setUserFilterList((pre) => ({
          ...pre,
          error: '',
          open: false,
          uploadedBySearch: '',
        }));
      }
    };

    const handleCheckboxChange = (id: string, isChecked: boolean) => {
      setCheckedIds((prevCheckedIds) => {
        if (isChecked) {
          return [...prevCheckedIds, id];
        } else {
          return prevCheckedIds.filter((checkedId) => checkedId !== id);
        }
      });
    };

    const handleChange = (e) => {
      setTableInfo((pre) => ({
        ...pre,
        searchText: e.target.value,
      }));
      setParams((pre) => {
        return { ...pre, reload: !params.reload, skip: 0, take: 50 };
      });
    };

    const debouncedResults = useMemo(() => {
      return debouce(handleChange, 1000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getResumeValidations = () => {
      setSyncState(true);
      setLoading((pre) => ({ ...pre, tableLoader: true }));
      setTableInfo((pre) => ({
        ...pre,
        list: [],
        total: 0,
      }));
      getAllListData(
        `${RESUME_ENDPOINT.ResumeValidationResumes}?skip=${String(
          params.skip,
        )}&take=${String(params.take)}&order=${String(
          params.order,
        )}&search=${String(tableInfo.searchText)}&sortby=${
          userInfo.roleId === 5 ? 'createdAt' : String(params.sortby)
        }&fromDate=${String(params.fromDate)}&toDate=${String(
          params.toDate,
        )}&uploadedBy=${params.uploadedBy}${
          props.resumes && props.type && props.jobPostingId
            ? `&type=${props.type}&jobPostingId=${props.jobPostingId}`
            : ''
        }`,
      )
        .then((res) => {
          setTableInfo((pre) => ({
            ...pre,
            list: res.data,
            total: res.total,
          }));
          setLoading((pre) => ({ ...pre, tableLoader: false }));
        })
        .catch(() => {
          setLoading((pre) => ({ ...pre, tableLoader: false }));
        })
        .finally(() => {
          setSyncState(false);
          setMount(true);
        });
    };

    const getResumeUsers = () => {
      setLoading((pre) => ({ ...pre, filterLoader: true }));
      getAllListData(`${RESUME_ENDPOINT.ResumeValidationUsers}`)
        .then((res) => {
          setUserFilterList((pre) => ({
            ...pre,
            list: res.data,
          }));
          setLoading((pre) => ({ ...pre, filterLoader: false }));
        })
        .catch(() => {
          setLoading((pre) => ({ ...pre, filterLoader: false }));
        });
    };

    const filteredUserList = userFilterList.list.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(userFilterList.uploadedBySearch.toLowerCase());
    });

    const handleDateChange = (item) => {
      setDateSelection([item.selection]);
      const { startDate, endDate } = item.selection;
      if (startDate && endDate) {
        setUserFilterList((pre) => ({
          ...pre,
          error: '',
        }));
      } else {
        setUserFilterList((pre) => ({
          ...pre,
          error: 'Please select date.',
        }));
      }
    };

    const validateDateSelection = () => {
      const { startDate, endDate } = dateSelection[0];

      return new Promise((resolve, reject) => {
        if (userInfo.roleId === 3) {
          if (startDate && endDate) {
            resolve(true);
          } else {
            reject('Please select date.');
          }
        } else {
          resolve(true);
        }
      });
    };

    const headRow = useCallback(() => {
      return (
        <TableHead>
          <TableRow>
            <TableCell className="w-5" align="center">
              S.No
            </TableCell>
            <TableCell className={userInfo?.roleId === 3 ? 'w-15' : 'w-10'}>
              Customer
            </TableCell>
            <TableCell className="w-15">Job Title</TableCell>
            <TableCell className={userInfo?.roleId === 3 ? 'w-20' : 'w-15'}>
              Name of the candidate
            </TableCell>
            <TableCell className="w-13">uploaded date</TableCell>
            <TableCell className="w-13">uploaded by</TableCell>
            <TableCell className="w-14" align="center">
              Status
            </TableCell>
            {<TableCell className="w-5" />}
            {userInfo?.roleId === 5 && (
              <TableCell className="w-15" align="center">
                Resume Score
              </TableCell>
            )}
          </TableRow>
        </TableHead>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rowProps = useCallback(
      (row, index: number) => {
        return (
          <TableRow
            key={index}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '&>td': { cursor: 'default' },
            }}
            onClick={(e) => {
              if (row?.status === StatusData.DISCARDED) {
                return;
              }

              jobData.current =
                (row && row.job) || (row && row.source)
                  ? {
                      job: row.job ? row.job : undefined,
                      source: row.source ? row.source : undefined,
                    }
                  : undefined;
            }}>
            <TableCell align="center">
              {Number(params ? params.skip || 0 : 0) + Number(index) + 1 + '.'}
            </TableCell>
            <TableCell
              onClick={(e: any) => {
                if (row?.status === StatusData.DISCARDED) {
                  return;
                }
                e.stopPropagation();
                row && row.jobPostingId
                  ? handleClickView(row.jobPostingId, undefined, true)
                  : '';
              }}
              sx={{
                cursor:
                  row?.status === StatusData.DISCARDED ||
                  !row?.job?.customer?.name
                    ? 'default !important'
                    : 'pointer !important',
              }}>
              {row?.job?.customer?.name || '-'}
            </TableCell>
            <TableCell
              sx={{
                cursor:
                  row?.status === StatusData.DISCARDED || !row?.job?.jobTitle
                    ? 'default !important'
                    : 'pointer !important',
              }}
              onClick={(e: any) => {
                if (row?.status === StatusData.DISCARDED) {
                  return;
                }
                e.stopPropagation();
                row && row.jobPostingId
                  ? handleClickView(row.jobPostingId, undefined, true)
                  : '';
              }}>
              {row?.job?.jobTitle || '-'}
            </TableCell>
            <TableCell>
              {row?.firstName && row?.lastName
                ? `${row?.firstName} ${row?.lastName}`
                : row?.firstName
                ? row?.firstName
                : '-'}
            </TableCell>
            <TableCell>
              {row?.createdAt
                ? `${moment(row?.createdAt).format('MM/DD/YYYY h:mmA')}`
                : '-'}
            </TableCell>
            <TableCell>
              {row?.uploadedBy?.firstName && row?.uploadedBy?.lastName
                ? `${row?.uploadedBy?.firstName} ${row?.uploadedBy?.lastName}`
                : row?.uploadedBy?.firstName
                ? row?.uploadedBy?.firstName
                : '-'}
            </TableCell>
            <TableCell
              sx={{
                cursor:
                  row?.status === StatusData.DISCARDED ? 'default' : 'pointer',
              }}>
              <Typography
                component={'div'}
                className={`resval-table-status ${
                  row?.status === StatusData.REVIEWED
                    ? 'REVIEWED'
                    : row?.status === StatusData.INPROGRESS
                    ? 'INPROGRESS'
                    : row?.status === StatusData.ACCEPTED ||
                      row?.status === StatusData.READYFORREVIEW
                    ? 'ACCEPTED-RFR'
                    : row?.status === StatusData.REJECTED
                    ? 'REJECTED'
                    : row?.status === StatusData.DISCARDED
                    ? 'DISCARDED'
                    : row?.status === StatusData.PARSINGFAILED
                    ? 'PARSINGFAILED'
                    : 'table-default-clrs'
                } ${
                  row?.status === StatusData.PARSINGFAILED
                    ? 'table-parsefailed-align'
                    : ''
                }
                ${
                  row?.status === StatusData.DISCARDED
                    ? 'table-discarded-align'
                    : ''
                }
                ${
                  row?.status === StatusData.READYFORREVIEW ||
                  row?.status === StatusData.REVIEWED ||
                  row?.status === StatusData.PARSINGFAILED
                    ? 'table-cur-pointer'
                    : 'cursor-default'
                }
                ${
                  row?.status === StatusData.READYFORREVIEW
                    ? 'table-rfr-minWidth'
                    : 'table-width-auto'
                }
                `}
                onClick={(e) => {
                  if (row?.status === StatusData.DISCARDED) {
                    return;
                  }
                  if (
                    (userInfo.roleId === 3 || userInfo.roleId === 5) &&
                    row?.status === StatusData.PARSINGFAILED
                  ) {
                    row && row.id ? setDiscardVal(row) : undefined;
                    setopening1(true);
                    return;
                  }
                  ((userInfo.roleId === 3 || userInfo.roleId === 5) &&
                    row?.status === StatusData.READYFORREVIEW) ||
                  row?.status === StatusData.REVIEWED ||
                  (userInfo.roleId === 5 && row?.status === StatusData.REVIEWED)
                    ? setViewModal((pre) => ({
                        ...pre,
                        candidateId: row?.candidateId,
                        fileId: row?.fileId,
                        fileNameResValTable: row?.file?.fileName,
                        readOnly:
                          userInfo.roleId === 3 &&
                          row?.status === StatusData.REVIEWED
                            ? true
                            : false,
                        resumeId: row?.id,
                        salesReviewEnable:
                          userInfo.roleId === 5 &&
                          row?.status === StatusData.READYFORREVIEW,
                        show: true,
                      }))
                    : setViewModal((pre) => ({
                        ...pre,
                        candidateId: '',
                        fileId: null,
                        resumeId: null,
                        salesReviewEnable: false,
                        show: false,
                      }));
                }}>
                {row?.status === StatusData.PARSINGFAILED && (
                  <img src={dangerIcon} alt="dangerIcon" />
                )}
                {row?.status === StatusData.DISCARDED && (
                  <img src={discardgrey} alt="discardgrey" />
                )}
                {row?.status && row?.status === StatusData.INPROGRESS
                  ? 'In process'
                  : row?.status === StatusData.READYFORREVIEW ||
                    row?.status === StatusData.PARSINGFAILED
                  ? 'Ready for review'
                  : row?.status === StatusData.DISCARDED
                  ? 'Discarded'
                  : row?.status}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                cursor:
                  userInfo?.roleId === 5 ? 'pointer !important' : 'default',
                textAlign: 'center',
              }}>
              {userInfo?.roleId === 5 ? (
                <img
                  src={documentDownload}
                  alt="documentDownload"
                  onClick={(e) => {
                    if (userInfo?.roleId === 5) {
                      setOpenPdfPreview((pre) => ({
                        ...pre,
                        analyticalCandidateName:
                          row?.candidate?.firstName && row?.candidate?.lastName
                            ? `${row?.candidate?.firstName} ${row?.candidate?.lastName}`
                            : row?.candidate?.firstName
                            ? row?.candidate?.firstName
                            : '',
                        candidateResume: true,
                        fileId: row?.fileId,
                        fileNameAnalytical: row?.file?.fileName,
                        open: true,
                        resumeId: row.id,
                      }));
                      return;
                    }
                    if (row?.status === StatusData.DISCARDED) {
                      return;
                    }
                  }}
                />
              ) : null}
            </TableCell>
            {userInfo?.roleId !== 3 && (
              <TableCell
                align="center"
                onClick={() =>
                  setOpenPdfPreview((pre) => ({
                    ...pre,
                    analyticalCandidateName:
                      !_.isEmpty(row?.candidate) &&
                      row?.candidate?.firstName &&
                      row?.candidate?.lastName
                        ? String(row.candidate.firstName) +
                          String(row.candidate.lastName)
                        : row?.candidate?.firstName
                        ? String(row?.candidate?.firstName)
                        : '',
                    candidateResume: false,
                    fileId: null,
                    fileNameAnalytical: row?.file?.fileName,
                    open: true,
                    resumeId: row.id,
                  }))
                }>
                {row?.overallScore ? (
                  <Typography component={'div'} className="AnalyticsTotalScore">
                    <span className="txt">
                      {row?.overallScore ? row?.overallScore : 0} %
                    </span>
                  </Typography>
                ) : (
                  <Typography component={'div'}>-</Typography>
                )}
              </TableCell>
            )}
          </TableRow>
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [params.skip],
    );

    const tableData = useMemo(() => {
      return (
        <InfinityScroll
          paramState={setParams}
          scrollableTargetId={''}
          data={tableInfo.list}
          rowPorops={rowProps}
          headRow={headRow}
          count={tableInfo.total}
          columnCount={12}
          loading={loading.tableLoader}
          params={params}
        />
      );
    }, [
      params,
      tableInfo.list,
      tableInfo.total,
      rowProps,
      headRow,
      loading.tableLoader,
    ]);

    useEffect(() => {
      getResumeValidations();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    useEffect(() => {
      getResumeUsers();
      getResumeValidationsources();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      getUpdatedResume() {
        setParams((pre) => ({
          ...pre,
          skip: 0,
          take: 50,
        }));
      },
    }));
    const jobDataVal = jobData.current;
    const jobDataVal2 =
      props.resumes && props.jobData ? props.jobData : undefined;

    return (
      <Typography component={'div'}>
        <DiscardModalPopup
          open={opening1}
          ClickFn={CloseFn1}
          reloadCall={() => getResumeValidations()}
          OpenUploadModal={() => {
            CloseFn1();
            setopening(true);
          }}
          DiscardValue={DiscardVal}
          Loader={DiscardLoader}
          LoaderFn={handlesetDiscardLoader}
          headerShown={DiscardVal?.job?.jobTitle ? true : false}
          JobTitle={DiscardVal?.job?.jobTitle || ''}
          Customer={DiscardVal?.job?.customer?.name || ''}
          setOpenPdfPreview={setOpenPdfPreview}
        />
        <ResumeValActionModal open={OpenAction} ClickFn={handleOpenAction} />
        <UploadResumeModal
          open={opening}
          ClickFn={CloseFn}
          SubmitFn={ResumeSubmit}
          Success={SuccessUPRESLoader}
          Loader={ResumeuploadLoader}
          JobListingData={JobListingData}
          ResumeId={DiscardVal}
          ReadyMadeSourceId={
            jobDataVal && jobDataVal.source && jobDataVal.source.id
              ? jobDataVal.source.id
              : ''
          }
          headerShown={DiscardVal?.job?.jobTitle ? true : false}
          JobTitle={DiscardVal?.job?.jobTitle || ''}
          Customer={DiscardVal?.job?.customer?.name || ''}
        />
        <Grid container>
          <Grid item md={12}>
            <Typography component={'div'} className="recruiter-body">
              <Typography component={'div'} className="d-flex-a flex-sb">
                {props.resumes ? (
                  <Typography className="Resumes-validation-container">
                    {jobDataVal2 && Object.values(jobDataVal2).length > 0 ? (
                      <header>
                        <div className="firstbox">
                          <div className="txt">
                            {jobDataVal2 && jobDataVal2.jobTitle
                              ? jobDataVal2.jobTitle
                              : ''}
                          </div>
                          <div
                            className={`btn ${
                              jobDataVal2 &&
                              jobDataVal2.status &&
                              Formatify(jobDataVal2.status) ===
                                StatusData.PUBLISHED
                                ? 'post-badge'
                                : Formatify(jobDataVal2.status) ===
                                  StatusData.CLOSED
                                ? 'tick-badge'
                                : 'match-badge'
                            }`}>
                            {jobDataVal2 &&
                            jobDataVal2.status &&
                            Formatify(jobDataVal2.status) ===
                              StatusData.CLOSED ? (
                              <img src={CheckIcon} alt="Tick" />
                            ) : (
                              ''
                            )}
                            <span>
                              {jobDataVal2 &&
                              jobDataVal2.status &&
                              Formatify(jobDataVal2.status) ===
                                StatusData.PUBLISHED
                                ? 'Posted'
                                : Formatify(jobDataVal2.status) ===
                                  StatusData.CLOSED
                                ? 'Filled'
                                : jobDataVal2 &&
                                  jobDataVal2.status &&
                                  jobDataVal2.status
                                ? capitalize(jobDataVal2.status)
                                : ''}
                            </span>
                          </div>
                        </div>
                        <div className="secondbox">
                          <div className="txt">
                            <span className="texted company">
                              {jobDataVal2 &&
                              jobDataVal2.customer &&
                              jobDataVal2.customer.name
                                ? jobDataVal2.customer.name
                                : ''}
                            </span>{' '}
                            <span className="texted location">
                              {jobDataVal2 && jobDataVal2.city
                                ? jobDataVal2.city
                                : ''}
                              ,{' '}
                              {jobDataVal2 && jobDataVal2.state
                                ? jobDataVal2.state
                                : ''}{' '}
                              (
                              {jobDataVal2 && jobDataVal2.workType
                                ? jobDataVal2.workType
                                : ''}
                              )
                            </span>{' '}
                            <span className="month">
                              {jobDataVal2 && jobDataVal2.createdAt
                                ? timeSince(jobDataVal2.createdAt)
                                : 0}{' '}
                              ago
                            </span>
                          </div>
                        </div>
                      </header>
                    ) : (
                      ''
                    )}
                  </Typography>
                ) : (
                  <Typography className="recruter-h1">
                    Resume Validation
                  </Typography>
                )}
                <Typography component={'div'} className="d-flex-a">
                  <ResumeSearchBar
                    placeHolderText={`Search by ${
                      placeHolder
                        ? placeHolder
                        : 'Customer,Candidate Name and Job Title'
                    } `}
                    onChange={debouncedResults}
                  />
                  <Button
                    onClick={() => {
                      setUserFilterList((pre) => ({
                        ...pre,
                        open: true,
                      }));
                    }}>
                    <img src={redfilter} alt="Filter" className="red-filter" />
                  </Button>
                  <Button
                    className={`sync-loader ${
                      Mount && SyncState ? 'sync-anim' : ''
                    }`}
                    onClick={handleSync}>
                    <SyncSvgIcon />
                  </Button>
                  <Drawer
                    className="resume-validation-filter-drawer"
                    open={userFilterList.open}
                    anchor="right"
                    onClose={() =>
                      setUserFilterList((pre) => ({
                        ...pre,
                        open: true,
                      }))
                    }>
                    <Typography className="resume-validation-filter-title">
                      Filter
                      <img
                        src={closefilter}
                        alt="closefilter"
                        className="close-filter"
                        onClick={() => {
                          if (
                            !_.isEmpty(params.fromDate) ||
                            !_.isEmpty(params.toDate) ||
                            !_.isEmpty(params.uploadedBy)
                          ) {
                            resetDefaultValue('close');
                          } else {
                            resetDefaultValue();
                          }
                        }}
                      />
                    </Typography>
                    {userInfo?.roleId === 5 && (
                      <Box className="res-val-filter-box">
                        <ResumeValSearchFilter
                          staticWidth
                          onChange={(e) => {
                            setUserFilterList((pre) => ({
                              ...pre,
                              uploadedBySearch: e.target.value,
                            }));
                          }}
                          value={userFilterList.uploadedBySearch}
                        />
                      </Box>
                    )}
                    {loading.filterLoader ? (
                      <Typography
                        component={'div'}
                        sx={{
                          left: '50%',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 1500,
                        }}>
                        <CircularProgress sx={{ color: '#bf0000' }} size={40} />
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          '&::-webkit-scrollbar': {
                            width: '5px', // Adjust the width as needed
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Customize color
                            borderRadius: '4px', // Optional: rounded corners
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'transparent', // Optional: track color
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: '100%',
                          padding: '16px',
                          paddingTop: '0px !important',
                        }}>
                        <Box
                          sx={{
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#bec4c4 !important',
                              borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: 'transparent',
                            },
                            maxHeight: '210px',
                            overflowX: 'auto',
                            width: '100%',
                          }}>
                          {userInfo?.roleId === 5 &&
                            (filteredUserList?.length ? (
                              filteredUserList.map((datas, index: number) => {
                                return (
                                  <Typography
                                    component={'div'}
                                    key={index}
                                    className="res-val-checkbox">
                                    <Checkbox
                                      checkedIcon={
                                        <img src={redcheckbox} alt="redcheck" />
                                      }
                                      checked={checkedIds.includes(datas?.id)}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          datas?.id,
                                          e?.target?.checked,
                                        )
                                      }
                                      value={datas?.id}
                                    />
                                    <Typography
                                      className="pointer"
                                      component="div"
                                      onClick={(e) =>
                                        handleCheckboxChange(
                                          datas?.id,
                                          !checkedIds.includes(datas?.id),
                                        )
                                      }>
                                      {`${datas?.firstName} ${datas?.lastName}`
                                        .length > 36 ? (
                                        <Tooltip
                                          title={`${datas?.firstName} ${datas?.lastName}`}
                                          placement="top">
                                          <Typography
                                            sx={{
                                              diplay: 'inline-block',
                                              maxWidth: '275px', // adjust as per for ...
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                            }}>
                                            {datas?.firstName && datas?.lastName
                                              ? `${datas?.firstName} ${datas?.lastName}`
                                              : datas?.firstName}
                                          </Typography>
                                        </Tooltip>
                                      ) : datas?.firstName &&
                                        datas?.lastName ? (
                                        `${datas?.firstName} ${datas?.lastName}`
                                      ) : (
                                        datas?.firstName
                                      )}
                                    </Typography>
                                  </Typography>
                                );
                              })
                            ) : (
                              <Typography className="no-record-found">
                                No Records Found
                              </Typography>
                            ))}
                        </Box>
                        <Box
                          mt={2}
                          className="padd-16"
                          sx={{ padding: '0px !important' }}>
                          <DateRange
                            editableDateInputs={false}
                            onChange={(item) => {
                              handleDateChange(item);
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={
                              isDateSelected
                                ? dateSelection
                                : [
                                    {
                                      endDate: new Date(),
                                      key: 'selection',
                                      startDate: new Date(),
                                    },
                                  ]
                            }
                            rangeColors={['rgb(191 0 0 / 68%)']}
                            locale={enUS} // Pass the locale here
                          />
                          {userFilterList.error && (
                            <FormHelperText className="errorMsg pd-left-12">
                              {userFilterList.error}
                            </FormHelperText>
                          )}
                        </Box>
                        <Box className="resume-filter-centered-button-container">
                          <Button
                            disabled={
                              !dateSelection[0].startDate && !checkedIds.length
                            }
                            className="resume-filter-btn"
                            sx={{
                              backgroundColor: '#0a84f5 !important',
                              color: '#ffffff !important',
                            }}
                            onClick={() => {
                              if (
                                !_.isEmpty(params.fromDate) ||
                                !_.isEmpty(params.toDate) ||
                                !_.isEmpty(params.uploadedBy)
                              ) {
                                resetDefaultValue('date');
                              }
                              resetDefaultValue();
                            }}>
                            Reset
                          </Button>
                          <Button
                            disabled={
                              userInfo.roleId === 3
                                ? !isDateSelected
                                : !checkedIds.length &&
                                  !dateSelection[0].startDate
                            }
                            className="resume-filter-btn"
                            onClick={() => {
                              validateDateSelection()
                                .then(() => {
                                  setParams((pre) => ({
                                    ...pre,
                                    fromDate: dateSelection[0].startDate
                                      ? moment(
                                          dateSelection[0]?.startDate,
                                        ).format('YYYY-MM-DD')
                                      : '',
                                    skip: 0,
                                    toDate: dateSelection[0].endDate
                                      ? moment(
                                          dateSelection[0]?.endDate,
                                        ).format('YYYY-MM-DD')
                                      : '',
                                    uploadedBy: checkedIds.length
                                      ? checkedIds.join(',')
                                      : '',
                                  }));
                                  setUserFilterList((pre) => ({
                                    ...pre,
                                    open: false,
                                    uploadedBySearch: '',
                                  }));
                                })
                                .catch((err) => {
                                  setUserFilterList((pre) => ({
                                    ...pre,
                                    error: err,
                                  }));
                                });
                            }}>
                            Filter
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Drawer>
                </Typography>
              </Typography>
              <TableContainer
                component={Paper}
                className="recruiter-jobpost-table">
                {tableData}
              </TableContainer>
            </Typography>
          </Grid>
        </Grid>
        <Typography component={'div'} className="inner-pop-text" />
        {viewModal?.show && (
          <ResumeReview
            visible={viewModal?.show}
            resumeId={viewModal?.resumeId}
            fileId={viewModal?.fileId}
            ActionFn={handleOpenAction}
            candidateId={viewModal?.candidateId}
            readOnly={viewModal?.readOnly}
            fileNameResValTable={viewModal?.fileNameResValTable}
            salesReviewEnable={viewModal?.salesReviewEnable}
            widthType={
              userInfo.roleId === 5
                ? 'Sales'
                : userInfo.roleId === 3 && viewModal?.readOnly
                ? 'recruiterRead'
                : userInfo.roleId === 3 && !viewModal?.readOnly
                ? 'recruiter'
                : ''
            }
            onClose={(e: StatusData) => {
              if (
                [
                  StatusData.REVIEWED,
                  StatusData.REJECTED,
                  StatusData.ACCEPTED,
                ].includes(e)
              ) {
                getResumeValidations();
              }
              setViewModal((pre) => ({
                ...pre,
                candidateId: '',
                fileId: null,
                fileNameResValTable: '',
                resumeId: null,
                show: false,
              }));
            }}
          />
        )}
        {openPdfPreview.open && (
          <Modal
            open={openPdfPreview.open}
            onClose={() => {
              setOpenPdfPreview((pre) => ({
                ...pre,
                analyticalCandidateName: '',
                candidateResume: false,
                fileId: null,
                fileNameAnalytical: '',
                open: false,
                resumeId: null,
              }));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={'resume-modal view-resume'}>
              <Typography id="modal-modal-title" className="d-flex-a flex-sb">
                <Typography
                  variant="h6"
                  component="h2"
                  className="resume-header-name"
                  sx={{ transform: 'translateY(-9px)' }}>
                  {openPdfPreview?.candidateResume
                    ? 'Candidate Resume'
                    : 'Analytical Report'}
                </Typography>
                <div
                  className="closebtn"
                  onClick={() => {
                    setOpenPdfPreview((pre) => ({
                      ...pre,
                      analyticalCandidateName: '',
                      candidateResume: false,
                      fileId: null,
                      fileNameAnalytical: '',
                      open: false,
                      resumeId: null,
                    }));
                  }}
                  style={{
                    cursor: 'pointer',
                    transform: 'translateY(-12px)',
                    zIndex: 3,
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18">
                    <path
                      fill="#000"
                      d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
                    />
                  </svg>
                </div>
              </Typography>
              <Typography id="modal-modal-description">
                <Grid container spacing={2}>
                  <Grid item md={12} sm={6}>
                    <PdfViewer
                      url={
                        openPdfPreview?.candidateResume
                          ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.Files}/${openPdfPreview?.fileId}?type=pdf`
                          : `${process.env.REACT_APP_APIURL}resume-validation/analytics/report/${openPdfPreview.resumeId}`
                      }
                      hideDownload={false}
                      scale={1.4}
                      from={
                        !openPdfPreview?.candidateResume
                          ? 'AnalyticalReport'
                          : 'DocumentDownload'
                      }
                      fileName={openPdfPreview?.fileNameAnalytical}
                      analyticalCandidateName={
                        openPdfPreview?.analyticalCandidateName
                      }
                    />
                  </Grid>
                </Grid>
              </Typography>
            </Box>
          </Modal>
        )}
      </Typography>
    );
  },
);
