export const CANDIDATESTATUS = [
  'None',
  'W2 Available',
  'W2 OnAssignment',
  'W2 Prospective',
];

export const JOBCLOSINGSTATUS = [
  {
    id: 'matched',
    name: 'Matched',
  },
  {
    id: 'filled',
    name: 'Filled',
  },
  {
    id: 'lost',
    name: 'Lost',
  },
  {
    id: 'others',
    name: 'Others',
  },
];

export const WORKSTATUS = [
  {
    id: 'US Citizen',
    name: 'US Citizen',
  },
  {
    id: 'Green Card',
    name: 'Green Card',
  },
  {
    id: 'H1B Sponsored',
    name: 'H1B Sponsored',
  },
  {
    id: 'H1B (Not Sponsored)',
    name: 'H1B (Not Sponsored)',
  },
  {
    id: 'TN1 Visa',
    name: 'TN1 Visa',
  },
  {
    id: 'Other',
    name: 'Other',
  },
];

export const EDUCATIONARRAY = [
  {
    id: 'HS Diploma',
    name: 'HS Diploma',
  },
  {
    id: 'Bachelors',
    name: 'Bachelors',
  },
  {
    id: 'MBA',
    name: 'MBA',
  },
  {
    id: 'Masters',
    name: 'Masters',
  },
  {
    id: 'Doctorate',
    name: 'Doctorate',
  },
];

export const PAYRATETYPEARRAY = [
  {
    id: 'Hourly',
    name: 'Hourly',
  },
  {
    id: 'Salary',
    name: 'Salary',
  },
];

export const timeSince = (date) => {
  const seconds: any = Math.floor(
    (new Date().valueOf() - new Date(date).valueOf()) / 1000,
  );
  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
};
