import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updateData } from 'services/CommonServices';
import { changePasswordForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { changePasswordSchema } from 'utils/ValidatorSchema';

import useStyles from './CommonStyle';

const style = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  p: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 508,
};

export const ChangePassword: React.FC<any> = () => {
  const classes = useStyles();
  const { handleSubmit, control, formState, reset } =
    useForm<changePasswordForm>({
      defaultValues: {
        confirmPassword: '',
        currentPassword: '',
        newPassword: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(changePasswordSchema),
    });

  const [open, setOpen] = useState<boolean>(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);

  const resetForm = () => {
    handleClose();
    reset();
  };

  const submitForm = (data: any) => {
    setLoading(true);
    updateData('', data, MASTER_ENDPOINT.Changepassword, true)
      .then((resp: any) => {
        resetForm();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Link
        href="#"
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_SSO_PORTAL_URL}/change-password?redirectUrl=${window.location.href}`; // Redirect to an external URL
        }}
        underline="none"
        color="#292929">
        Change Password
      </Link>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form id={'changePassword'} onSubmit={handleSubmit(submitForm)}>
          <Box sx={style} className="model-widget">
            <IconButton
              aria-label="Close"
              onClick={resetForm}
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
              }}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" component="h2">
              Change Password
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Typography component={'div'}>
                <Typography>
                  <label>
                    Current Pasword<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="currentPassword"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          type={'password'}
                          value={value}
                          className={classes.passwordinput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          extraStyle1={{
                            right: '14%',
                          }}
                          error={formState.errors?.currentPassword?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography>
                  <label>
                    New Pasword<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="newPassword"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          type={'password'}
                          value={value}
                          className={classes.passwordinput}
                          name={name}
                          ref={ref}
                          extraStyle1={{
                            right: '14%',
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.newPassword?.message}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography>
                  <label>
                    Retype Password<span className="errorText">{'*'}</span>
                  </label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="confirmPassword"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          type={'password'}
                          value={value}
                          extraStyle1={{
                            right: '14%',
                          }}
                          className={classes.passwordinput}
                          name={name}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.confirmPassword?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Typography>
            </Typography>
            <Typography sx={{ paddingTop: '15px' }} />
            <Divider />
            <Typography sx={{ paddingTop: '15px' }} />
            <Typography className="model-btn" component={'div'}>
              <Button className="cancel-btn" onClick={resetForm}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                type={'submit'}
                isStyle={true}
                name={'Save Changes'}
                loading={loading}
                form={'changePassword'}
                className={'save-btn'}
              />
            </Typography>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePassword;
