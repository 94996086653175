import { Box, Modal, Tooltip, Typography } from '@mui/material';
import dividerLogo from 'assets/images/split-v.svg';
import React from 'react';
import {
  getPanelElement,
  getPanelGroupElement,
  getResizeHandleElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

import { Analytics } from './Analytics';
import { ListNotes } from './Notes/ListNotes';
import { CandidateList } from './ResumeInfo/CandidateList';
import { CreateCandidate } from './ResumeInfo/CreateCandidate';
import { PdfViewer } from './viewer';

type Props = {
  visible: boolean;
  onClose: (status: string) => void;
  resumeId: string;
  fileId: string;
  ActionFn?: () => void;
  candidateId?: string;
  readOnly?: boolean;
  widthType?: string;
  fileNameResValTable?: string;
  salesReviewEnable: boolean;
};

export const ResumeReview: React.FC<Props> = (props: Props) => {
  const {
    resumeId,
    fileId,
    visible,
    onClose,
    ActionFn,
    candidateId,
    readOnly,
    widthType,
    fileNameResValTable,
    salesReviewEnable,
  } = props;
  const [selectCandidate, setSelectCandidate] = React.useState<string>('');
  const { userInfo } = useAppSelector((store) => store.userLogin);
  const [analytics, setAnalytics] = React.useState<boolean>(false);
  const defaultLayout = [
    widthType === 'Sales'
      ? 60
      : widthType === 'recruiterRead' || widthType === 'recruiter'
      ? 55
      : 50,
    widthType === 'Sales'
      ? 40
      : widthType === 'recruiterRead' || widthType === 'recruiter'
      ? 45
      : 50,
  ];

  const refs = React.useRef<{
    groupElement?: HTMLElement;
    leftPanelElement?: HTMLElement;
    resizeHandleElement?: HTMLElement;
    rightPanelElement?: HTMLElement;
  }>({});

  React.useEffect(() => {
    const groupElement = getPanelGroupElement('group');
    const leftPanelElement = getPanelElement('left-panel');
    const rightPanelElement = getPanelElement('right-panel');
    const resizeHandleElement = getResizeHandleElement('resize-handle');

    // If you want to, you can store them in a ref to pass around
    if (refs) {
      refs.current = {
        groupElement,
        leftPanelElement,
        resizeHandleElement,
        rightPanelElement,
      };
    }
  }, []);
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={'resume-modal'}>
        <Typography id="modal-modal-title" className="d-flex-a flex-sb">
          <Typography
            variant="h6"
            component="h2"
            className="resume-header-name"
            sx={{ transform: 'translateY(-9px)' }}>
            Resume Validation
          </Typography>
          <div
            className="closebtn"
            onClick={() => onClose('')}
            style={{
              cursor: 'pointer',
              transform: 'translateY(-12px)',
              zIndex: 3,
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="none"
              viewBox="0 0 18 18">
              <path
                fill="#000"
                d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
              />
            </svg>
          </div>
        </Typography>
        <Typography id="modal-modal-description">
          <PanelGroup direction="horizontal" id="group">
            <Panel id="left-panel" defaultSize={defaultLayout[0]}>
              <PdfViewer
                url={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.Files}/${fileId}?type=pdf`}
                hideDownload={true}
                fileName={fileNameResValTable}
              />
            </Panel>
            <PanelResizeHandle
              id="resize-handle"
              className="panel-resize-handle">
              <Tooltip title={'Resize'} placement="top">
                <img src={dividerLogo} className="handle-bar" />
              </Tooltip>
            </PanelResizeHandle>
            <Panel id="right-panel" defaultSize={defaultLayout[1]}>
              {userInfo?.roleId === 3 || readOnly || salesReviewEnable ? (
                <Typography component={'div'}>
                  {selectCandidate ? (
                    <CreateCandidate
                      close={onClose}
                      resumeId={resumeId}
                      candidateId={
                        selectCandidate === 'new' ? null : selectCandidate
                      }
                      {...(ActionFn ? { ActionFn } : {})}
                      readOnly={readOnly}
                    />
                  ) : (
                    <CandidateList
                      readOnly={readOnly}
                      setSelectCandidate={setSelectCandidate}
                      resumeId={resumeId}
                      readOnlyCandidateId={candidateId}
                    />
                  )}
                </Typography>
              ) : userInfo?.roleId === 5 ? (
                <Typography component={'div'}>
                  {analytics ? (
                    <Analytics
                      setAnalytics={setAnalytics}
                      closeModal={onClose}
                      resumeId={resumeId}
                    />
                  ) : (
                    <ListNotes
                      setAnalytics={setAnalytics}
                      candidateId={candidateId}
                    />
                  )}
                </Typography>
              ) : null}
            </Panel>
          </PanelGroup>
        </Typography>
      </Box>
    </Modal>
  );
};
