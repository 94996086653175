/* eslint-disable react/jsx-no-useless-fragment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText, Typography } from '@mui/material';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import CustomButton from 'components/CustomButton/customButton';
import { Loader } from 'components/loader';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  ReplaceData,
} from 'services/CommonServices';
import {
  CreateCandidateApiResponse,
  CreateCandidateparserData,
  EducationInfo,
  ExperienceInfo,
  GeneralDetails,
  GeneralFormDate,
} from 'types';
import { RESUME_ENDPOINT } from 'utils/Constant';
import { StatusData } from 'utils/type';
import { AddGeneralInfoSchema } from 'utils/ValidatorSchema';
import { v4 as uuidv4 } from 'uuid';

import { AddNotes } from '../Notes/AddNotes';
import { AccordionView } from './Accordion';

type Props = {
  close?: (status: string) => void;
  resumeId: string;
  candidateId: string;
  ActionFn?: () => void;
  readOnly?: boolean;
};

export const accordionTypes = [
  {
    name: 'Personal Information',
    type: 'profileInfo',
  },
  {
    name: 'Experience Information',
    type: 'experienceInfo',
  },
  {
    name: 'Education Information',
    type: 'educationInfo',
  },
  {
    name: 'Skills',
    type: 'skils',
  },
];

interface DiscardRes {
  message: string;
  status: number;
}
export const CreateCandidate: React.FC<Props> = (props: Props) => {
  const { close, resumeId, candidateId, ActionFn, readOnly } = props;
  const [done, setdone] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [mount, setMount] = useState<boolean>(false);
  const [loading, setLoading] = useState<{
    button: boolean;
    common: boolean;
  }>({
    button: false,
    common: true,
  });
  const [open, setOpen] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [show, setShow] = useState<string>('');
  const [parsedInfo, setParsedInfo] = useState(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [referenceLinkedIn, setReferenceLinkedIn] = useState('');
  const ReasonRef = useRef<any>(null);
  const handleChange = (e) => {
    setMount(false);
    setText(e.target.value);
  };

  const showError = () => {
    setMount(false);
    setText('');
  };
  const handleReason = () => {
    setLoading((pre) => ({
      ...pre,
      common: true,
    }));
    ReplaceData(
      resumeId,
      {
        comments: ReasonRef.current.value,
        status: StatusData.DISCARDED,
      },
      RESUME_ENDPOINT.ResumeValidationStatus,
      false,
    )
      .then((res: DiscardRes) => {
        close(undefined);
        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
        toast.success('Discarded successfully');
      })
      .catch((er) => {
        console.error(er);
        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
      });
  };

  const ValueChecker = (MyLocaldata: any, value: any): any => {
    if (MyLocaldata && MyLocaldata[value]) {
      return MyLocaldata[value];
    } else {
      return null;
    }
  };

  const formControl = useForm<GeneralDetails>({
    defaultValues: {
      city: '',
      countryCode: '',
      documentTypeId: null,
      documentTypeNumber: null,
      educations: [],
      experience: [],
      firstName: '',
      jobTitle: '',
      lastName: '',
      linkedinUrl: '',
      middleName: '',
      notes: '',
      phoneNumber: '',
      primaryEmail: '',
      skills: [],
      skillsMatched: [],
      sourceId: '',
      state: '',
      workAuthId: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(AddGeneralInfoSchema),
  });

  const internalVerify = () => {
    linkedInVerification();
  };
  const linkedInVerification = (isomitApiCall?: boolean) => {
    if (
      (!formControl.watch('linkedinUrl') ||
        !formControl.watch('linkedinUrl').trim() ||
        (referenceLinkedIn === formControl.watch('linkedinUrl').trim() &&
          show !== 'success')) &&
      !isomitApiCall
    ) {
      setLoading((pre) => ({
        ...pre,
        button: true,
      }));
      submit(formControl.watch());
      return;
    }
    if (!isomitApiCall && show == 'success') {
      submit(formControl.watch());
      return;
    }
    setIsFocused(true);
    setShow('');
    if (!isomitApiCall) {
      setLoading((pre) => ({
        ...pre,
        button: true,
      }));
    }
    setReferenceLinkedIn(formControl.watch('linkedinUrl'));
    getAllListData(
      `${
        RESUME_ENDPOINT.ResumeValidationLinkedInValidation
      }?linkedInId=${formControl.watch('linkedinUrl')}`,
    )
      .then((resp: { isValidProfile: boolean }) => {
        setIsFocused(true);
        setShow(resp?.isValidProfile ? 'success' : 'cancel');
        if (!isomitApiCall) {
          if (resp?.isValidProfile) {
            submit(formControl.watch());
          } else {
            setIsOpen(true);
          }
        }
      })
      .catch(() => {
        setIsFocused(false);
        if (!isomitApiCall) {
          submit(formControl.watch());
        }
      });
  };
  const generateUUid = (val: any, bodyData: any) => {
    const id = uuidv4();
    const jobInfo = {
      companyName: val?.companyName || null,
      designation: val?.jobTitle || null,
      fromDate: val?.fromDate || null,
      isCurrentlyWorking: val?.isCurrentlyWorking || false,
      jobId: id,
      location: val?.location || null,
      reportingTo: null,
      toDate: val.toDate || null,
    };
    bodyData.jobs = [
      ...(bodyData.jobs && bodyData.jobs.length ? bodyData.jobs : []),
      jobInfo,
    ];
    return id;
  };
  const submit = (formData: GeneralDetails) => {
    delete parsedInfo.skillsExist;

    if (!_.isEmpty(formData.firstName)) {
      formData.firstName = formData.firstName.trim();
    }
    if (!_.isEmpty(formData.lastName)) {
      formData.lastName = formData.lastName.trim();
    }

    const bodyData: GeneralFormDate = {
      ...parsedInfo,
      ...formData,
      candidateId: candidateId,
      education:
        formData.educations && formData.educations.length
          ? formData.educations.map((e) => ({
              ...e,
              yearOfPassing: e.graduationYear,
            }))
          : [],
      isLinkedInProfileVerified: show === 'success' ? true : false,
      notes:
        formData.notes && formData.notes.trim()
          ? [{ note: formData.notes }]
          : [],
      projects:
        formData.experience && formData.experience.length
          ? formData.experience.map((e) => ({
              ...e,
              companyName: e.employerOrClient,
              designation: e.jobTitle,
              projectTitle: e.project,
              skills: e.skills ? e.skills : [],
              skillsMatched: e.skillsMatched ? e.skillsMatched : [],
            }))
          : [],
      resumeId: resumeId,
      skill: Array.from(new Set(formData.skills.map((e) => e.name))),
      skillsMatched: Array.from(
        new Set(formData.skillsMatched.map((e) => e.name)),
      ),
      sourceId: Number(formData.sourceId),
    };
    delete bodyData.skills;
    delete bodyData.educations;

    bodyData.projects = bodyData.projects?.map((e) => {
      const matchingJob =
        bodyData.jobs && bodyData.jobs.length
          ? bodyData.jobs.find(
              (el) =>
                String(el.companyName).toLowerCase() ===
                String(e.employerOrClient).toLowerCase(),
            )
          : null;
      return {
        ...e,
        jobId: matchingJob?.jobId || generateUUid(e, bodyData), // Assign jobId if not present
      };
    });
    bodyData.jobs =
      bodyData.jobs && bodyData.jobs.length
        ? bodyData.jobs.filter((l) =>
            bodyData.projects.some((p) => p.jobId === l.jobId),
          )
        : [];

    ActionFn ? '' : '';
    setLoading((pre) => ({
      ...pre,
      button: true,
    }));
    addNewData(bodyData, RESUME_ENDPOINT.ResumeValidationSave, {})
      .then(() => {
        setLoading((pre) => ({
          ...pre,
          button: false,
        }));
        toast.success('General details has been updated successfully');
        close && close(StatusData.REVIEWED);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
        setLoading((pre) => ({
          ...pre,
          button: false,
        }));
      });
  };

  const getMatchedProfile = useCallback(() => {
    const { setValue } = formControl;
    getAllListData(
      `${RESUME_ENDPOINT.ResumeValidationParsed}/${String(resumeId)}`,
      true,
    )
      .then((resp: CreateCandidateApiResponse) => {
        const { sourceId } = resp.data;
        setParsedInfo(resp.data.file.parserData);
        if (resp && resp.data && resp.data.file && resp.data.file.parserData) {
          const MyLocaldata: CreateCandidateparserData =
            resp.data.file.parserData;

          setShow(MyLocaldata.isLinkedInProfileVerified ? 'success' : 'cancel');

          if (!readOnly && MyLocaldata.firstName && MyLocaldata.middleName) {
            setValue(
              'firstName',
              `${MyLocaldata.firstName} ${MyLocaldata.middleName}`,
            );
          } else {
            setValue('firstName', ValueChecker(MyLocaldata, 'firstName'));
          }
          setValue('middleName', ValueChecker(MyLocaldata, 'middleName'));
          setValue('lastName', ValueChecker(MyLocaldata, 'lastName'));
          setValue('primaryEmail', ValueChecker(MyLocaldata, 'primaryEmail'));
          setValue('city', ValueChecker(MyLocaldata, 'city'));
          setValue('state', ValueChecker(MyLocaldata, 'state'));
          setValue('jobTitle', ValueChecker(MyLocaldata, 'jobTitle'));
          setValue(
            'phoneNumber',
            `${(ValueChecker(MyLocaldata, 'countryCode') || '') as string}${
              (ValueChecker(MyLocaldata, 'phoneNumber') || '') as string
            }`,
          );
          setValue('linkedinUrl', ValueChecker(MyLocaldata, 'linkedinUrl'));
          setValue('sourceId', sourceId ? sourceId : '');
          setValue('workAuthId', ValueChecker(MyLocaldata, 'workAuth'));
          setValue('countryCode', ValueChecker(MyLocaldata, 'countryCode'));

          if (ValueChecker(MyLocaldata, 'projects')) {
            setValue(
              'experience',
              ValueChecker(MyLocaldata, 'projects').map(
                (exp: ExperienceInfo) => {
                  return {
                    ...exp,
                    employerOrClient: ValueChecker(exp, 'companyName'),
                    fromDate: ValueChecker(exp, 'fromDate'),
                    isCurrentlyWorking:
                      ValueChecker(exp, 'isCurrentlyWorking') || false,
                    jobTitle: ValueChecker(exp, 'designation'),
                    location: ValueChecker(exp, 'location'),
                    project: ValueChecker(exp, 'projectTitle'),
                    skills: ValueChecker(exp, 'skills'),
                    skillsMatched: ValueChecker(exp, 'skills'),
                    toDate: ValueChecker(exp, 'toDate'),
                  };
                },
              ),
            );
          }
          if (ValueChecker(MyLocaldata, 'education')) {
            const educations = ValueChecker(MyLocaldata, 'education').map(
              (edu: EducationInfo) => {
                const university =
                  ValueChecker(edu, 'university') ||
                  ValueChecker(edu, 'college');
                return {
                  ...edu,
                  college: ValueChecker(edu, 'college'),
                  country: ValueChecker(edu, 'country'),
                  degree: ValueChecker(edu, 'degree'),
                  graduationYear: ValueChecker(edu, 'yearOfPassing'),
                  university: university,
                };
              },
            );

            setValue('educations', educations);
          }
          const skils = ValueChecker(MyLocaldata, 'skill');
          if (skils && skils.length) {
            setValue(
              'skills',
              Array.from(new Set(skils)).map((val: string, idt: number) => {
                return {
                  id: idt.toString(),
                  name: val ? val : null,
                  rowId: undefined,
                };
              }),
            );
          }
          const skillsMatched = ValueChecker(MyLocaldata, 'skillsMatched');
          if (skillsMatched && skillsMatched.length) {
            setValue(
              'skillsMatched',
              Array.from(new Set(skillsMatched)).map(
                (val: string, idt: number) => {
                  return {
                    id: idt.toString(),
                    name: val ? val : null,
                    rowId: undefined,
                  };
                },
              ),
            );
          }
        }

        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
      })
      .catch(() => {
        setLoading((pre) => ({
          ...pre,
          common: false,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeId]);

  const handleDone = () => {
    setMount(true);
    setText('');
    setdone((p) => !p);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading((pre) => ({
      ...pre,
      common: true,
    }));
    getMatchedProfile();
  }, [getMatchedProfile]);

  useEffect(() => {
    setMount(true);
  }, []);

  return (
    <Typography component={'div'}>
      <>
        <Typography component={'div'} className="d-flex-a flex-sb plr-14 pb-4">
          <Typography
            component={'div'}
            className="red-color resume-tab-header highlight pl-0">
            {'General Details'}
          </Typography>
        </Typography>
        {loading.common ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 'calc(88vh - 75px)',
              justifyContent: 'center',
            }}>
            <Loader
              pageCenterLoadingHide={true}
              className={'d-flex-ja w-100 h-100'}
            />
          </Box>
        ) : (
          <Box>
            <Typography
              component={'div'}
              className="pr-5"
              sx={{ height: 'calc(88vh - 75px)', overflow: 'auto' }}>
              <div
                style={{
                  opacity: done ? 0.12 : 1,
                  pointerEvents: done ? 'none' : 'auto',
                }}
                onClick={(eg: any) => {
                  if (done) {
                    eg.preventDefault();
                    return;
                  }
                }}>
                <AddNotes
                  setValue={formControl.setValue}
                  hideButton={true}
                  readOnly={readOnly}
                />

                <form
                  onSubmit={formControl.handleSubmit(internalVerify)}
                  id="generalForm">
                  {accordionTypes.map((e) => (
                    <AccordionView
                      key={e.type}
                      header={e.name}
                      type={e.type}
                      control={formControl}
                      closeAcc={done ? true : false}
                      readOnly={readOnly}
                      isFocused={isFocused}
                      setIsFocused={setIsFocused}
                      show={show}
                      setShow={setShow}
                      linkedInVerification={linkedInVerification}
                    />
                  ))}
                  <ConfirmationDialog
                    open={open}
                    title="Confirmation"
                    message="Are you sure you want to perform this action?"
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                  />
                </form>
              </div>
              {done ? (
                <div className="reasoncontainer">
                  <div className="inputContainer">
                    <div className="label discard-reason-label">
                      <span className="errorText">
                        Reason for Discarding
                        <span className="red-asterisk"> *</span>
                      </span>
                    </div>
                    <textarea
                      cols={30}
                      rows={30}
                      ref={ReasonRef}
                      onChange={handleChange}
                    />
                    {mount ? null : text ? null : (
                      <FormHelperText className="errorMsg">
                        {'Reason for Discarding is required'}
                      </FormHelperText>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </Typography>
            {done ? (
              <div className="reasonBtnContainer">
                <div className="btn bt1" onClick={handleDone}>
                  Cancel
                </div>
                <div
                  className="btn bt2"
                  onClick={() => {
                    ReasonRef.current && ReasonRef.current.value
                      ? handleReason()
                      : showError();
                  }}>
                  Save
                </div>
              </div>
            ) : (
              <></>
            )}
            {done ? (
              <></>
            ) : (
              !readOnly && (
                <Typography
                  component={'div'}
                  className="d-flex-jae"
                  sx={{ marginTop: '20px' }}>
                  <div
                    className="discardbtn"
                    onClick={!readOnly ? handleDone : null}
                    style={{ marginRight: '26px' }}>
                    Discard
                  </div>
                  <CustomButton
                    variant="contained"
                    type={'submit'}
                    isStyle={true}
                    name={'Submit'}
                    loading={loading.button}
                    form={'generalForm'}
                    className={'GeneralDetails'}
                  />
                </Typography>
              )
            )}
          </Box>
        )}
      </>
      {isOpen && (
        <ConfirmationDialog
          open={isOpen}
          title={'Linkedin Verification'}
          message={
            'LinkedIn ID verification was not successful. Do you want to proceed with submitting the details?'
          }
          onClose={() => {
            setLoading((pre) => ({
              ...pre,
              button: false,
            }));
            setIsOpen(false);
          }}
          onConfirm={() => {
            setLoading((pre) => ({
              ...pre,
              button: false,
            }));
            setIsOpen(false);
            submit(formControl.watch());
          }}
        />
      )}
    </Typography>
  );
};
