import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addNewData, updateData } from 'services/CommonServices';
import { USER_RELOAD } from 'store/types/UserTypes';
import { AddSkills } from 'types';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addSkillsSchema } from 'utils/ValidatorSchema';

import useStyles from '../private/PrivateStyle';

interface UserProps {
  row?: any;
  editId?: string;
  visible: boolean;
  onClose: () => void;
}

const style = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  maxHeight: 400,
  minHeight: 200,
  overflowY: 'scroll',
  padding: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '200px',
  transform: 'translate(-50%, -60px)',
  width: 450,
};

const Skills = (props: UserProps) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [removeId, setRemoveId] = useState<any>([]);

  const { handleSubmit, control, formState, reset, setValue } =
    useForm<AddSkills>({
      defaultValues: {
        aliasName: [],
        name: '',
        primary: false,
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(addSkillsSchema),
    });

  const {
    fields: aliasField,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'aliasName',
  });
  useEffect(() => {
    if (props.row) {
      setValue('name', props.row?.name);
      if (props.row.alias && props.row.alias.length) {
        const aliasTrueData = [];
        const aliasFalseData = [];
        props.row.alias.map((item: any) => {
          if (item.status) {
            aliasTrueData.push({
              aliasId: item.id,
              name: item.aliasName,
              status: item.status,
            });
          } else {
            aliasFalseData.push({
              aliasId: item.id,
              name: item.aliasName,
              status: item.status,
            });
          }
        });

        append([...aliasTrueData, ...aliasFalseData]);
      }
    }
  }, [props.row, append, setValue]);

  const removeAlias = (index, item) => {
    if (props.editId) {
      const getIndex = aliasField.findIndex(
        (field) => field.aliasId === item.aliasId,
      );
      remove(getIndex);
    } else {
      remove(index);
    }
    if (item?.aliasId) {
      removeId.push(item?.aliasId);
      setRemoveId([...removeId]);
    }
  };

  const submitForm = async (data: any) => {
    if (props.editId) {
      const aliasArray = [];
      if (data['aliasName'] && data['aliasName'].length) {
        data['aliasName'].map((item: any) => {
          if (item.aliasId) {
            aliasArray.push({
              aliasId: item.aliasId,
              aliasName: item.name.trim(),
              status: item.status,
            });
          } else {
            if (item.name.trim()) {
              aliasArray.push({
                aliasName: item.name.trim(),
                status: false,
              });
            }
          }
        });
      }
      const postJson = {
        name: data['name'].trim(),
        removeRowId: removeId,
        status: props.editId ? props.row.status : true,
        updateAlias: aliasArray,
      };

      await updateData(props.editId, postJson, MASTER_ENDPOINT.Skills, true)
        .then((resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
        })
        .catch((err: any) => {});
      resetForm();
    } else {
      const aliasArray = [];
      let isAliasDuplication = false;
      if (data['aliasName'] && data['aliasName'].length) {
        data['aliasName'].map((item: any) => {
          if (
            data['name'].toLowerCase().trim() !== item.name.toLowerCase().trim()
          ) {
            isAliasDuplication = false;
            if (item.name.trim()) {
              aliasArray.push(item.name.trim());
            }
          } else {
            isAliasDuplication = true;
          }
        });
      }

      if (isAliasDuplication) {
        toast.error(
          'Alias names must not be the same as Skill names.',
          alertOptions,
        );
        return;
      }

      const postJson = {
        alias: {
          aliasNames: aliasArray,
          status: false,
        },
        name: data['name'].trim(),
        primary: data['primary'],
        status: true,
      };

      await addNewData(postJson, MASTER_ENDPOINT.Skills, {}, true)
        .then((resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
        })
        .catch((err: any) => {});
      resetForm();
    }
  };

  const resetForm = () => {
    reset();
    setRemoveId([]);
    props.onClose();
  };

  return (
    <Modal
      open={props.visible}
      // onClose={resetForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <IconButton
          aria-label="Close"
          onClick={resetForm}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}>
          <CloseIcon />
        </IconButton>
        <Typography className="recrutor-title">
          {props.editId ? 'Edit' : 'Add'} Skill
        </Typography>
        <form onSubmit={handleSubmit(submitForm)}>
          <Grid container columnSpacing={2} rowSpacing={1.5}>
            <Grid item md={12} sm={12}>
              <Typography>
                <label>Skill Name</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="name"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className={classes.custominput}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e);
                          if (props.editId) {
                            const index = aliasField.findIndex(
                              (alias) => alias.status,
                            );
                            setValue(`aliasName.${index}.name`, e.target.value);
                          }
                        }}
                        error={formState.errors?.name?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={12} sm={12}>
              <Typography>
                <label>Alias Name</label>
                <Typography>
                  {aliasField.length === 0 ? (
                    <a
                      href={'javascript:void(0)'}
                      onClick={() => {
                        append({
                          name: '',
                        });
                      }}>
                      <AddCircleOutlineIcon
                        sx={{
                          color: '#e0e0e4',
                          fontSize: '25px',
                        }}
                      />
                    </a>
                  ) : (
                    ''
                  )}
                </Typography>
                {aliasField.length ? (
                  aliasField.map((item: any, index) => {
                    return (
                      <Grid
                        item
                        container
                        key={item.id}
                        sx={{ paddingTop: '5px' }}>
                        <Grid item md={8.5} sm={8.5}>
                          <Controller
                            key={index}
                            control={control}
                            defaultValue=""
                            name={`aliasName.${index}.name`}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              return (
                                <CustomInput
                                  placeHolder=""
                                  value={value}
                                  name={name}
                                  className={classes.custominput}
                                  ref={ref}
                                  onChange={(e: any) => {
                                    onChange(e);
                                  }}
                                  // onBlur={(e) => {
                                  //   if (
                                  //     watch('name') !== '' &&
                                  //     String(watch('name'))
                                  //       .toLowerCase()
                                  //       .trim() ===
                                  //       String(e.target.value)
                                  //         .toLowerCase()
                                  //         .trim()
                                  //   ) {
                                  //     toast.error(
                                  //       'Alias names must not be the same as Skill names.',
                                  //       alertOptions,
                                  //     );
                                  //     removeAlias(index, item);
                                  //     return;
                                  //   }
                                  // }}
                                  disabled={
                                    props.editId && item.status ? true : false
                                  }
                                  error={
                                    formState?.errors?.aliasName?.[index]?.name
                                      ?.message
                                  }
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          md={3.5}
                          sm={3.5}
                          sx={{
                            alignSelf: 'center',
                            paddingLeft: '10px',
                            paddingTop: '5px',
                          }}>
                          {!item.status && index !== 0 && (
                            <a
                              href={'javascript:void(0)'}
                              style={{ paddingRight: 8 }}
                              onClick={() => {
                                removeAlias(index, item);
                              }}>
                              <RemoveCircleOutlineIcon
                                sx={{
                                  color: '#e0e0e4',
                                  fontSize: '35px',
                                }}
                              />
                            </a>
                          )}
                          {index === 0 && (
                            <a
                              href={'javascript:void(0)'}
                              onClick={() => {
                                append({
                                  aliasId: '',
                                  name: '',
                                  status: false,
                                });
                              }}>
                              <AddCircleOutlineIcon
                                sx={{
                                  color: '#e0e0e4',
                                  fontSize: '35px',
                                }}
                              />
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <Typography />
                )}
              </Typography>
            </Grid>
            {!props.editId || _.isUndefined(props.editId) ? (
              <Grid item md={12} sm={12}>
                <Typography>
                  {/* <label>Primary Skill</label>
                  <span className="errorText">{'*'}</span> */}
                  <Controller
                    control={control}
                    defaultValue={false}
                    name="primary"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <>
                          <FormControlLabel
                            className="default-chexbox"
                            control={
                              <Checkbox
                                defaultChecked={false}
                                color="success"
                                name={name}
                                checked={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                }}
                              />
                            }
                            label="Primary Skill"
                          />

                          {formState.errors?.primary?.message && (
                            <FormHelperText className="errorMsg">
                              {formState.errors?.primary?.message}
                            </FormHelperText>
                          )}
                        </>
                      );
                    }}
                  />
                </Typography>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          <Typography
            component={'div'}
            className="d-flex-a flex-sb"
            sx={{ marginTop: '30px !important' }}>
            <Button className="cancel-btn" onClick={resetForm}>
              Cancel
            </Button>
            <Button className="save-btn" type={'submit'}>
              Save
            </Button>
          </Typography>
        </form>
      </Box>
    </Modal>
  );
};

export default Skills;
