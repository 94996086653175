// import SearchIcon from '@mui/icons-material/Search';
import {
  FormHelperText,
  // InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import debouce from 'lodash.debounce';
import moment from 'moment';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

type InputProps = {
  placeHolder?: string;
  className?: string;
  onChange?: (src: any) => void;
  extraStyle?: any;
  value?: any;
  error?: any;
  options?: any;
  labelKey?: string;
  label1Key?: string;
  valueKey?: string;
  id?: string;
  name?: string;
  ref?: any;
  disabled?: boolean;
  selectedValue?: string;
  searchable?: boolean;
  valueHighLigt?: string;
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

const CustomMultiSelect = (props: InputProps): ReactElement => {
  const {
    placeHolder,
    className,
    onChange,
    extraStyle,
    value,
    error,
    options,
    labelKey,
    label1Key,
    valueKey,
    id,
    name,
    ref,
    disabled,
    selectedValue,
    searchable,
    valueHighLigt,
  } = props;
  const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(false);
  const [optionList, setOptionList] = useState<any>([]);
  const [optionListAll, setOptionListAll] = useState<any>([]);

  useEffect(() => {
    setOptionList(options);
    setOptionListAll(options);
  }, [options]);

  const onChangeText = React.useCallback(
    (e: any) => {
      if (e?.target?.value) {
        setOptionList([
          ...optionListAll.filter(
            (option) =>
              option[labelKey]
                .toLowerCase()
                .indexOf(e?.target?.value.toLowerCase()) !== -1,
          ),
        ]);
      } else {
        setOptionList(optionListAll);
      }
    },
    [labelKey, optionListAll],
  );

  const debouncedResults = useMemo(() => {
    return debouce(onChangeText, 1000);
  }, [onChangeText]);

  return (
    <>
      <Select
        MenuProps={MenuProps}
        sx={{
          '& .Mui-selected': {
            backgroundColor: '#eed8d9 !important',
          },
          '& .MuiInputBase-root': {
            background: '#f3f3f3',
            display: 'flex',
            height: '46px !important',
          },
          '& .MuiOutlinedInput-root': {
            width: '100% !important',
          },
          textTransform: 'capitalize',
          width: '100% !important',
        }}
        className={className}
        onOpen={() => setShowPlaceHolder(true)}
        onClose={() => {
          setShowPlaceHolder(false);
          setOptionList(optionListAll);
        }}
        multiple
        onChange={onChange && onChange}
        style={extraStyle}
        value={value}
        displayEmpty
        disabled={disabled}
        id={id}
        name={name}
        ref={ref}
        error={!!error}>
        {searchable && (
          <ListSubheader style={{ backgroundColor: '#fff' }}>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <SearchIcon />
              //     </InputAdornment>
              //   ),
              // }}
              onChange={debouncedResults}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {!showPlaceHolder && (
          <MenuItem value="">
            <span style={{ color: '#8f8a8a' }}>{placeHolder}</span>
          </MenuItem>
        )}

        {optionList.length ? (
          optionList.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                sx={{
                  backgroundColor: valueHighLigt
                    ? valueHighLigt === item[valueKey]
                      ? '#eed8d9 !important'
                      : 'transparent'
                    : item[valueKey] === value
                    ? '#eed8d9 !important'
                    : 'transparent',
                  textTransform: 'capitalize',
                }}
                value={
                  valueKey === 'date'
                    ? moment(item[valueKey]).format('DD-MM-YYYY')
                    : item[valueKey]
                }
                selected={
                  selectedValue !== '' &&
                  String(item[valueKey]) === String(selectedValue)
                }>
                {labelKey === 'date'
                  ? moment(item[labelKey]).format('DD-MM-YYYY')
                  : item[labelKey]}
                {label1Key ? ` ${String(item[label1Key])}` : ''}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem value={''} disabled />
        )}
      </Select>
      {error && <FormHelperText className="errorMsg">{error}</FormHelperText>}
    </>
  );
};

export default CustomMultiSelect;
