import { Box, Typography } from '@mui/material';
import { Loader } from 'components/loader';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import { HeaderTab } from '../Analytics/header';
import { AddNotes } from './AddNotes';

type Props = {
  setAnalytics: (src: boolean) => void;
  candidateId: string;
};
export const ListNotes: React.FC<Props> = (props: Props) => {
  const { candidateId, setAnalytics } = props;
  const [shownNotes, setShownAddNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState([]);
  const [refreshNotes, setRefreshNotes] = useState<boolean>(false);
  const [loading, setLoading] = useState<{
    common: boolean;
  }>({
    common: true,
  });

  const getNotes = useCallback(
    (id?: string) => {
      setLoading((pre) => ({
        ...pre,
        common: true,
      }));
      getAllListData(
        MASTER_ENDPOINT.Candidates +
          '/notes?id=&userId=&candidateId=' +
          `${candidateId}`,
      )
        .then((resp) => {
          setNotes(resp?.data);
          setRefreshNotes(false);
          setLoading((pre) => ({
            ...pre,
            common: false,
          }));
        })
        .catch((err) => {
          setLoading((pre) => ({
            ...pre,
            common: false,
          }));
          setRefreshNotes(false);
          console.log(err);
        });
    },
    [candidateId],
  );

  useEffect(() => {
    getNotes();
  }, [getNotes, refreshNotes]);

  return (
    <Typography component={'div'}>
      <HeaderTab
        tab="notes"
        setAnalytics={setAnalytics}
        setShownAddNotes={setShownAddNotes}
        shownNotes={shownNotes}
        notesTotal={notes.length}
      />
      {shownNotes && (
        <AddNotes
          closeNotes={() => setShownAddNotes(false)}
          setRefreshNotes={setRefreshNotes}
          candidateId={candidateId}
        />
      )}

      {loading.common ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 'calc(88vh - 75px)',
            justifyContent: 'center',
          }}>
          <Loader
            pageCenterLoadingHide={true}
            className={'d-flex-ja w-100 h-100'}
          />
        </Box>
      ) : (
        <Typography component={'div'} className="body-hight">
          {notes && notes.length > 0 ? (
            notes.map((data, index: number) => (
              <Box className="notes-card-info mt-12" key={index}>
                <Typography
                  component={'div'}
                  className="info"
                  sx={{
                    wordBreak: ' break-all',
                  }}>
                  {data.notes}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '45px',
                  }}
                  component={'div'}
                  className="d-flex-a flex-sb footer-info">
                  <Typography component={'div'} className="title">
                    Created by &nbsp;{' '}
                    <span className="red-color f-12">
                      {data.user.firstName} {data.user.lastName}{' '}
                    </span>
                  </Typography>
                  <Typography component={'div'} className="title">
                    Created Date &nbsp;{' '}
                    <span className="red-color f-12">
                      {moment(data.createdAt).format('MM/DD/YYYY')}
                    </span>
                  </Typography>
                </Typography>
              </Box>
            ))
          ) : (
            <Typography
              component={'div'}
              className={'d-flex-ja w-100 h-100 red-color'}>
              No Records Found
            </Typography>
          )}
        </Typography>
      )}

      <Typography component={'div'} className="footer-info">
        <Typography
          component={'div'}
          className="resume-btn-footer"
          onClick={() => setAnalytics(true)}>
          Next
        </Typography>
      </Typography>
    </Typography>
  );
};
