import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import profileimg from 'assets/images/profileimg.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useDispatch } from 'react-redux';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { USER_IMAGE_ID_CHANGE, USER_PROFILE_DATA } from 'store/types/UserTypes';
import { EditProfileForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { editProfileSchema } from 'utils/ValidatorSchema';

import useStyles from './CommonStyle';

const style = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  p: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 780,
};

export const Profile: React.FC<any> = (props) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [countryCode, setCountryCode] = React.useState('');
  const [uploadImage, setUploadImage] = React.useState<any>({});
  const [localImage, setLocalImage] = React.useState<any>('');
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLocalImage('');
    setUploadImage({});
  };

  const { handleSubmit, control, formState, setValue } =
    useForm<EditProfileForm>({
      defaultValues: {
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(editProfileSchema),
    });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userInfo, profileInfo }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const getData = () => {
    getAllListData(MASTER_ENDPOINT.Admins + '?id=' + userInfo.id)
      .then((resp: any) => {
        if (userInfo?.imageId !== resp?.data?.[0]?.imageId) {
          dispatch({
            payload: resp?.data?.[0]?.imageId,
            type: USER_IMAGE_ID_CHANGE,
          });
        }
        if (!_.isEqual(profileInfo, resp?.data?.[0])) {
          dispatch({
            payload: resp?.data?.[0],
            type: USER_PROFILE_DATA,
          });
        }
        setValue('firstName', resp?.data?.[0]?.firstName);
        setValue('lastName', resp?.data?.[0]?.lastName);
        setValue('email', resp?.data?.[0]?.login?.email);
        if (resp?.data?.[0]?.phoneNumber) {
          setValue('phoneNumber', resp?.data?.[0]?.phoneNumber);
          setCountryCode(resp?.data?.[0]?.countryCode);
        }
      })
      .catch((err: any) => {});
  };

  const submitForm = (data: any) => {
    const formData = new FormData();
    formData.append('status', 'true');
    formData.append('roleId', profileInfo.roleId);
    formData.append('email', data['email']);
    formData.append('empCode', profileInfo.empCode);
    formData.append('firstName', data['firstName']);
    formData.append('organizationId', profileInfo.organizationId);
    formData.append('lastName', data['lastName']);
    if (data['phoneNumber']) {
      formData.append('phoneNumber', data['phoneNumber']);
      formData.append('countryCode', `${countryCode}`);
    } else {
      formData.append('phoneNumber', '');
      formData.append('countryCode', '');
    }
    if (uploadImage.name) {
      formData.append('profile', uploadImage);
    } else if (profileInfo.imageId) {
      formData.append('imageId', profileInfo.imageId);
    }
    setLoading(true);
    updateData(
      profileInfo.id,
      formData,
      MASTER_ENDPOINT.Admins + '/profile',
      true,
    )
      .then((resp: any) => {
        setLoading(false);
        handleClose();
        getData();
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Card sx={{ boxShadow: 0 }}>
        <CardContent>
          <a href={'#'} onClick={handleOpen}>
            <Typography> Edit </Typography>
          </a>
          <Typography className={classes.profilebox} component={'div'}>
            <Typography component={'div'}>
              <img
                src={
                  userInfo?.imageId
                    ? `${process.env.REACT_APP_APIURL}${
                        MASTER_ENDPOINT.Files
                      }/${String(userInfo?.imageId)}?userId=${String(
                        userInfo?.id,
                      )}`
                    : profileimg
                }
                style={{
                  borderRadius: '10%',
                  objectFit: 'cover',
                  padding: 0,
                }}
              />
            </Typography>
            <Typography component={'div'}>
              <ul className={classes.profiledetails}>
                <li>
                  <span>Name</span> {profileInfo?.firstName}{' '}
                  {profileInfo?.lastName}
                </li>
                <li>
                  <span>Email</span> {profileInfo?.login?.email}
                </li>
                <li>
                  <span> Phone </span>{' '}
                  {formatPhoneNumber('+' + profileInfo?.phoneNumber)}
                </li>
              </ul>
            </Typography>
          </Typography>
        </CardContent>
      </Card>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form id={'editProfile'} onSubmit={handleSubmit(submitForm)}>
          <Box sx={style} className="model-widget">
            <IconButton
              aria-label="Close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
              }}>
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" component="h2">
              Edit Profile
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                marginBottom: 25,
              }}>
              <Typography component={'div'}>
                <Typography component={'div'} sx={{ textAlign: 'center' }}>
                  <Button variant="text" component="label">
                    {localImage ? (
                      <img
                        style={{
                          borderRadius: '50px',
                          height: '100px',
                          objectFit: 'cover',
                          padding: 0,
                          width: '100px',
                        }}
                        src={localImage}
                      />
                    ) : profileInfo?.imageId ? (
                      <img
                        style={{
                          borderRadius: '50px',
                          height: '100px',
                          objectFit: 'cover',
                          padding: 0,
                          width: '100px',
                        }}
                        src={`${process.env.REACT_APP_APIURL}${
                          MASTER_ENDPOINT.Files
                        }/${String(profileInfo?.imageId)}?userId=${String(
                          userInfo?.id,
                        )}`}
                      />
                    ) : (
                      <img
                        src={profileimg}
                        style={{
                          borderRadius: '50px',
                          height: '100px',
                          objectFit: 'cover',
                          padding: 0,
                          width: '100px',
                        }}
                      />
                    )}
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e) => {
                        setUploadImage(e.target.files[0]);
                        setLocalImage(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </Button>
                </Typography>
                <Grid container columnSpacing={2.5}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography>
                      <label>
                        First Name<span className="errorText">{'*'}</span>
                      </label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="firstName"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.firstName?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography>
                      <label>
                        Last Name<span className="errorText">{'*'}</span>
                      </label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="lastName"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.lastName?.message}
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography>
                      <label>
                        Email<span className="errorText">{'*'}</span>
                      </label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="email"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <CustomInput
                              placeHolder=""
                              value={value}
                              className={classes.custominput}
                              name={name}
                              ref={ref}
                              onChange={(e: any) => {
                                onChange(e);
                              }}
                              error={formState.errors?.email?.message}
                              disabled
                            />
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography>
                      <label>Phone Number</label>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="phoneNumber"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <>
                              <PhoneInput
                                country={'us'}
                                inputStyle={{
                                  border: '0px !important',
                                }}
                                value={value}
                                onChange={(val, data: any) => {
                                  onChange(val);
                                  setCountryCode(data?.dialCode);
                                }}
                              />
                              {formState.errors?.phoneNumber?.message && (
                                <FormHelperText className="errorMsg">
                                  {formState.errors?.phoneNumber?.message}
                                </FormHelperText>
                              )}
                            </>
                          );
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Typography>
            <Divider />
            <Typography
              className="model-btn"
              component={'div'}
              style={{
                marginTop: 25,
              }}>
              <Button
                className="cancel-btn"
                variant="text"
                onClick={handleClose}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                type={'submit'}
                isStyle={true}
                name={'Save Changes'}
                form={'editProfile'}
                loading={loading}
                className={'save-btn'}
              />
            </Typography>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default Profile;
