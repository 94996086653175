import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Grid, Paper, Typography } from '@mui/material';
import Loginbg from 'assets/images/login-bg.png';
import Loginlogo from 'assets/images/login-logo.png';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import { PublicRoutes } from 'config';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addNewData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { ForgotSubmitForm } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { forgotSchema } from 'utils/ValidatorSchema';

import CopyAndVersionHistory from '../../common/CopyAndVersionHistory';
import useStyles from '../publicStyles';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { emailRemember }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const { handleSubmit, control, formState, setValue } =
    useForm<ForgotSubmitForm>({
      defaultValues: {
        email: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(forgotSchema),
    });

  useEffect(() => {
    if (emailRemember) {
      setValue('email', emailRemember);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailRemember]);

  const submitForm = (data: any) => {
    setLoading(true);
    addNewData({ email: data.email }, MASTER_ENDPOINT.ForgotPassword, {}, true)
      .then((resp: any) => {
        setLoading(false);
        navigate('/login');
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Typography
          component="div"
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          style={{ backgroundImage: `url(${Loginbg})` }}
          className={classes.info}>
          <Paper className={classes.loginwrap}>
            <form onSubmit={handleSubmit(submitForm)}>
              <a
                href="javascript:void(0);"
                style={{
                  height: 20,
                  left: 20,
                  position: 'absolute',
                  top: 20,
                  width: 20,
                }}
                onClick={() => navigate(PublicRoutes.LOGIN)}>
                <ArrowBackIosNewIcon sx={{ color: '#292929', fontSize: 20 }} />
              </a>
              <Typography>
                <img
                  src={Loginlogo}
                  alt="signup-logo"
                  style={{ display: 'table', margin: '51px auto 30px' }}
                />
              </Typography>
              <Typography style={{ paddingBottom: 3 }}>
                <label
                  style={{
                    fontFamily: 'Red Hat Display Bold',
                    fontSize: 18,
                  }}>
                  Forgot Password
                </label>
              </Typography>
              <Typography>
                <label>Email Address</label>
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder="Email Address"
                        // variant="outlined"
                        value={value}
                        className={classes.custominput}
                        name={name}
                        ref={ref}
                        onChange={(e: any) => {
                          const emailTrim = e?.target?.value.replace(
                            /\s+/g,
                            '',
                          );
                          onChange(emailTrim);
                        }}
                        error={formState.errors?.email?.message}
                      />
                    );
                  }}
                />
              </Typography>
              <Typography style={{ paddingTop: 5 }}>
                <CustomButton
                  variant="contained"
                  type="submit"
                  name={'Submit'}
                  loading={loading}
                  extraStyle={{ textTransform: 'capitalize' }}
                />
              </Typography>
              <CopyAndVersionHistory />
            </form>
          </Paper>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
