import React from 'react';

export default function ProjectSkillsTrashSvg({
  Selected,
}: {
  Selected?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      fill="none"
      viewBox="0 0 25 20"
      style={{ cursor: 'pointer' }}>
      <rect
        width="24.5"
        height="19.5"
        x="0.25"
        y="0.25"
        fill="#F8F8FA"
        rx="3.75"
      />
      <rect
        width="24.5"
        height="19.5"
        x="0.25"
        y="0.25"
        stroke="#0262B9"
        strokeWidth="0.5"
        rx="3.75"
      />
      <path
        stroke="#0262B9"
        strokeLinecap="round"
        d="M16.1 7.395h.3c.331 0 .6-.26.6-.58v-.289a.59.59 0 00-.6-.579h-1.8m1.5 1.448v6.947c0 .64-.537 1.158-1.2 1.158h-4.8c-.663 0-1.2-.518-1.2-1.158V7.395m7.2 0H8.9m5.7-1.448v-.29c0-.639-.537-1.157-1.2-1.157h-1.8c-.663 0-1.2.518-1.2 1.158v.29m4.2 0h-4.2M8.9 7.394h-.3a.59.59 0 01-.6-.58v-.289a.59.59 0 01.6-.579h1.8m.9 3.474v4.053m2.4-4.053v4.053"
      />
    </svg>
  );
}
