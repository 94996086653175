import React from 'react';

export default function UnknownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="62"
      fill="none"
      viewBox="0 0 44 62">
      <g fill="#BF0000" clipPath="url(#clip0_180_2824)">
        <path d="M13.361 0L.44 13.005V62h43.12V0H13.36zm-.601 3.528V12.4H3.945l8.815-8.872zm28.747 56.405H2.493V14.467h12.32v-12.4h26.694v57.866z" />
        <path d="M16.69 24.549l-2.903 2.923-2.904-2.923-1.452 1.461 2.904 2.923-2.904 2.924 1.452 1.461 2.904-2.923 2.903 2.923 1.452-1.461-2.903-2.924 2.903-2.923-1.452-1.461zM26.283 33.318l2.904-2.923 2.903 2.923 1.452-1.461-2.904-2.924 2.904-2.923-1.452-1.461-2.903 2.923-2.904-2.923-1.452 1.461 2.904 2.923-2.904 2.924 1.452 1.461zM13.06 42.67l1.453 1.46.954-.96a9.21 9.21 0 0113.066 0l.954.96 1.452-1.46-.954-.96a11.254 11.254 0 00-15.97 0l-.954.96z" />
      </g>
      <defs>
        <clipPath id="clip0_180_2824">
          <path fill="#fff" d="M0 0H44V62H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
