import PrivateLayout from 'components/PrivateLayout';
import { Candidates } from 'pages/private/Candidates';
import { Customer } from 'pages/private/Customer';
import { Dashboard } from 'pages/private/Dashboard';
import { IPWhitelist } from 'pages/private/IPWhitelist';
import { JobPosting } from 'pages/private/JobPosting';
import { Marketer } from 'pages/private/Marketers';
import Myprofile from 'pages/private/Myprofile';
import { Recruiter } from 'pages/private/Recruiters';
import { Sales } from 'pages/private/Sales';
import { Skills } from 'pages/private/Skills';
import VersionHistory from 'pages/public/VersionHistory';
import VersionHistoryAdd from 'pages/public/VersionHistory/add';
import { ViewJob } from 'pages/recruiter/JobPosting/ViewJob';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from '../index';

const PrivateRoutes = () => {
  const {
    CANDIDATE,
    DASHBOARD,
    JOBPOST,
    MARKETER,
    MYPROFILE,
    RECRUITER,
    SKILLS,
    SALES,
    CUSTOMER,
    IPWHITELIST,
    VIEWJOB,
    VERSIONHISTORYADD,
    VERSIONHISTORY,
  } = PrivateRoute;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={JOBPOST} />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout
            routes={{
              path: DASHBOARD,
              show: false,
              title: 'Dashboard',
            }}
            from={'dashboard'}
            showHeader={false}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path={RECRUITER}
        element={
          <PrivateLayout
            routes={{
              path: RECRUITER,
              show: false,
              title: 'Recruiter',
            }}
            from={'recruiters'}
            showHeader={true}>
            <Recruiter />
          </PrivateLayout>
        }
      />
      <Route
        path={MARKETER}
        element={
          <PrivateLayout
            routes={{
              path: MARKETER,
              show: false,
              title: 'Marketers',
            }}
            from={'marketers'}
            showHeader={true}>
            <Marketer />
          </PrivateLayout>
        }
      />
      <Route
        path={SALES}
        element={
          <PrivateLayout
            routes={{
              path: SALES,
              show: false,
              title: 'Sales',
            }}
            from={'sales'}
            showHeader={true}>
            <Sales />
          </PrivateLayout>
        }
      />
      <Route
        path={CANDIDATE}
        element={
          <PrivateLayout
            routes={{
              path: CANDIDATE,
              show: false,
              title: 'Candidate',
            }}
            from={'candidate'}
            showHeader={true}>
            <Candidates />
          </PrivateLayout>
        }
      />
      <Route
        path={JOBPOST}
        element={
          <PrivateLayout
            routes={{
              path: JOBPOST,
              show: false,
              title: 'Job Posting',
            }}
            from={'jobpost'}
            showHeader={true}>
            <JobPosting />
          </PrivateLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <PrivateLayout
            routes={{
              path: MYPROFILE,
              show: false,
              title: 'Profile',
            }}
            from={'profile'}
            showHeader={false}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={SKILLS}
        element={
          <PrivateLayout
            routes={{
              path: SKILLS,
              show: false,
              title: 'Skills',
            }}
            from={'skills'}
            showHeader={true}>
            <Skills />
          </PrivateLayout>
        }
      />
      <Route
        path={CUSTOMER}
        element={
          <PrivateLayout
            routes={{
              path: CUSTOMER,
              show: false,
              title: 'customermanagement',
            }}
            from={'customermanagement'}
            showHeader={true}>
            <Customer />
          </PrivateLayout>
        }
      />

      <Route
        path={IPWHITELIST}
        element={
          <PrivateLayout
            routes={{
              path: IPWHITELIST,
              show: false,
              title: 'ipwhitelist',
            }}
            from={'ipwhitelist'}
            showHeader={true}>
            <IPWhitelist />
          </PrivateLayout>
        }
      />

      <Route
        path={VIEWJOB}
        element={
          <PrivateLayout
            routes={{
              path: VIEWJOB,
              show: false,
              title: 'View Job',
            }}
            from={'viewjob'}
            showHeader={false}>
            <ViewJob />
          </PrivateLayout>
        }
      />
      <Route path={VERSIONHISTORY} element={<VersionHistory />} />
      <Route path={VERSIONHISTORYADD} element={<VersionHistoryAdd />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PrivateRoutes;
