import instance from 'services/api';

interface PostRequestProps {
  url: string;
  data: any;
  token?: string;
}

interface GetRequestProps {
  url: string;
  token?: string;
  ssoLogin?: boolean;
}

const postRequest = async (props: PostRequestProps, options?: any) => {
  return await instance
    .post(process.env.REACT_APP_APIURL + props.url, props.data, options)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      if (error.response) {
        throw error.response.data;
      }
    });
};
const putRequest = async (props: PostRequestProps) => {
  return await instance
    .put(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};
const patchRequest = async (props: PostRequestProps) => {
  return await instance
    .patch(process.env.REACT_APP_APIURL + props.url, props.data)
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const deleteRequest = async (props: GetRequestProps) => {
  return await instance
    .delete(
      props.ssoLogin ? props.url : process.env.REACT_APP_APIURL + props.url,
    )
    .then((response: any) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error: any) => {
      throw error.response.data;
    });
};

const getRequest = async (props: GetRequestProps) => {
  return await instance
    .get(
      props.ssoLogin ? props.url : process.env.REACT_APP_APIURL + props.url,
      props.token !== '' && {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      },
    )
    .then((response: any) => {
      if (response.data) {
        return response?.data;
      }
    })
    .catch((error: any) => {
      throw error && error?.response && error?.response?.data
        ? error.response.data
        : '';
    });
};

export { postRequest, putRequest, patchRequest, getRequest, deleteRequest };
