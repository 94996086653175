// import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import DownIcon from 'assets/images/down-arrow-selected.svg';
// import MoreIcon from 'assets/images/MoreIcon.svg';
import UpIcon from 'assets/images/up-arrow-selected.svg';
import InfinityScroll from 'components/InfinityScroll';
import ActiveModal from 'pages/common/ActiveModal';
import AddAndEditUser from 'pages/common/AddAndEditUser';
import DeleteModal from 'pages/common/DeleteModal';
import React, { useEffect } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { getAllListData, updateData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../PrivateStyle';

const staticParam = {
  order: 'asc',
  reload: true,
  skip: 0,
  sortby: 'name',
  take: 50,
};

export const Marketer = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [params, setParams] = React.useState<any>({});
  const [loading, setLoading] = React.useState<any>(true);
  const [tableInfo, setTableInfo] = React.useState<any>({
    list: [],
    total: 0,
  });
  // const handleClick = (
  //   event: React.MouseEvent<HTMLButtonElement>,
  //   row: any,
  // ) => {
  //   setEditRow(row);
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editRow, setEditRow] = React.useState<any>({});
  const [editModal, setEditModal] = React.useState<any>({
    row: {},
    visible: false,
  });
  const [val, setVal] = React.useState<any>([]);
  const handleOpen2 = () => {
    setEditModal({
      row: editRow,
      visible: true,
    });
    handleClose();
  };
  const handleClose2 = () =>
    setEditModal({
      row: {},
      visible: false,
    });
  const [deleteModal, setDeleteModal] = React.useState<any>({
    visible: false,
  });
  const handleOpen1 = () => {
    setDeleteModal({
      visible: true,
    });
    val?.length > 0 ? '' : setVal([editRow.id]);
    handleClose();
  };
  const handleClose1 = () => {
    setVal([]);
    setDeleteModal({
      visible: false,
    });
  };

  const [activeModal, setActiveModal] = React.useState<any>({
    visible: false,
  });
  const handleOpen3 = () => {
    setActiveModal({
      visible: true,
    });
    val?.length > 0 ? '' : setVal([editRow.id]);
    handleClose();
  };
  const handleClose3 = () => {
    setVal([]);
    setActiveModal({
      visible: false,
    });
  };

  const onDelete = () => {
    const updateStatus = {
      ids: val,
      status: false,
    };
    updateData(
      '',
      updateStatus,
      MASTER_ENDPOINT.Admins + '/status-update',
      true,
    ).then((resp: any) => {
      if (status === 'all') {
        const fInx = tableInfo.list.findIndex(
          (item: any) => item.id === val[0],
        );
        if (fInx !== -1) {
          tableInfo.list[fInx].status = false;
          setTableInfo({
            ...tableInfo,
          });
        }
      } else {
        setParams({ ...staticParam, reload: !params.reload });
      }
      handleClose1();
    });
  };

  const onActive = () => {
    const updateStatus = {
      ids: val,
      status: true,
    };
    updateData(
      '',
      updateStatus,
      MASTER_ENDPOINT.Admins + '/status-update',
      true,
    ).then((resp: any) => {
      if (status === 'all') {
        const fInx = tableInfo.list.findIndex(
          (item: any) => item.id === val[0],
        );
        if (fInx !== -1) {
          tableInfo.list[fInx].status = true;
          setTableInfo({
            ...tableInfo,
          });
        }
      } else {
        setParams({ ...staticParam, reload: !params.reload });
      }
      handleClose3();
    });
  };

  const { status, searchText, reload, companyFilter }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  useEffect(() => {
    if (Object.keys(params).length) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (Object.keys(params).length) {
      setParams({ ...staticParam, reload: !params.reload });
    } else {
      setParams(staticParam);
    }
    setVal([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, reload, searchText, companyFilter]);

  const getList = () => {
    setLoading(true);
    setTableInfo({
      list: [],
      total: 0,
    });
    let url = `${MASTER_ENDPOINT.Admins}?sortby=${String(
      params.sortby,
    )}&order=${String(params.order)}&roleId=4&search=${String(
      searchText,
    )}&skip=${String(params.skip)}&take=${String(
      params.take,
    )}&organizationId=${String(companyFilter)}`;
    if (status === 'active') {
      url += '&status=true';
    } else if (status === 'inactive') {
      url += '&status=false';
    }
    getAllListData(url)
      .then((resp: any) => {
        setLoading(false);
        setTableInfo({
          list: resp.data,
          total: resp?.total,
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const headRow = React.useCallback(() => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="w-24" sx={{ paddingLeft: '114px !important' }}>
            <Typography component={'div'} className="d-flex-a">
              <Typography className="th-title">Name</Typography>
              <Typography className="sort-box">
                <img
                  src={UpIcon}
                  alt="up"
                  className="down-arrow"
                  onClick={() => {
                    if (params.order !== 'asc') {
                      setParams({ ...staticParam, order: 'asc' });
                    }
                  }}
                />
                <img
                  src={DownIcon}
                  alt="up"
                  className="up-arrow"
                  onClick={() => {
                    if (params.order !== 'desc') {
                      setParams({ ...staticParam, order: 'desc' });
                    }
                  }}
                />
              </Typography>
            </Typography>
          </TableCell>
          <TableCell className="w-24">Email</TableCell>
          <TableCell className="w-15">Phone #</TableCell>
          <TableCell className="w-12">Role</TableCell>
          <TableCell className="w-12">Status</TableCell>
          <TableCell className="w-15">Company</TableCell>
          {/* <TableCell className="w-2" /> */}
        </TableRow>
      </TableHead>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValData = React.useCallback((id: string) => {
    setVal((pre) => [...pre, id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lngth = val.filter((x: string) => x === val[val.length - 1]).length;
    if (lngth === 2) {
      setVal([...val.filter((item: string) => item !== val[val.length - 1])]);
    }
  }, [val]);

  const rowProps = React.useCallback(
    (row: any, index: number) => {
      return (
        <TableRow
          key={index}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}>
          <TableCell>
            <Typography component={'div'} className="d-flex-a">
              <Typography component={'div'} className="d-flex-a">
                {status !== 'all' && (
                  <Checkbox
                    className="td-check"
                    defaultChecked={false}
                    color="success"
                    disabled={status === 'all' ? true : false}
                    sx={{ padding: '0px' }}
                    onChange={(e) => setValData(row.id)}
                  />
                )}
                <Typography component={'div'}>
                  {row?.imageId ? (
                    <Typography className={classes.profileboxRecAndMar}>
                      <img
                        src={`${process.env.REACT_APP_APIURL}${
                          MASTER_ENDPOINT.Files
                        }/${String(row?.imageId)}`}
                        style={{
                          objectFit: 'cover',
                          padding: 0,
                        }}
                      />
                    </Typography>
                  ) : (
                    <Typography
                      className="th-avator d-flex-ja"
                      sx={{
                        textTransform: 'capitalize',
                      }}>
                      {row?.firstName?.[0]}
                    </Typography>
                  )}
                </Typography>
              </Typography>
              <Typography
                className="td-text"
                sx={{
                  textTransform: 'capitalize',
                }}>
                {row?.firstName} {row?.lastName}
              </Typography>
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              textTransform: 'initial !important',
            }}>
            {row.login?.email}
          </TableCell>
          <TableCell>
            {row.phoneNumber
              ? formatPhoneNumberIntl('+' + row?.countryCode + row.phoneNumber)
              : '-'}
          </TableCell>
          <TableCell>{'Marketer'}</TableCell>
          <TableCell>{row.status ? 'Active' : 'Inactive'}</TableCell>
          <TableCell>{row?.organization?.name}</TableCell>
          {/* <TableCell>
            <Button
              sx={{
                minWidth: 'auto !important',
                padding: '0 10px !important',
              }}
              aria-describedby={id}
              onClick={(e) => handleClick(e, row)}>
              <img src={MoreIcon} alt="More" />
            </Button>
          </TableCell> */}
        </TableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );

  const tableData = React.useMemo(() => {
    return (
      <InfinityScroll
        paramState={setParams}
        scrollableTargetId={'Marketer'}
        data={tableInfo.list}
        rowPorops={rowProps}
        headRow={headRow}
        count={tableInfo.total}
        columnCount={7}
        loading={loading}
        params={params}
      />
    );
  }, [headRow, params, rowProps, tableInfo.list, tableInfo.total, loading]);

  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12}>
          <TableContainer component={Paper} className="recruiter-table">
            {tableData}
          </TableContainer>
        </Grid>
      </Grid>
      <Popover
        className="shadow-popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}>
        {editRow.status ? (
          <Typography component={'div'} className="inner-pop-text">
            <Typography className="arrow-top" />
            {!val.length && (
              <Typography>
                <a onClick={handleOpen2}>Edit</a>
              </Typography>
            )}
            {status !== 'all' && (
              <Typography>
                <a onClick={handleOpen1}>Remove</a>
              </Typography>
            )}
          </Typography>
        ) : (
          <Typography component={'div'} className="inner-pop-text">
            <Typography className="arrow-top" />
            {status !== 'all' && (
              <Typography>
                <a onClick={handleOpen3}>Active</a>
              </Typography>
            )}
          </Typography>
        )}
      </Popover>
      <AddAndEditUser
        row={editModal.row}
        editId={editModal.row?.id}
        visible={editModal.visible}
        onClose={handleClose2}
        title={'Edit Marketer'}
        roleId={4}
      />
      <DeleteModal
        visible={deleteModal.visible}
        onClose={handleClose1}
        onDone={onDelete}
        title="Marketer"
      />
      <ActiveModal
        visible={activeModal.visible}
        onClose={handleClose3}
        onDone={onActive}
        title="Marketer"
      />
    </Typography>
  );
};
