import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { deleteData } from 'services/CommonServices';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { deleteRequest, postRequest } from 'utils/Handlers';
import Storage from 'utils/Storage';

import {
  PORTAL_ERROR,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
  USER_UNAUTHORIZED,
} from '../types/UserTypes';

export const ssoLogin = (token: string, userIpName?: string) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const params = {
      data: {
        token,
        userIpName,
      },
      token: '',
      url: MASTER_ENDPOINT.SsoLogin,
    };
    return await postRequest(params)
      .then((response: any) => {
        const { accessToken, refreshToken: refreshToken1 } = response;
        const userData: any = jwt_decode(String(accessToken));
        userData['token'] = accessToken;
        userData['refreshToken'] = refreshToken1;
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });

        if (accessToken && refreshToken(refreshToken1) && userData?.id) {
          const tokenData: any = {
            refreshToken: refreshToken1,
            token: accessToken,
            userId: userData.id,
          };
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          Storage.setItem(Storage.KEYS.TOKEN_ID, userData.refreshTokenId);
        }
      })
      .catch((error: any) => {
        let errorMessage = '';
        if (error.error.message) {
          errorMessage = error.error.message;
        }
        dispatch({
          payload: errorMessage,
          type: USER_LOGIN_FAIL,
        });
        if (errorMessage === 'No Access To User') {
          dispatch(updateNotAuthorized(true));
          return;
        }
        if (
          errorMessage ==
          'You are trying to access the application from an invalid IP address'
        ) {
          dispatch(
            updateErrorPage(
              true,
              'You are not authorized to access this application from your current location',
            ),
          );
          return;
        }
        toast.error(
          errorMessage || 'Invalid username or password' || String(error),
          alertOptions,
        );
      });
  };
};

export const login = (email: string, password: string, userIpName?: string) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const params = {
      data: {
        email,
        password,
        userIpName,
      },
      token: '',
      url: MASTER_ENDPOINT.Login,
    };
    return await postRequest(params)
      .then((response: any) => {
        const { accessToken, refreshToken: refreshToken1 } = response;
        const userData: any = jwt_decode(String(accessToken));
        userData['token'] = accessToken;
        userData['refreshToken'] = refreshToken1;
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });

        if (accessToken && refreshToken(refreshToken1) && userData?.id) {
          const tokenData: any = {
            refreshToken: refreshToken1,
            token: accessToken,
            userId: userData.id,
          };
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          Storage.setItem(Storage.KEYS.TOKEN_ID, userData.refreshTokenId);
        }
      })
      .catch((error: any) => {
        let errorMessage = '';
        if (error.error.message) {
          errorMessage = error.error.message;
        }
        dispatch({
          payload: errorMessage,
          type: USER_LOGIN_FAIL,
        });

        toast.error(
          errorMessage || 'Invalid username or password' || String(error),
          alertOptions,
        );
      });
  };
};

export const refreshToken: any = (token: string) => (dispatch) => {
  dispatch({
    payload: token,
    type: USER_REFRESH_TOKEN,
  });
};

export const ssoLogout = async () => {
  const apiUrl = process.env.REACT_APP_APIURL;

  return await deleteRequest({
    ssoLogin: true,
    url: `${apiUrl}auth/cookies-logout`,
  });
};
export const logout = (sessionRemove?: boolean) => {
  return async (dispatch) => {
    const tokenId = Storage.getItem(Storage.KEYS.TOKEN_ID);
    if (tokenId && !sessionRemove) {
      await deleteData(tokenId, MASTER_ENDPOINT.LogoutUrl);
      dispatch({
        type: USER_LOGOUT,
      });
      window.location.replace('/');
    } else {
      window.location.replace('/');
    }

    localStorage.clear();
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    Storage.removeItem(Storage.KEYS.MENUS);
    Storage.removeItem(Storage.KEYS.TOKEN_ID);
  };
};

export const updateNotAuthorized = (val: boolean) => (dispatch) => {
  dispatch({
    payload: val,
    type: USER_UNAUTHORIZED,
  });
};
export const updateErrorPage =
  (val = false, message = 'Something Went wrong. Please try again Later') =>
  (dispatch) => {
    dispatch({
      payload: { message, show: val },
      type: PORTAL_ERROR,
    });
  };
// export const getTokenDetail = () => {
//   return async (dispatch) => {
//     const tokenStatusDetails = await Storage.isTokenValidDetails();

//     if (!tokenStatusDetails || !tokenStatusDetails.id) {
//       Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
//       dispatch({
//         payload: 'Token Expired!',
//         type: USER_LOGIN_FAIL,
//       });
//     } else {
//       dispatch({
//         payload: tokenStatusDetails,
//         type: USER_LOGIN_SUCCESS,
//       });
//     }
//   };
// };
