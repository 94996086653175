export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const EMAIL_CHECKED = 'EMAIL_CHECKED';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
export const USER_IMAGE_ID_CHANGE = 'USER_IMAGE_ID_CHANGE';
export const USER_FILTER_STATUS = 'USER_FILTER_STATUS';
export const USER_SEARCH = 'USER_SEARCH';
export const USER_RELOAD = 'USER_RELOAD';
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const USER_SKILL_DATA = 'USER_SKILL_DATA';
export const USER_CUSTOMER_DATA = 'USER_CUSTOMER_DATA';
export const USER_RECRUITER_DATA = 'USER_RECRUITER_DATA';
export const USER_MARKETER_DATA = 'USER_MARKETER_DATA';
export const USER_JOB_DATA = 'USER_JOB_DATA';
export const USER_CANDIDATES_DATA = 'USER_CANDIDATES_DATA';
export const USER_JOB_FILTER_DATA = 'USER_JOB_FILTER_DATA';
export const USER_CANDIDATE_FILTER_DATA = 'USER_CANDIDATE_FILTER_DATA';
export const USER_CANDIDATE_JOB_FILTER_DATA = 'USER_CANDIDATE_JOB_FILTER_DATA';
export const USER_JOB_TAB_DATA = 'USER_JOB_TAB_DATA';
export const USER_JOB_FILTER_CUSTOMER = 'USER_JOB_FILTER_CUSTOMER';
export const COMPANY_STATUS_FILTER = 'COMPANY_STATUS_FILTER';
export const USER_UNAUTHORIZED = 'USER_UNAUTHORIZED';
export const PORTAL_ERROR = 'PORTAL_ERROR';
