import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CheckIcon from 'assets/images/lightgreencheck.svg';
import MoreIcon from 'assets/images/MoreIcon.svg';
import CustomSelect from 'components/CustomSelect/customSelect';
import InfinityScroll from 'components/InfinityScroll';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  USER_CUSTOMER_DATA,
  USER_JOB_FILTER_CUSTOMER,
} from 'store/types/UserTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';

const staticParam = {
  order: 'desc',
  reload: true,
  skip: 0,
  sortby: '',
  take: 50,
};

export const JobPosting: React.FC<any> = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [params, setParams] = React.useState<any>({});
  const [loading, setLoading] = React.useState<any>(true);
  const [tableInfo, setTableInfo] = React.useState<any>({
    list: [],
    total: 0,
  });
  const [jobId, setJobid] = React.useState('');
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    ids: string,
  ) => {
    setJobid(ids);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setJobid('');
    setAnchorEl(null);
  };
  const dispatch: any = useDispatch();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { customerInfo, searchText, customerFilter, companyFilter }: any =
    useAppSelector((store: any) => store.userLogin);

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomer = () => {
    getAllListData(
      MASTER_ENDPOINT.Customer +
        '?status=true&order=asc&sortby=name&publish=true&deletedStatus=false',
    ).then((resp: any) => {
      if (!_.isEqual(customerInfo, resp?.data)) {
        dispatch({
          payload: resp.data,
          type: USER_CUSTOMER_DATA,
        });
      }
    });
  };

  useEffect(() => {
    if (Object.keys(params).length) {
      getJobData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (Object.keys(params).length) {
      setParams({ ...staticParam, reload: !params.reload });
    } else {
      setParams(staticParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, companyFilter, customerFilter]);

  const getJobData = async () => {
    setLoading(true);
    setTableInfo({
      list: [],
      total: 0,
    });
    let url = `${
      MASTER_ENDPOINT.Jobs
    }?id=&publish=true&deletedStatus=false&organizationId=${String(
      companyFilter,
    )}&sortby=${String(params.sortby)}&order=${String(
      params.order,
    )}&search=${String(searchText)}&skip=${String(params.skip)}&take=${String(
      params.take,
    )}`;
    if (customerFilter && customerFilter !== 'all') {
      url += '&customerId=' + customerFilter;
    }
    await getAllListData(url)
      .then((resp) => {
        setLoading(false);
        setTableInfo({
          list: resp.data,
          total: resp?.total,
        });
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const headRow = React.useCallback(() => {
    return (
      <TableHead>
        <TableRow>
          <TableCell className="w-20">
            <Typography
              sx={{
                display: 'flex',
              }}>
              <TableCell className="w-20">Customer</TableCell>
              <CustomSelect
                onChange={(e: any) => {
                  dispatch({
                    payload: e?.target?.value,
                    type: USER_JOB_FILTER_CUSTOMER,
                  });
                }}
                value={''}
                searchable={true}
                valueHighLigt={customerFilter}
                labelKey={'name'}
                valueKey={'id'}
                options={[{ id: 'all', name: 'All' }, ...customerInfo]}
                // placeHolder={'Customer'}
                className={'common-select-table'}
              />
            </Typography>
          </TableCell>
          <TableCell className="w-22">Job Title</TableCell>
          <TableCell className="w-13">Created By</TableCell>
          <TableCell className="w-10">Date Added</TableCell>
          <TableCell className="w-10">Pay Rate</TableCell>
          <TableCell className="w-10">Position Type</TableCell>
          <TableCell className="w-10">Status</TableCell>
          <TableCell className="w-5" />
        </TableRow>
      </TableHead>
    );
  }, [customerFilter, customerInfo, dispatch]);

  const rowProps = React.useCallback(
    (row: any, index: number) => {
      return (
        <TableRow
          key={index}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            cursor: 'pointer',
          }}
          onClick={() => navigate('/admin/view-job/admin/' + row?.id)}>
          <TableCell>
            <Typography
              className="td-text"
              sx={{
                paddingBottom: '15px',
                paddingTop: '15px',
              }}>
              {row?.customer?.name}
            </Typography>
          </TableCell>
          <TableCell>{row.jobTitle}</TableCell>
          <TableCell>
            {row?.user ? row?.user?.firstName + ' ' + row?.user?.lastName : ''}
          </TableCell>
          <TableCell>
            {row.createdAt ? moment(row.createdAt).format('MM/DD/YYYY') : ''}
          </TableCell>
          <TableCell>
            {row.payRateType === 'Salary'
              ? `$${Number(row.salaryDesired)}`
              : `$${Number(row.payRangeFrom)} - $${Number(row.payRangeTo)}`}
          </TableCell>
          <TableCell>{row.positionType}</TableCell>
          <TableCell>
            {row.status === 'published' ? (
              <Typography component={'div'} className="post-badge d-flex-ja">
                Posted
              </Typography>
            ) : row.status === 'closed' ? (
              <Typography component={'div'} className="tick-badge d-flex-ja">
                <img src={CheckIcon} alt="Tick" />
                Filled
              </Typography>
            ) : (
              <Typography component={'div'} className="match-badge d-flex-ja">
                {capitalize(row.status)}
              </Typography>
            )}
          </TableCell>
          <TableCell>
            <Button
              sx={{
                minWidth: 'auto !important',
                padding: '5px 15px !important',
              }}
              aria-describedby={id}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e, row?.id);
              }}>
              <img src={MoreIcon} alt="More" key={index} />
            </Button>
          </TableCell>
        </TableRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const tableData = React.useMemo(() => {
    return (
      <InfinityScroll
        paramState={setParams}
        params={params}
        scrollableTargetId={'job'}
        data={tableInfo.list}
        rowPorops={rowProps}
        headRow={headRow}
        count={tableInfo.total}
        columnCount={8}
        loading={loading}
      />
    );
  }, [headRow, params, rowProps, tableInfo.list, tableInfo.total, loading]);

  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12}>
          <TableContainer component={Paper} className="jobpost-table">
            {tableData}
          </TableContainer>
        </Grid>
        <Popover
          className="shadow-popover"
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}>
          <Typography component={'div'} className="inner-pop-text">
            <Typography className="arrow-top" />
            <Typography>
              <Link
                to={'/admin/view-job/admin/' + jobId}
                style={{
                  color: '#fff',
                  textDecoration: 'unset',
                }}>
                View Job
              </Link>
            </Typography>
          </Typography>
        </Popover>
      </Grid>
    </Typography>
  );
};
