import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import DeleteIcon from 'assets/images/deletepng.png';
import React from 'react';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  flexDirection: 'column',
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 780,
};

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  onDone: () => void;
  title: string;
}

const DeleteModal = (props: ModalProps) => {
  return (
    <Modal
      className="job-delete-modal"
      open={props.visible}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style} className="confirmation-widget d-flex-ja">
        <IconButton
          aria-label="Close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}>
          <CloseIcon />
        </IconButton>
        <img src={DeleteIcon} alt="delete" />
        <Typography
          sx={{
            fontSize: '24px !important',
            margin: '19px 0 28px 0',
          }}>
          Are you sure you want to DELETE this {props.title}?
        </Typography>
        <Typography className="confirm-btn model-btn" component={'div'}>
          <Button
            className="no-btn"
            onClick={props.onClose}
            sx={{
              borderRadius: '8px',
              textTransform: 'capitalize',
            }}>
            No, Cancel
          </Button>
          <Button
            className="job-delete-btn"
            onClick={props.onDone}
            sx={{
              borderRadius: '8px',
              fontSize: '16px !important',
              textTransform: 'capitalize',
            }}>
            YES, DELETE {props.title}
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
