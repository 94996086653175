import RecruiterAndMarketerLayout from 'components/RecruiterAndMarketerLayout';
import { ResumeValidationTable } from 'pages/common/ResumeValidation/ResumeValidation';
import { Candidates } from 'pages/recruiter/Candidates';
import { JobPosting } from 'pages/recruiter/JobPosting';
import { ViewJob } from 'pages/recruiter/JobPosting/ViewJob';
import Myprofile from 'pages/recruiter/Myprofile';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RecruiterRoute } from '../index';

const RecruiterRoutes = () => {
  const { CANDIDATES, JOBPOST, VIEWJOB, MYPROFILE, RESUMEVALIDATION } =
    RecruiterRoute;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={JOBPOST} />} />
      <Route
        path={JOBPOST}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: JOBPOST,
              show: false,
              title: 'Job Posting',
            }}
            from={'jobpost'}
            showHeader={true}>
            <JobPosting />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={VIEWJOB}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: VIEWJOB,
              show: false,
              title: 'View Job',
            }}
            from={'viewjob'}
            showHeader={true}>
            <ViewJob />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={CANDIDATES}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: CANDIDATES,
              show: false,
              title: 'Candidates',
            }}
            from={'candidates'}
            showHeader={true}>
            <Candidates />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={RESUMEVALIDATION}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: RESUMEVALIDATION,
              show: false,
              title: 'Resume Validation',
            }}
            from={'resumevalidation'}
            showHeader={true}>
            <ResumeValidationTable />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route
        path={MYPROFILE}
        element={
          <RecruiterAndMarketerLayout
            routes={{
              path: MYPROFILE,
              show: false,
              title: 'Job Posting',
            }}
            from={'profile'}
            showHeader={true}>
            <Myprofile />
          </RecruiterAndMarketerLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default RecruiterRoutes;
