import 'react-phone-input-2/lib/style.css';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { USER_RELOAD } from 'store/types/UserTypes';
import { AddAdmin } from 'types';
// import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addAdminSchema } from 'utils/ValidatorSchema';

import useStyles from '../private/PrivateStyle';

interface UserProps {
  row?: any;
  editId?: string;
  visible: boolean;
  onClose: () => void;
  title: string;
  roleId: number;
}

const style = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  minHeight: 363,
  padding: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '120px',
  transform: 'translate(-50%, -60px)',
  width: 780,
};

const AddAndEditUser = (props: UserProps) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();
  const [countryCode, setCountryCode] = React.useState('');
  // const [checkedIpStatus, setCheckedIpStatus] = React.useState<boolean>(false);
  const [list, setList] = React.useState([]);
  // const [validPhoneNumber, setValidPhoneNumber] = React.useState<boolean>(true);
  useEffect(() => {
    if (props.visible) {
      getAllListData(
        MASTER_ENDPOINT.Organization + '?order=asc&sortby=name',
      ).then((resp: any) => {
        setList(resp?.data);
      });
    }
  }, [props.visible]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<AddAdmin>({
    defaultValues: {
      email: '',
      empCode: '',
      firstName: '',
      ipStatus: false,
      lastName: '',
      organizationId: '',
      phoneNumber: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(addAdminSchema),
  });

  useEffect(() => {
    if (props.row) {
      setValue('email', props.row?.login?.email);
      setValue('empCode', props.row?.empCode);
      setValue('firstName', props.row?.firstName);
      setValue('lastName', props.row?.lastName);
      setValue('ipStatus', props.row?.ipStatus);
      // setCheckedIpStatus(props.row?.ipStatus);
      if (props.row?.phoneNumber) {
        setValue(
          'phoneNumber',
          '+' + String(props.row?.countryCode) + String(props.row?.phoneNumber),
        );
        setCountryCode(props.row?.countryCode);
      }
      setValue('organizationId', props.row?.organizationId);
    }
  }, [props.row, setValue]);

  const submitForm = (data: any) => {
    // if (!validPhoneNumber) {
    //   toast.error('Invalid Phone number', alertOptions);
    //   return;
    // }
    const formData = new FormData();
    formData.append('roleId', props.roleId.toString());
    formData.append('empCode', data['empCode'].trim());
    formData.append('firstName', data['firstName'].trim());
    formData.append('lastName', data['lastName'].trim());
    formData.append('organizationId', data['organizationId']);
    formData.append('ipStatus', String(data['ipStatus']));

    if (data['phoneNumber']) {
      const restOfPhoneNumber = data['phoneNumber']
        ?.split(`+${countryCode}`)[1]
        ?.replace(/[^0-9]/g, '');

      // const phoneWithoutCountry = String(data['phoneNumber'])
      //   .split(/(?<=^\S+)\s/)[1]
      //   .replace(/[^0-9]/g, '');
      formData.append(
        'phoneNumber',
        restOfPhoneNumber ? restOfPhoneNumber : '',
      );
      formData.append('countryCode', countryCode ? countryCode : '1');
    }
    if (props.editId) {
      formData.append('status', props.row.status);
      updateData(
        props.editId,
        formData,
        MASTER_ENDPOINT.Admins + '/profile',
        true,
      )
        .then((resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
          resetForm();
        })
        .catch((err: any) => {});
    } else {
      formData.append('email', data['email']);
      formData.append('status', 'true');
      addNewData(formData, MASTER_ENDPOINT.Admins, {}, true)
        .then((resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
          resetForm();
        })
        .catch((err: any) => {});
    }
  };

  const resetForm = () => {
    reset();
    props.onClose();
  };

  return (
    <Modal
      open={props.visible}
      // onClose={resetForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <IconButton
          aria-label="Close"
          onClick={resetForm}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}>
          <CloseIcon />
        </IconButton>
        <Typography className="recrutor-title">{props.title}</Typography>
        <form onSubmit={handleSubmit(submitForm)}>
          <Grid container columnSpacing={2} rowSpacing={1.5}>
            <Grid item md={6} sm={12}>
              <Typography>
                <label>Company</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="organizationId"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomSelect
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        value={value}
                        labelKey={'name'}
                        valueKey={'id'}
                        options={list}
                        name={'fileType'}
                        placeHolder={''}
                        disabled={props.editId ? true : false}
                        className={'common-select'}
                        error={errors?.organizationId?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography>
                <label>Emp Code</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="empCode"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className={classes.custominput}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        error={errors?.empCode?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography>
                <label>First Name</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="firstName"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className={classes.custominput}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e.target.value.replace(/[^a-zA-Z s]/g, ''));
                        }}
                        error={errors?.firstName?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography>
                <label>Last Name</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="lastName"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className={classes.custominput}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e.target.value.replace(/[^a-zA-Z s]/g, ''));
                        }}
                        error={errors?.lastName?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography>
                <label>Email</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        disabled={props.editId ? true : false}
                        placeHolder=""
                        value={value}
                        name={name}
                        className={classes.custominput}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        error={errors?.email?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography>
                <label>Phone #</label>
                <Controller
                  control={control}
                  defaultValue=""
                  name="phoneNumber"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <>
                        <PhoneInput
                          country={'us'}
                          inputStyle={{
                            border: '0px !important',
                          }}
                          value={value}
                          onChange={(value, data, event, formattedValue) => {
                            const codes: any = data;
                            onChange(formattedValue);
                            setCountryCode(codes?.dialCode);
                          }}
                          // isValid={(inputNumber, country: any, countries) => {
                          //   const removeFirst = inputNumber.slice(
                          //     country.dialCode.length,
                          //   );

                          //   if (removeFirst.length < 10 && isDirty) {
                          //     setValidPhoneNumber(false);
                          //     return false;
                          //   } else {
                          //     setValidPhoneNumber(true);
                          //     return true;
                          //   }
                          // }}
                        />
                        {errors?.phoneNumber?.message && (
                          <FormHelperText className="errorMsg">
                            {errors?.phoneNumber?.message}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography>
                <Controller
                  control={control}
                  defaultValue={false}
                  name="ipStatus"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <>
                        <FormControlLabel
                          className="default-chexbox"
                          control={
                            <Checkbox
                              defaultChecked={false}
                              color="success"
                              name={name}
                              checked={value}
                              onChange={(e) => {
                                onChange(e.target.checked);
                              }}
                            />
                          }
                          label="IP Restricted"
                        />

                        {errors?.ipStatus?.message && (
                          <FormHelperText className="errorMsg">
                            {errors?.ipStatus?.message}
                          </FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Typography
            component={'div'}
            className="d-flex-a flex-sb"
            sx={{ marginTop: '30px !important' }}>
            <Button className="cancel-btn" onClick={resetForm}>
              Cancel
            </Button>
            <Button className="save-btn" type={'submit'}>
              Save
            </Button>
          </Typography>
        </form>
      </Box>
    </Modal>
  );
};

export default AddAndEditUser;
