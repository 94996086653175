import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

import { EducationInfo } from './Information/EducationInfo';
import { ExperienceInfo } from './Information/ExperienceInfo';
import { ProfileInfo } from './Information/ProfileInfo';
import { SkillsInfo } from './Information/Skills';

type Props = {
  header: string;
  type: string;
  control: UseFormReturn<GeneralDetails>;
  closeAcc?: boolean;
  readOnly?: boolean;
  isFocused?: boolean;
  setIsFocused: (src: boolean) => void;
  show?: string;
  setShow?: (src: string) => void;
  linkedInVerification?: (src: boolean) => void;
};

export const AccordionInfo: React.FC<{
  type: string;
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
  isFocused?: boolean;
  setIsFocused: (src: boolean) => void;
  show?: string;
  setShow?: (src: string) => void;
  linkedInVerification?: (src: boolean) => void;
}> = ({
  type,
  control,
  readOnly,
  isFocused,
  setIsFocused,
  show,
  setShow,
  linkedInVerification,
}) => {
  switch (type) {
    case 'profileInfo':
      return (
        <ProfileInfo
          control={control}
          readOnly={readOnly}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          show={show}
          setShow={setShow}
          linkedInVerification={linkedInVerification}
        />
      );
    case 'experienceInfo':
      return <ExperienceInfo control={control} readOnly={readOnly} />;
    case 'educationInfo':
      return <EducationInfo control={control} readOnly={readOnly} />;
    case 'skils':
      return <SkillsInfo control={control} readOnly={readOnly} />;
    default:
      return null;
  }
};
export const AccordionView: React.FC<Props> = (props: Props) => {
  const {
    header,
    type,
    control,
    closeAcc,
    readOnly,
    isFocused,
    setIsFocused,
    show,
    setShow,
    linkedInVerification,
  } = props;
  const [expanded, setExpanded] = React.useState(true); // Initially expanded

  const handleChange = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (closeAcc) {
      setExpanded(false);
    }
  }, [closeAcc]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="accordion-root">
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="icon-color" />
          ) : (
            <AddIcon className="icon-color" />
          )
        }
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography component={'div'} className="accordion-heading">
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionInfo
          type={type}
          control={control}
          readOnly={readOnly}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          show={show}
          setShow={setShow}
          linkedInVerification={linkedInVerification}
        />
      </AccordionDetails>
    </Accordion>
  );
};
