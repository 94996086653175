import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material';
import profileimg from 'assets/images/profileimg.png';
import HeaderLogo from 'assets/images/RiseIt-black.svg';
import { MarketerRoute, RecruiterRoute, SalesRoute } from 'config';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COMPANY_STATUS_FILTER } from 'store/types/UserTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { getRequest } from 'utils/Handlers';

type headConfigsProps = {
  routes: any;
  showTools?: boolean;
  showHeader?: boolean;
  addEditRoute?: any;
  children: any;
  from?: string;
};

const RecruiterAndMarketerLayout = (props: headConfigsProps) => {
  const apiUrl = process.env.REACT_APP_APIURL;

  const dispatch = useDispatch();
  const [comapanyList, setCompanyList] = React.useState([]);
  const getCompanyList = () => {
    getAllListData(
      MASTER_ENDPOINT.Organization + '?order=asc&sortby=name',
    ).then((resp: any) => {
      setCompanyList(resp?.data);
    });
  };
  const [imageId, setImageId] = React.useState<string>('');
  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const { companyFilter, jobTab } = useAppSelector(
    (store: any) => store.userLogin,
  );
  const [portals, setPortals] = React.useState<
    Array<{
      id: number;
      name: string;
      status: boolean;
      url: string;
    }>
  >([]);

  React.useEffect(() => {
    setOpen(false);
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.from]);

  React.useEffect(() => {
    setImageId(userInfo?.imageId);
  }, [userInfo?.imageId]);

  const companyStatusChange = (sts: any) => {
    dispatch({
      payload: sts,
      type: COMPANY_STATUS_FILTER,
    });
    setOpen(!open);
  };

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const [filterName, setFilterName] = React.useState('');

  const getPortalAccess = React.useCallback(async () => {
    try {
      const res = await getRequest({
        ssoLogin: true,
        url: `${apiUrl}auth/applications`,
      });
      setPortals(
        res && res.data && Array.isArray(res.data) && res.data.length
          ? res.data
          : [],
      );
    } catch (error) {
      console.warn(error);
    }
  }, [apiUrl]);

  React.useEffect(() => {
    getPortalAccess();
  }, [getPortalAccess]);
  React.useEffect(() => {
    if (companyFilter) {
      const fitem = comapanyList.find((item: any) => item.id === companyFilter);
      setFilterName(fitem?.name);
    } else {
      setFilterName('All');
    }
  }, [comapanyList, companyFilter]);

  return (
    <Grid item md={12}>
      <Grid item md={12}>
        <Typography component={'div'}>
          <Typography component={'div'} className={'Consultant-header'}>
            <Grid container>
              <Grid item xs={6}>
                <Box
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '59px',
                    m: 0,
                    pl: 2.5,
                  }}>
                  <Typography>
                    <img src={HeaderLogo} />
                  </Typography>
                  <Typography>
                    <ul style={{ paddingLeft: '55px' }}>
                      <li
                        className={
                          props.from === 'jobpost' || props.from === 'viewjob'
                            ? 'active'
                            : ''
                        }>
                        <Link
                          to={
                            userInfo.roleId === 3
                              ? RecruiterRoute.JOBPOST
                              : userInfo.roleId === 4
                              ? MarketerRoute.JOBPOST
                              : SalesRoute.JOBPOST
                          }
                          className={'sidebarA'}>
                          Job Posting
                        </Link>
                      </li>
                      <li
                        className={props.from === 'candidates' ? 'active' : ''}>
                        <Link
                          className={'sidebarA'}
                          to={
                            userInfo.roleId === 3
                              ? RecruiterRoute.CANDIDATES
                              : userInfo.roleId === 4
                              ? MarketerRoute.CANDIDATES
                              : SalesRoute.CANDIDATES
                          }>
                          Candidates
                        </Link>
                      </li>
                      <li
                        className={
                          props.from === 'resumevalidation' ? 'active' : ''
                        }>
                        <Link
                          to={
                            userInfo?.roleId === 5
                              ? SalesRoute.RESUMEVALIDATION
                              : RecruiterRoute.RESUMEVALIDATION
                          }
                          className={'sidebarA'}>
                          Resume Validation
                        </Link>
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    alignItems: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    height: '59px',
                    justifyContent: 'flex-end',
                    m: 0,
                    p: 0,
                  }}>
                  {portals &&
                    portals.map((e, i) => (
                      <Typography
                        component={e.id === 1 ? 'a' : 'div'}
                        href={e.id === 1 ? e.url : ''}
                        target={'_blank'}
                        key={e.id}
                        className={`sso-app ${
                          i === 0 && portals.length >= 2 ? 'left' : 'rigth'
                        } ${e.id === 2 ? 'active-sso-portal' : ''}`}>
                        {e.name}
                      </Typography>
                    ))}
                  {props.from === 'jobpost' &&
                    ([3, 4].includes(userInfo.roleId) ||
                      (userInfo.roleId === 5 && jobTab === 0)) && (
                      <Grid
                        container
                        justifyContent="right"
                        sx={{
                          width: 'auto',
                        }}>
                        <Typography>
                          {open && (
                            <ClickAwayListener
                              onClickAway={() => setOpen(false)}>
                              <Popper
                                className="popwidget"
                                open={open}
                                anchorEl={anchorEl}
                                placement={placement}
                                transition>
                                {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={350}>
                                    <Paper>
                                      <Typography sx={{ p: 2 }}>
                                        <Typography
                                          onClick={() =>
                                            companyStatusChange('')
                                          }
                                          component={'p'}
                                          sx={{
                                            cursor: 'pointer',
                                            margin: '10px',
                                            marginLeft: '3px',
                                          }}>
                                          {'All'}
                                        </Typography>
                                        {comapanyList.map(
                                          (item: any, index: number) => {
                                            return (
                                              <Typography
                                                key={index}
                                                onClick={() =>
                                                  companyStatusChange(item?.id)
                                                }
                                                component={'p'}
                                                sx={{
                                                  cursor: 'pointer',
                                                  margin: '10px',
                                                  marginLeft: '3px',
                                                }}>
                                                {item?.name}
                                              </Typography>
                                            );
                                          },
                                        )}
                                      </Typography>
                                    </Paper>
                                  </Fade>
                                )}
                              </Popper>
                            </ClickAwayListener>
                          )}
                          <Grid container justifyContent="right">
                            <ul className="filterlist">
                              <li>
                                <Button
                                  className="header-company-btn"
                                  onClick={handleClick('bottom-end')}>
                                  Company: {filterName}
                                  {open ? (
                                    <KeyboardArrowUpIcon
                                      sx={{ color: '#a59999' }}
                                    />
                                  ) : (
                                    <KeyboardArrowDownIcon
                                      sx={{ color: '#a59999' }}
                                    />
                                  )}
                                </Button>
                              </li>
                            </ul>
                          </Grid>
                        </Typography>
                      </Grid>
                    )}
                  <Typography component={'div'} className="notify-bg">
                    <Typography className="header-profile">
                      <Link
                        to={
                          userInfo.roleId === 3
                            ? RecruiterRoute.MYPROFILE
                            : userInfo.roleId === 4
                            ? MarketerRoute.MYPROFILE
                            : SalesRoute.MYPROFILE
                        }
                        className={'sidebarA'}>
                        <Typography component={'div'} className="d-flex-a">
                          <Avatar
                            sx={{
                              background: '#e7e7e7',
                              borderRadius: '50%',
                              height: '32px !important',
                              width: '32px !important',
                            }}
                            alt="Cindy Baker"
                            src={
                              imageId
                                ? `${process.env.REACT_APP_APIURL}${
                                    MASTER_ENDPOINT.Files
                                  }/${String(imageId)}`
                                : profileimg
                            }
                          />
                          <ArrowDropDownIcon sx={{ fill: '#C5C5C5' }} />
                        </Typography>
                      </Link>
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
      </Grid>
      <Typography
        component={'div'}
        sx={{ marginBottom: 'unset !important' }}
        paragraph>
        {props.children}
      </Typography>
    </Grid>
  );
};

export default RecruiterAndMarketerLayout;
