import jwt_decode from 'jwt-decode';

import { decrypt, encrypt } from './LocalStorage';

type AuthTokenType = {
  token: string;
};

type MenuType = {
  menus: string;
};

class Storage {
  static KEYS = {
    AUTH_TOKEN: 'AUTH_TOKEN',
    MENUS: 'MENUS',
    REMEMBER_ME: 'REMEMBER_ME',
    TOKEN_ID: 'TOKEN_ID',
  };

  static setItem = (key: string, value: string): void => {
    if (!value) {
      value = '';
    }

    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(encrypt(key), encrypt(value));
  };

  static getItem = (key: string, shouldParse?: boolean): string | null => {
    const keyVal = decrypt(localStorage.getItem(Storage.searchKey(key)));

    if (shouldParse && keyVal) {
      return JSON.parse(keyVal);
    }

    return keyVal;
  };

  static searchKey(key: string): string {
    const keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      try {
        if (decrypt(keys[i]) === key) {
          return keys[i];
        }
      } catch (e) {
        localStorage.removeItem(keys[i]);
      }
    }

    return '';
  }

  static removeItem = (key: string): void => {
    localStorage.removeItem(Storage.searchKey(key));
  };

  static clear = (): void => {
    localStorage.clear();
  };

  static getTokens = () => {
    try {
      const appAuth = Storage.getItem(Storage.KEYS.AUTH_TOKEN);
      return appAuth ? (JSON.parse(appAuth) as AuthTokenType) : null;
    } catch (e) {
      return null;
    }
  };

  static getMenus = () => {
    try {
      const appMenu = Storage.getItem(Storage.KEYS.MENUS);
      return appMenu ? (JSON.parse(appMenu) as MenuType) : null;
    } catch (e) {
      return null;
    }
  };

  static isTokenValidDetails = () => {
    const tokenData: any = Storage.getTokens();
    if (!tokenData || !tokenData.token) {
      return false;
    }
    const userData: any = jwt_decode(String(tokenData.token));
    if (!userData || (userData && userData.exp * 1000 < Date.now())) {
      return false;
    }
    return userData;
  };

  static updateAccessToken = (token) => {
    const tokenData: any = Storage.getTokens();
    tokenData.token = token;
    Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
  };
}

export default Storage;
