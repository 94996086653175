/* eslint-disable react/react-in-jsx-scope */
function SuccessUploadResume({ baseColor }: { baseColor?: any }) {
  return (
    <svg
      width="235"
      height="206"
      viewBox="0 0 235 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M200.55 126.41H165.855C164.422 126.41 163.229 125.216 163.229 123.784C163.229 122.352 164.422 121.158 165.855 121.158H200.55C216.386 121.158 229.198 108.266 229.198 92.51C229.198 76.7537 216.307 63.7826 200.55 63.7826C199.834 63.7826 199.118 63.5439 198.561 62.9868C198.084 62.5093 197.765 61.7931 197.845 60.9974C198.243 51.4481 195.06 42.5354 188.853 36.0101C182.805 29.7234 174.21 26.0629 165.457 26.0629C160.603 26.0629 155.748 27.177 151.531 29.246C150.815 29.5643 150.019 29.6439 149.303 29.3255C148.586 29.0868 148.029 28.4502 147.87 27.734C143.653 14.5241 131.557 5.69104 117.631 5.69104C103.705 5.69104 91.6885 14.6037 87.3913 27.8932C87.1526 28.6889 86.5956 29.246 85.9589 29.4847C85.2427 29.7234 84.447 29.7234 83.7308 29.4051C79.5132 27.2565 74.7385 26.222 69.8047 26.222C60.9716 26.222 52.4568 29.8826 46.4089 36.1692C40.1223 42.615 37.0188 51.5277 37.4167 61.1565C37.4963 61.8727 37.178 62.5889 36.7005 63.146C36.223 63.6234 35.5068 63.9417 34.7111 63.9417C18.8751 63.9417 6.06317 76.8333 6.06317 92.5896C6.06317 108.346 18.9547 121.238 34.7111 121.238H76.0118C77.4441 121.238 78.6378 122.431 78.6378 123.864C78.6378 125.296 77.4441 126.49 76.0118 126.49H34.9498C16.1695 126.49 0.970215 111.211 0.970215 92.51C0.970215 73.8093 14.7371 60.0424 32.2442 58.6101C32.4033 48.5833 36.0639 39.3523 42.7484 32.3495C49.8308 25.0284 59.778 20.8108 69.9639 20.8108C74.6589 20.8108 79.2744 21.6065 83.492 23.2777C89.142 9.3516 102.591 0.200195 117.79 0.200195C132.989 0.200195 146.358 9.3516 152.008 23.1981C156.226 21.527 160.841 20.7312 165.536 20.7312C175.722 20.7312 185.669 24.9488 192.752 32.3495C199.357 39.2727 203.097 48.5037 203.256 58.5305C220.763 59.9629 234.53 74.6051 234.53 92.51C234.53 110.415 219.331 126.41 200.55 126.41Z"
        fill="#F5F5F5"
      />
      <path
        d="M163.07 181H78.9562C75.9322 181 73.5449 178.533 73.5449 175.589V71.9791C73.5449 68.9552 76.0118 66.5679 78.9562 66.5679H141.584C142.3 66.5679 143.016 66.8066 143.494 67.3636L167.685 91.5552C168.163 92.0327 168.481 92.7489 168.481 93.465V175.669C168.481 178.533 166.093 181 163.07 181ZM78.9562 71.82C78.8766 71.82 78.8766 71.8996 78.8766 71.8996V175.509C78.8766 175.589 78.9562 175.589 78.9562 175.589H162.99C163.07 175.589 163.07 175.509 163.07 175.509V94.4996L140.47 71.82H78.9562Z"
        fill="#F5F5F5"
      />
      <path
        d="M128.055 156.809H113.97C112.538 156.809 111.344 155.615 111.344 154.183V121.078H103.386C102.352 121.078 101.477 120.521 101.079 119.646C100.601 118.771 100.681 117.656 101.238 116.94L118.824 91.8734C119.302 91.1572 120.098 90.7593 120.973 90.7593C121.848 90.7593 122.644 91.1572 123.122 91.8734L140.708 116.94C141.345 117.736 141.345 118.85 140.867 119.646C140.39 120.521 139.515 121.078 138.56 121.078H130.761V154.183C130.761 155.615 129.567 156.809 128.055 156.809ZM116.596 151.556H125.429V118.452C125.429 117.02 126.623 115.826 128.055 115.826H133.467L120.973 98.0804L108.559 115.826H113.97C115.403 115.826 116.596 117.02 116.596 118.452V151.556Z"
        fill="#F5F5F5"
      />
      <path
        d="M165.855 96.0911H141.584C140.151 96.0911 138.958 94.8975 138.958 93.4651V69.2735C138.958 67.8411 140.151 66.6475 141.584 66.6475C143.016 66.6475 144.21 67.8411 144.21 69.2735V90.839H165.855C167.287 90.839 168.481 92.0327 168.481 93.4651C168.481 94.8975 167.287 96.0911 165.855 96.0911Z"
        fill="#F5F5F5"
      />
      <path
        d="M121.5 206C132.822 206 142 196.822 142 185.5C142 174.178 132.822 165 121.5 165C110.178 165 101 174.178 101 185.5C101 196.822 110.178 206 121.5 206Z"
        fill="#00BA00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.099 195.498L107.602 187.473C106.575 186.375 106.634 184.637 107.733 183.61C108.831 182.584 110.569 182.643 111.596 183.741L117.379 189.931L126.533 181.378C126.616 181.301 126.702 181.23 126.791 181.166L131.095 177.144C132.194 176.117 133.932 176.176 134.959 177.275C135.985 178.373 135.926 180.112 134.828 181.138L121.679 193.423L121.665 193.408L117.117 197.657L115.099 195.498Z"
        fill="white"
      />
    </svg>
  );
}

export default SuccessUploadResume;
