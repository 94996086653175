import Storage from 'utils/Storage';

import {
  COMPANY_STATUS_FILTER,
  EMAIL_CHECKED,
  PORTAL_ERROR,
  USER_CANDIDATE_FILTER_DATA,
  USER_CANDIDATE_JOB_FILTER_DATA,
  USER_CANDIDATES_DATA,
  USER_CUSTOMER_DATA,
  USER_FILTER_STATUS,
  USER_IMAGE_ID_CHANGE,
  USER_JOB_DATA,
  USER_JOB_FILTER_CUSTOMER,
  USER_JOB_FILTER_DATA,
  USER_JOB_TAB_DATA,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_MARKETER_DATA,
  USER_PROFILE_DATA,
  USER_RECRUITER_DATA,
  USER_REFRESH_TOKEN,
  USER_RELOAD,
  USER_SEARCH,
  USER_SKILL_DATA,
  USER_UNAUTHORIZED,
} from '../types/UserTypes';

const user = Storage.isTokenValidDetails();

export interface PortalErrorParams {
  show: boolean;
  message: string;
}
export interface UserState {
  candidatesInfo?: Array<any>;
  loading?: boolean;
  error?: string;
  userInfo?: any;
  isLoggedIn?: boolean;
  emailRemember?: string;
  status?: any;
  searchText?: any;
  reload: boolean;
  profileInfo?: any;
  skillInfo?: Array<any>;
  customerInfo?: Array<any>;
  recruiterInfo?: Array<any>;
  marketerInfo?: Array<any>;
  jobInfo?: Array<any>;
  jobFilter?: JobFilterParams;
  candidateFilter?: CandidateFilterParams;
  jobTab?: number;
  customerFilter: string;
  companyFilter: string;
  candidateJobFilter?: CandidateFilterParams;
  isRestricted?: boolean;
  isPortalError?: PortalErrorParams;
}

export interface JobFilterParams {
  applyFilter: boolean;
  location: string;
  payRangeFrom: string;
  payRangeTo: string;
  skillData: Array<any>;
  title: string;
  workLocation: string;
  workStatus: string;
}

export interface CandidateFilterParams {
  applyFilter: boolean;
  location: string;
  title: string;
  workAuth: Array<any>;
  skillData: Array<any>;
  organizationId: string;
}

export interface Action {
  type: string;
  payload?: string;
}

export const jobFilterData = {
  applyFilter: false,
  location: '',
  payRangeFrom: '',
  payRangeTo: '',
  salaryFrom: 0,
  salaryTo: 0,
  skillData: [],
  title: '',
  workLocation: '',
  workStatus: '',
};

export const candidateFilterData = {
  applyFilter: false,
  booleanSearch: '',
  location: '',
  organizationId: '',
  skillData: [],
  tabSearch: '1',
  title: '',
  workAuth: [],
};
export const candidateJobFilterData = {
  applyFilter: false,
  booleanSearch: '',
  location: '',
  organizationId: '',
  skillData: [],
  title: '',
  workAuth: [],
};

const INITIAL_STATE: UserState = {
  candidateFilter: candidateFilterData,
  candidateJobFilter: candidateJobFilterData,
  candidatesInfo: [],
  companyFilter: '',
  customerFilter: '',
  customerInfo: [],
  emailRemember: Storage.getItem(Storage.KEYS.REMEMBER_ME),
  error: null,
  isLoggedIn: Storage.isTokenValidDetails() ? true : false,
  isPortalError: { message: '', show: false },
  isRestricted: false,
  jobFilter: jobFilterData,
  jobInfo: [],
  jobTab: 0,
  loading: false,
  marketerInfo: [],
  profileInfo: {},
  recruiterInfo: [],
  reload: false,
  searchText: '',
  skillInfo: [],
  status: 'all',
  userInfo: Storage.isTokenValidDetails()
    ? Storage.isTokenValidDetails()
    : null,
};

export const userLoginReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        candidateFilter: candidateFilterData,
        candidatesInfo: [],
        companyFilter: '',
        customerFilter: '',
        customerInfo: [],
        emailRemember: Storage.getItem(Storage.KEYS.REMEMBER_ME),
        error: null,
        isLoggedIn: false,
        jobFilter: jobFilterData,
        jobInfo: [],
        jobTab: 0,
        loading: false,
        marketerInfo: [],
        profileInfo: {},
        recruiterInfo: [],
        reload: false,
        searchText: '',
        skillInfo: [],
        status: 'all',
        userInfo: null,
      };
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        emailRemember: state.emailRemember,
        error: action.payload,
        isLoggedIn: false,
        loading: false,
        userInfo: null,
      };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...user, token: action.payload },
      };
    case USER_IMAGE_ID_CHANGE:
      state.userInfo.imageId = action.payload;
      return {
        ...state,
      };
    case USER_FILTER_STATUS:
      state.status = action.payload;
      return {
        ...state,
      };
    case USER_SEARCH:
      state.searchText = action.payload;
      return {
        ...state,
      };
    case EMAIL_CHECKED:
      return {
        ...state,
        emailRemember: action.payload,
      };
    case USER_RELOAD:
      state.reload = !state.reload;
      return {
        ...state,
      };
    case USER_PROFILE_DATA:
      return {
        ...state,
        profileInfo: action.payload,
      };
    case USER_SKILL_DATA:
      return {
        ...state,
        skillInfo: action.payload,
      };
    case USER_CUSTOMER_DATA:
      return {
        ...state,
        customerInfo: action.payload,
      };
    case USER_RECRUITER_DATA:
      return {
        ...state,
        recruiterInfo: action.payload,
      };
    case USER_MARKETER_DATA:
      return {
        ...state,
        marketerInfo: action.payload,
      };
    case USER_JOB_DATA:
      return {
        ...state,
        jobInfo: action.payload,
      };
    case USER_JOB_TAB_DATA:
      return {
        ...state,
        jobTab: action.payload,
      };
    case USER_JOB_FILTER_DATA:
      return {
        ...state,
        jobFilter: action.payload,
      };
    case USER_CANDIDATE_FILTER_DATA:
      return {
        ...state,
        candidateFilter: action.payload,
      };
    case USER_CANDIDATE_JOB_FILTER_DATA:
      return {
        ...state,
        candidateJobFilter: action.payload,
      };

    case USER_JOB_FILTER_CUSTOMER:
      return {
        ...state,
        customerFilter: action.payload,
      };
    case COMPANY_STATUS_FILTER:
      return {
        ...state,
        companyFilter: action.payload,
      };
    case USER_CANDIDATES_DATA:
      return {
        ...state,
        candidatesInfo: action.payload,
      };
    case USER_UNAUTHORIZED:
      return {
        ...state,
        isRestricted: action.payload,
      };
    case PORTAL_ERROR:
      return {
        ...state,
        isPortalError: action.payload,
      };
    default:
      return state;
  }
};
