import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

export const Dashboard: React.FC<any> = () => {
  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={12}>
          <Typography component={'div'} />
        </Grid>
      </Grid>
    </Typography>
  );
};
