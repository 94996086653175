import React from 'react';

function DummyProfileUploadResume() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="70"
      fill="none"
      viewBox="0 0 56 70">
      <g clipPath="url(#clip0_848_5424)">
        <path
          fill="#FBFDFE"
          d="M51.75 68.975H4.25a2.9 2.9 0 01-2.9-2.9V3.925a2.9 2.9 0 012.9-2.899H51.75a2.9 2.9 0 012.9 2.9v62.149a2.9 2.9 0 01-2.9 2.9z"
        />
        <path
          fill="#FFF7ED"
          d="M51.75 1.026h-8.662a2.9 2.9 0 012.9 2.9v62.148a2.9 2.9 0 01-2.9 2.9h8.661a2.9 2.9 0 002.9-2.9V3.925a2.9 2.9 0 00-2.9-2.9z"
        />
        <path
          fill="#D9D9D9"
          d="M24.581 1.026H4.251a2.9 2.9 0 00-2.9 2.9v62.148a2.9 2.9 0 002.9 2.9h20.33V1.026z"
        />
        <path
          fill="#FFE07D"
          d="M12.98 22.98a7.107 7.107 0 100-14.213 7.107 7.107 0 000 14.213z"
        />
        <path
          fill="#407093"
          d="M12.98 17.55a4.994 4.994 0 00-4.788 3.575 7.08 7.08 0 004.787 1.855 7.081 7.081 0 004.788-1.855 4.994 4.994 0 00-4.787-3.575z"
        />
        <path
          fill="#365E7D"
          d="M12.98 18.182a2.515 2.515 0 100-5.03 2.515 2.515 0 000 5.03z"
        />
        <path
          fill="#FFE07D"
          d="M18.923 37.97H7.036a1.19 1.19 0 01-1.19-1.19v-2.93c0-.658.533-1.19 1.19-1.19h11.887c.657 0 1.19.533 1.19 1.19v2.93a1.19 1.19 0 01-1.19 1.19zM18.923 48.84H7.036a1.19 1.19 0 01-1.19-1.19v-2.93c0-.657.533-1.19 1.19-1.19h11.887c.657 0 1.19.533 1.19 1.19v2.93a1.19 1.19 0 01-1.19 1.19zM18.923 59.711H7.036a1.19 1.19 0 01-1.19-1.19v-2.93c0-.657.533-1.19 1.19-1.19h11.887c.657 0 1.19.533 1.19 1.19v2.93a1.19 1.19 0 01-1.19 1.19z"
        />
        <path
          fill="#000"
          d="M35.412 12.806c.615 0 1.208.181 1.715.523a1.026 1.026 0 001.148-1.7 5.094 5.094 0 00-2.863-.874 5.124 5.124 0 00-5.118 5.118 5.125 5.125 0 005.118 5.119c1.129 0 2.141-.37 2.929-1.072.155-.138.301-.29.436-.45a1.026 1.026 0 00-1.572-1.318 2.265 2.265 0 01-.228.236c-.412.366-.939.552-1.565.552a3.07 3.07 0 01-3.067-3.067 3.07 3.07 0 013.067-3.067zM45.624 20.992h.008a1.214 1.214 0 001.14-.828l2.673-8.045a1.026 1.026 0 00-1.947-.647l-1.892 5.694-1.986-5.722a1.025 1.025 0 10-1.938.673l2.801 8.07c.172.482.63.805 1.14.805zM49.18 33.35H29.945a1.026 1.026 0 000 2.052H49.18a1.026 1.026 0 100-2.051zM29.944 41.694h9.618a1.026 1.026 0 100-2.052h-9.618a1.026 1.026 0 100 2.052zM49.18 45.934H29.945a1.026 1.026 0 100 2.051H49.18a1.026 1.026 0 000-2.051zM50.206 53.252c0-.567-.459-1.026-1.025-1.026H29.944a1.026 1.026 0 100 2.051H49.18c.566 0 1.025-.459 1.025-1.026zM29.944 58.685a1.026 1.026 0 100 2.052h9.618a1.026 1.026 0 000-2.052h-9.618zM49.18 27.059H29.945a1.026 1.026 0 000 2.05H49.18a1.026 1.026 0 100-2.05z"
        />
        <path
          fill="#727272"
          d="M54.649 14.393c.566 0 1.026-.46 1.026-1.026V3.925A3.93 3.93 0 0051.748 0H4.251A3.93 3.93 0 00.326 3.925v62.15A3.93 3.93 0 004.25 70H51.75a3.93 3.93 0 003.925-3.925V18.162a1.026 1.026 0 00-2.05 0v47.913c0 1.033-.842 1.874-1.875 1.874H25.607V2.05h26.142c1.033 0 1.874.841 1.874 1.874v9.442c0 .566.46 1.025 1.026 1.025zM23.555 67.948H4.251a1.876 1.876 0 01-1.874-1.873V3.925c0-1.033.84-1.874 1.874-1.874h19.304v65.897z"
        />
        <path
          fill="#000"
          d="M12.98 24.006c4.47 0 8.132-3.64 8.132-8.133 0-4.484-3.649-8.132-8.133-8.132-4.484 0-8.132 3.648-8.132 8.132 0 4.487 3.656 8.133 8.132 8.133zm0-2.052A6.046 6.046 0 019.418 20.8a4.019 4.019 0 012.059-1.927 3.518 3.518 0 003.002 0 4.02 4.02 0 012.06 1.927 6.046 6.046 0 01-3.56 1.155zm-1.49-6.287a1.49 1.49 0 011.49-1.489 1.49 1.49 0 011.488 1.489 1.49 1.49 0 01-1.489 1.489 1.49 1.49 0 01-1.489-1.489zm1.49-5.875a6.088 6.088 0 016.08 6.081c0 1.254-.381 2.42-1.034 3.39a5.974 5.974 0 00-1.95-1.884 3.518 3.518 0 00.444-1.712 3.545 3.545 0 00-3.54-3.54 3.545 3.545 0 00-3.541 3.54c0 .62.16 1.205.443 1.713a5.972 5.972 0 00-1.95 1.883 6.046 6.046 0 01-1.034-3.39 6.088 6.088 0 016.081-6.08zM7.036 38.995h11.886a2.218 2.218 0 002.216-2.215v-2.93a2.218 2.218 0 00-2.216-2.216H7.037a2.218 2.218 0 00-2.215 2.215v2.93c0 1.222.994 2.216 2.215 2.216zm-.164-5.146c0-.09.074-.164.164-.164h11.886c.09 0 .164.074.164.164v2.93c0 .091-.073.165-.163.165H7.035a.164.164 0 01-.164-.164v-2.93zM7.036 49.866h1.035a1.026 1.026 0 100-2.051H7.036a.164.164 0 01-.164-.164v-2.93c0-.091.074-.165.164-.165h11.886c.09 0 .164.074.164.164v2.93c0 .091-.073.165-.163.165h-6.057a1.026 1.026 0 000 2.051h6.056a2.218 2.218 0 002.216-2.215v-2.93a2.218 2.218 0 00-2.216-2.216H7.037a2.218 2.218 0 00-2.215 2.215v2.93c0 1.222.994 2.216 2.215 2.216zM7.036 60.737h11.886a2.218 2.218 0 002.216-2.215V55.59a2.218 2.218 0 00-2.216-2.215H7.037a2.218 2.218 0 00-2.215 2.215v2.93c0 1.222.994 2.216 2.215 2.216zm-.164-5.146c0-.09.074-.164.164-.164h11.886c.09 0 .164.073.164.164v2.93c0 .09-.073.164-.163.164H7.035a.164.164 0 01-.164-.164v-2.93z"
        />
      </g>
      <defs>
        <clipPath id="clip0_848_5424">
          <path fill="#fff" d="M0 0H56V70H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DummyProfileUploadResume;
