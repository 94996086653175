import React from 'react';

export default function SyncSvgIcon({ Color }: { Color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      fill="none"
      viewBox="0 0 24 26"
      style={{ transform: 'scale(0.9)' }}>
      <path
        fill={Color ? Color : '#BF0000'}
        d="M21.444 1.291S20.119 2.584 19.2 3.492C14.514.068 7.909.456 3.676 4.689 1.206 7.159.053 10.436.192 13.672h4C4.05 11.46 4.81 9.205 6.5 7.514c2.663-2.663 6.735-3.043 9.812-1.162-1.042 1.032-2.245 2.238-2.245 2.238-.841 1.009.104 1.592.584 1.577l5.624-.001.539.001H22.449c.298 0 .54-.243.54-.541V1.895c.034-.707-.742-1.393-1.545-.604zM2.555 24.709s1.326-1.293 2.243-2.201c4.688 3.424 11.292 3.036 15.526-1.197 2.47-2.471 3.622-5.747 3.484-8.983h-4.001a7.949 7.949 0 01-2.308 6.159c-2.663 2.662-6.735 3.043-9.812 1.161 1.042-1.032 2.245-2.238 2.245-2.238.84-1.01-.104-1.592-.584-1.577l-5.624.002c-.297 0-.54-.002-.54-.002H1.55a.542.542 0 00-.541.541v7.732c-.033.706.743 1.392 1.545.603z"
      />
    </svg>
  );
}
