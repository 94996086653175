import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  Typography,
} from '@mui/material';
import React from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';

type InfinityScrollInterFace = {
  paramState: any;
  height?: number;
  scrollableTargetId?: string;
  rowPorops: any;
  headRow: any;
  data: any;
  count: any;
  columnCount: number;
  loading: boolean;
  params: any;
};

const Loader = () => {
  return (
    <Typography
      component={'div'}
      sx={{
        justifyContent: 'center',
        paddingTop: '20px',
        textAlign: 'center',
      }}>
      <CircularProgress sx={{ color: '#340000' }} />
    </Typography>
  );
};

const InfinityScroll: React.FC<any> = (props: InfinityScrollInterFace) => {
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    props.paramState((prev) => ({
      ...prev,
      skip: newPage * Number(prev.take),
    }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    props.paramState((prev) => ({
      ...prev,
      skip: 0,
      take: parseInt(event.target.value, 10),
    }));
  };
  return (
    <>
      {/* <InfiniteScroll
        dataLength={props.data.length}
        next={() => {
          if (props.data.length < props.count) {
            props.paramState((prev) => ({
              ...prev,
              skip: Number(prev.skip) + Number(prev.take),
              take: prev.take,
            }));
          }
        }}
        hasMore={props.data.length < props.count}
        loader={<Loader />}
        height={props.height}
        scrollableTarget={props.scrollableTargetId}> */}
      <Table aria-label="customized table">
        {props.headRow()}
        <TableBody>
          {props?.data.length ? (
            props?.data.map((data: any, index: number) => {
              return props.rowPorops(data, index);
            })
          ) : (
            <TableCell
              colSpan={props.columnCount}
              sx={{
                color: '#241a2e',
                fontFamily: 'Red Hat Display SemiBold',
                fontSize: '14px',
                textAlign: 'center',
              }}>
              {props.loading ? <Loader /> : 'No Records Found'}
            </TableCell>
          )}
        </TableBody>
      </Table>
      {/* </InfiniteScroll> */}
      <TablePagination
        sx={{
          '& .MuiTablePagination-select': {
            fontFamily: 'Red Hat Display Medium !important',
          },
          '& p': {
            fontFamily: 'Red Hat Display Medium !important',
          },
        }}
        component="div"
        count={props.count ? parseInt(props.count) : 0}
        page={Number(props.params.skip) / Number(props.params.take)}
        onPageChange={handleChangePage}
        rowsPerPage={props.params.take ? parseInt(props.params.take) : 0}
        rowsPerPageOptions={[50, 100, 150, 200, 250]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default InfinityScroll;
