import React from 'react';

export default function ReadyForReviewIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      fill="none"
      viewBox="0 0 14 12">
      <path
        fill="#EE404C"
        d="M7.747.576l5.599 9.696A1.152 1.152 0 0112.348 12H1.152a1.152 1.152 0 01-.998-1.728L5.752.576a1.152 1.152 0 011.995 0z"
      />
      <path
        fill="#FFF7ED"
        d="M6.676 3.566h.146c.36 0 .652.292.652.651V7.33c0 .36-.292.651-.652.651h-.146a.651.651 0 01-.65-.65V4.216c0-.36.29-.65.65-.65zM6.75 10.448A.724.724 0 116.75 9a.724.724 0 010 1.448z"
      />
    </svg>
  );
}
