import React from 'react';

export default function UpArrowSvgIcon({ Color }: { Color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      fill="none"
      viewBox="0 0 15 13">
      <path
        stroke={Color ? Color : '#fff'}
        strokeLinecap="round"
        // strokeWidth="1.25"
        strokeWidth="1.8"
        d="M7.5 9.8V1.5m0 0l-4 4m4-4l4 4M1 12.5h13"
      />
    </svg>
  );
}
