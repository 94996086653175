export const fileApiUrl =
  process.env.REACT_APP_FILE_URL || `${window.location.origin}/api/aws-files/`;

export const alertOptions: any = {
  autoClose: 3000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: 'top-right',
  progress: undefined,
};

import { useLayoutEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    height,
    width,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const drawSignatureInfo = {
  height: '75px',
  width: '350px',
};

export const mimeTypes = `.rtf, application/rtf, application/pdf, image/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
   application/vnd.openxmlformats-officedocument.presentationml.presentation`;
