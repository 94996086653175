import { Button, FormHelperText, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton/customButton';
import CustomInput from 'components/CustomInput/customInput';
import _ from 'lodash';
import React, { useState } from 'react';
import { addNewData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

type Props = {
  closeNotes?: () => void;
  setValue?: (formVal: string, value: string) => void;
  currentValue?: string;
  hideLable?: boolean;
  hideButton?: boolean;
  setRefreshNotes?: (src: boolean) => void;
  candidateId?: string;
  readOnly?: boolean;
};

interface CandidateNotesResponse {
  id: string;
  message: string;
  status: number;
}

export const AddNotes: React.FC<Props> = (props: Props) => {
  const {
    closeNotes,
    setValue,
    currentValue = '',
    hideButton = false,
    setRefreshNotes,
    candidateId,
    readOnly,
  } = props;

  const [notes, setNotes] = useState<string>(currentValue ? currentValue : '');
  const [errorShown, setErrorShown] = useState<boolean>(false);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  const close = () => {
    closeNotes && closeNotes();
  };
  const saveNotes = () => {
    setErrorShown(true);
    if (notes || notes.trim()) {
      setValue && setValue('notes', notes);
      handleSaveNotes(candidateId);
    }
  };

  const handleSaveNotes = (candidateId: string) => {
    const AddNoteobj = {
      candidateId: candidateId,
      notes: notes,
      type: 'resumeValidation', // Send  type: 'resumeValidation' for salesLogin only
    };
    setBtnLoader(true);
    addNewData(AddNoteobj, MASTER_ENDPOINT.Candidates + '/notes', {}, true)
      .then((resp: CandidateNotesResponse) => {
        setRefreshNotes(true);
        setBtnLoader(false);
        close();
      })
      .catch((err) => {
        setRefreshNotes(false);
        setBtnLoader(false);
        console.log(err);
      });
  };

  return (
    <Typography component={'div'} className="mt-4 mb-7">
      {((!_.isEmpty(notes) && readOnly) || !readOnly) && (
        <Typography
          component={'label'}
          className="title label-figma pl-17 pb-2">
          Type your notes here
        </Typography>
      )}
      {((!_.isEmpty(notes) && readOnly) || !readOnly) && (
        <Typography component={'div'} className="mb-5 mt-4">
          <CustomInput
            value={notes}
            isTextArea={true}
            className="custom-textarea"
            minRows={6}
            disabled={readOnly ? true : false}
            onChange={(e) => {
              if (hideButton && e.target.value?.trim()) {
                setValue && setValue('notes', e.target.value?.trim());
              }
              setNotes(e.target.value?.trimStart());
            }}
          />
        </Typography>
      )}

      {errorShown && (!notes || !notes.trim()) && (
        <FormHelperText
          className="errorMsg"
          sx={{
            paddingLeft: '12px ',
          }}>
          {'Notes is required'}
        </FormHelperText>
      )}
      {hideButton
        ? null
        : ((!_.isEmpty(notes) && readOnly) || !readOnly) && (
            <Typography component={'div'} className="d-flex-jae pt-4">
              <Button
                className="rec-add-btn cancel-btn-notes mr-10"
                onClick={() => close()}>
                Cancel
              </Button>
              <CustomButton
                variant="contained"
                isStyle={true}
                name={'Submit'}
                loading={btnLoader}
                onPress={() => saveNotes()}
                className="rec-add-btn ad-btn-notes mr-10"
              />
            </Typography>
          )}
    </Typography>
  );
};
