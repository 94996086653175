import { Typography } from '@mui/material';
import React from 'react';

type Props = {
  tab: string;
  setAnalytics: (src: boolean) => void;
  setShownAddNotes?: (src: boolean) => void;
  shownNotes?: boolean;
  hideAddNotes?: boolean;
  FlagDropdown?: boolean;
  notesTotal?: number;
  Score?: string | number;
};
export const HeaderTab: React.FC<Props> = (props: Props) => {
  const {
    tab,
    setAnalytics,
    setShownAddNotes,
    shownNotes,
    hideAddNotes,
    FlagDropdown,
    notesTotal,
    Score,
  } = props;

  return (
    <Typography component={'div'}>
      <Typography component={'div'} className="d-flex-a flex-sb plr-14 pb-4">
        <Typography component={'div'} className="d-flex-a g-10">
          <Typography
            component={'div'}
            className={`resume-tab-header  ${
              tab === 'notes' ? 'red-color  highlight' : ''
            }`}
            onClick={() => setAnalytics(false)}>
            {!notesTotal ? 'Notes' : `Notes (${notesTotal})`}
          </Typography>

          <Typography
            component={'div'}
            className={`resume-tab-header  ${
              tab !== 'notes' ? 'red-color  highlight' : ''
            }`}
            onClick={() => setAnalytics(true)}>
            Analytical Details
          </Typography>
        </Typography>
        {Score ? (
          <Typography
            component={'div'}
            className="AnalyticsTotalScore ml-score">
            <span className="txt">{Score} %</span>
          </Typography>
        ) : null}
        {hideAddNotes
          ? null
          : !shownNotes && (
              <Typography
                component={'div'}
                className="btn-update-resume add-notes"
                onClick={() => setShownAddNotes(true)}>
                <span className="plus">+</span> Add Notes
              </Typography>
            )}
        {FlagDropdown ? (
          <Typography component={'div'} className="flag-dropdown" />
        ) : null}
      </Typography>
    </Typography>
  );
};
