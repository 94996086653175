import { Backdrop, Box, Modal, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Discard } from 'utils/type';

const style = {
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  borderRadius: '10px',
  boxShadow: 24,
  height: 150,
  left: '50%',
  overflow: 'auto',
  overflowY: 'auto',
  p: 4,
  padding: '10px',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 444,
};
const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0)',
}));

type JobListing =
  | {
      id: number;
      name: string;
      order: number;
      status: boolean;
    }
  | {
      label: string;
      value;
    };
export default function ResumeValActionModal({
  open,
  ClickFn,
}: {
  open: boolean;
  ClickFn: () => void;
  headerShown?: boolean;
  SubmitFn?: () => void;
  ResumeId?: Discard | null;
  Success?: boolean;
  Loader?: boolean;
  JobListingData?: JobListing;
  ReadyMadeSourceId?: number | string;
  JobTitle?: string;
  Customer?: string;
  placeHolder?: string;
}) {
  const [opn, setOpn] = useState<boolean>(false);

  useEffect(() => {
    setOpn(open);
  }, [open]);

  const handleClose = () => ClickFn();

  return (
    <Modal
      open={opn}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      BackdropComponent={CustomBackdrop}>
      <>
        <div className="modalBackdrop" onClick={handleClose} />
        <Box sx={style} className="UploadResumeinnerboxAction">
          <div className="ActionBody">
            <div className="dustbinTxt">
              <div className="txt">Are You Sure! You Want To Proceed ? </div>
            </div>
            <div className="mainBtn">
              <div className="btn discard" onClick={handleClose}>
                cancel
              </div>
              <div className="btn upload btn-red">Discard</div>
            </div>
          </div>
        </Box>
      </>
    </Modal>
  );
}
