import { FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import debouce from 'lodash.debounce';
import * as React from 'react';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

interface SelectProps {
  className: string;
  onChange: (src: any) => void;
  value?: any;
  type?: string;
  error?: any;
  valueObject?: any;
}

export default function CustomSelectSeach(props: SelectProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState<string>('');
  const [value, setValue] = React.useState<any>(null);

  React.useEffect(() => {
    if (props.valueObject) {
      setValue(props.valueObject);
      setInputValue(props.valueObject.name);
    }
  }, [props.valueObject]);

  const getSkillList = async (str: string) => {
    await getAllListData(
      MASTER_ENDPOINT.Skills +
        '?sortby=name&order=asc&status=true&primary=true&skip=0&take=20&search=' +
        encodeURIComponent(str),
    ).then((resp: any) => {
      const capitalizedArrayOfStrings = resp.data.map((e) => {
        e['name'] = e.name.charAt(0)?.toUpperCase() + e.name.slice(1);
        return e;
      });

      setOptions([...capitalizedArrayOfStrings]);
      return true;
    });
    return true;
  };

  const handleChange = (e: any) => {
    setSearchVal(e);
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      setLoading(true);
      (async () => {
        await getSkillList(searchVal); // For demo purposes.
        setLoading(false);
      })();
    }
  }, [open, searchVal]);

  React.useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedResults = React.useMemo(() => {
    return debouce(handleChange, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputValue, setInputValue] = React.useState<string>('');
  const changeInput = (e) => {
    if (e) {
      setInputValue(e.target.value);
      debouncedResults(e.target.value);
    } else {
      setInputValue('');
      debouncedResults('');
    }
  };

  return (
    <>
      <Autocomplete
        className={props.className}
        id="asynchronous-demo"
        value={value}
        sx={{ width: '100%' }}
        open={open}
        onChange={(event: any, newValue: any) => {
          setValue(newValue);
          if (props?.type && props?.type === 'multiple') {
            props.onChange(newValue);
            setInputValue('');
          } else {
            props.onChange(newValue?.id);
            setInputValue(newValue.name);
          }
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          // setValue(null);
        }}
        onInputChange={changeInput}
        inputValue={inputValue}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
      {props?.error && (
        <FormHelperText className="errorMsg">{props?.error}</FormHelperText>
      )}
    </>
  );
}
