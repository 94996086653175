import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material';
// import FilterIcon from 'assets/images/bluefilter.svg';
import SearchBar from 'components/SearchBar/SearchBar';
import { PrivateRoute } from 'config';
import debouce from 'lodash.debounce';
import AddAndEditUser from 'pages/common/AddAndEditUser';
import Customer from 'pages/common/Customer';
import Ipwhitelist from 'pages/common/Ipwhitelist';
import Skills from 'pages/common/Skills';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMPANY_STATUS_FILTER,
  USER_FILTER_STATUS,
  USER_SEARCH,
} from 'store/types/UserTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { getRequest } from 'utils/Handlers';

const Header: React.FC<any> = (props) => {
  const apiUrl = process.env.REACT_APP_APIURL;

  const dispatch: any = useDispatch();
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSkills, setOpenSkills] = React.useState(false);
  const handleOpenSkills = () => setOpenSkills(true);
  const handleCloseSkills = () => setOpenSkills(false);
  const [openCustomer, setOpenCustomer] = React.useState(false);
  const handleOpenCustomer = () => setOpenCustomer(true);
  const handleCloseCustomer = () => setOpenCustomer(false);
  const [openIpWhitelist, setOpenIpWhitelist] = React.useState(false);
  const handleOpenIpWhitelist = () => setOpenIpWhitelist(true);
  const handleCloseIpWhitelist = () => setOpenIpWhitelist(false);
  const [comapanyList, setCompanyList] = React.useState([]);
  const { status, companyFilter }: any = useAppSelector(
    (store: any) => store.userLogin,
  );

  const [openCom, setOpenCom] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [portals, setPortals] = useState<
    Array<{
      id: number;
      name: string;
      status: boolean;
      url: string;
    }>
  >([]);

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenCom((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  const handleChange = (e: any) => {
    dispatch({
      payload: e.target.value,
      type: USER_SEARCH,
    });
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getCompanyList();
    }, 500);

    return () => {
      debouncedResults.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companyStatusChange = (sts: any) => {
    dispatch({
      payload: sts,
      type: COMPANY_STATUS_FILTER,
    });
    setOpenCom(!openCom);
  };

  const getCompanyList = () => {
    getAllListData(
      MASTER_ENDPOINT.Organization + '?order=asc&sortby=name',
    ).then((resp: any) => {
      setCompanyList(resp?.data);
    });
  };

  const statusChange = (sts: string) => {
    if (status !== sts) {
      dispatch({
        payload: sts,
        type: USER_FILTER_STATUS,
      });
    }
  };
  const searchClear = () => {
    dispatch({
      payload: '',
      type: USER_SEARCH,
    });
  };
  const [filterName, setFilterName] = useState('');

  const getPortalAccess = useCallback(async () => {
    try {
      const res = await getRequest({
        ssoLogin: true,
        url: `${apiUrl}auth/applications`,
      });
      setPortals(
        res && res.data && Array.isArray(res.data) && res.data.length
          ? res.data
          : [],
      );
    } catch (error) {
      console.warn(error);
    }
  }, [apiUrl]);

  useEffect(() => {
    getPortalAccess();
  }, [getPortalAccess]);
  useEffect(() => {
    if (companyFilter) {
      const fitem = comapanyList.find((item: any) => item.id === companyFilter);
      setFilterName(fitem?.name);
    } else {
      setFilterName('All');
    }
  }, [comapanyList, companyFilter]);

  return (
    <Typography component={'div'}>
      <Grid container>
        <Grid item md={8}>
          <Typography component={'div'} className="d-flex-a">
            <SearchBar onChange={debouncedResults} />
            {props.from === 'skills' ||
            props.from === 'customermanagement' ||
            props.from === 'ipwhitelist' ? (
              <Typography component={'div'}>
                <Typography className="d-flex-a">
                  <Link
                    to={PrivateRoute.SKILLS}
                    className={'sidebarA'}
                    style={{ color: 'transparent' }}>
                    <Button
                      className={
                        props.from === 'skills'
                          ? 'header-tab-btn-active'
                          : 'header-tab-btn-normal'
                      }
                      onClick={() => searchClear()}>
                      Skills
                    </Button>
                  </Link>
                  <Link
                    to={PrivateRoute.CUSTOMER}
                    className={'sidebarA'}
                    style={{ color: 'transparent' }}
                    onClick={() => searchClear()}>
                    <Button
                      className={
                        props.from === 'customermanagement'
                          ? 'header-tab-btn-active'
                          : 'header-tab-btn-normal'
                      }>
                      Customer Management
                    </Button>
                  </Link>
                  <Link
                    to={PrivateRoute.IPWHITELIST}
                    className={'sidebarA'}
                    style={{ color: 'transparent' }}>
                    <Button
                      className={
                        props.from === 'ipwhitelist'
                          ? 'header-tab-btn-active'
                          : 'header-tab-btn-normal'
                      }
                      onClick={() => searchClear()}>
                      IP Whitelists
                    </Button>
                  </Link>
                </Typography>
              </Typography>
            ) : ['recruiters', 'marketers', 'sales'].includes(props.from) ? (
              <Typography component={'div'}>
                <Typography className="d-flex-a">
                  <Button
                    className={
                      status === 'all'
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusChange('all');
                      searchClear();
                    }}>
                    All
                  </Button>
                  <Button
                    className={
                      status === 'active'
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusChange('active');
                      searchClear();
                    }}>
                    Active
                  </Button>
                  <Button
                    className={
                      status === 'inactive'
                        ? 'header-tab-btn-active'
                        : 'header-tab-btn-normal'
                    }
                    onClick={() => {
                      statusChange('inactive');
                      searchClear();
                    }}>
                    Inactive
                  </Button>
                </Typography>
              </Typography>
            ) : (
              <Typography component={'div'} />
            )}
          </Typography>
        </Grid>
        <Grid item md={4} className="d-flex-jae">
          {portals &&
            portals.map((e, i) => (
              <Typography
                component={e.id === 1 ? 'a' : 'div'}
                href={e.id === 1 ? e.url : ''}
                target={'_blank'}
                key={e.id}
                className={`sso-app ${
                  i === 0 && portals.length >= 2 ? 'left' : 'rigth'
                } ${e.id === 2 ? 'active-sso-portal' : ''}`}>
                {e.name}
              </Typography>
            ))}
          {['recruiters', 'marketers', 'sales', 'jobpost'].includes(
            props.from,
          ) ? (
            <Grid
              container
              justifyContent="right"
              sx={{
                paddingRight: '10px',
              }}>
              <ul className="filterlist">
                <li>
                  <Button
                    onClick={handleClick('bottom-end')}
                    className="header-company-btn">
                    Company: {filterName}
                    {open ? (
                      <KeyboardArrowUpIcon sx={{ color: '#a59999' }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ color: '#a59999' }} />
                    )}
                  </Button>
                </li>
              </ul>
              {openCom && (
                <ClickAwayListener onClickAway={() => setOpenCom(false)}>
                  <Popper
                    className="popwidget"
                    open={openCom}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Typography sx={{ p: 2 }}>
                            <Typography
                              onClick={() => companyStatusChange('')}
                              component={'p'}
                              sx={{
                                cursor: 'pointer',
                                margin: '10px',
                                marginLeft: '3px',
                              }}>
                              {'All'}
                            </Typography>
                            {comapanyList.map((item: any, index: number) => {
                              return (
                                <Typography
                                  key={index}
                                  onClick={() => companyStatusChange(item?.id)}
                                  component={'p'}
                                  sx={{
                                    cursor: 'pointer',
                                    margin: '10px',
                                    marginLeft: '3px',
                                  }}>
                                  {item?.name}
                                </Typography>
                              );
                            })}
                          </Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </ClickAwayListener>
              )}
            </Grid>
          ) : (
            <Typography />
          )}
          <Typography component={'div'} className="d-flex-a">
            {/* {props.from !== 'viewjob' && (
              <Button className="header-filter-box">
                <img src={FilterIcon} alt="Filter" />
              </Button>
            )} */}
            {
              // ['recruiters', 'marketers', 'sales'].includes(props.from) ? (
              //   <Button className="header-add-btn" onClick={handleOpen}>
              //     {props.from === 'recruiters'
              //       ? '+ Add Recruiter'
              //       : props.from === 'marketers'
              //       ? '+ Add Marketer'
              //       : '+ Add Sales'}
              //   </Button>
              // ) :
              props.from === 'skills' ? (
                <Button className="header-add-btn" onClick={handleOpenSkills}>
                  + Add Skill
                </Button>
              ) : props.from === 'customermanagement' ? (
                <Button className="header-add-btn" onClick={handleOpenCustomer}>
                  + Add Customer
                </Button>
              ) : props.from === 'ipwhitelist' ? (
                <Button
                  className="header-add-btn"
                  onClick={handleOpenIpWhitelist}>
                  + Add IP Address
                </Button>
              ) : (
                <Typography />
              )
            }
          </Typography>
        </Grid>
      </Grid>
      <AddAndEditUser
        visible={open}
        onClose={handleClose}
        title={
          props.from === 'recruiters'
            ? 'Add Recruiter'
            : props.from === 'marketers'
            ? 'Add Marketer'
            : 'Add Sales'
        }
        roleId={
          props.from === 'recruiters' ? 3 : props.from === 'marketers' ? 4 : 5
        }
      />
      <Skills visible={openSkills} onClose={handleCloseSkills} />
      <Customer visible={openCustomer} onClose={handleCloseCustomer} />
      <Ipwhitelist visible={openIpWhitelist} onClose={handleCloseIpWhitelist} />
    </Typography>
  );
};

export default Header;
