import Authsso from 'pages/public/AuthSSO';
import CreatePassword from 'pages/public/CreatePassword';
import ForgotPassword from 'pages/public/ForgotPassword';
// import Login from 'pages/public/Login';
import SSOLogin from 'pages/public/Login/ssoLogin';
import VersionHistory from 'pages/public/VersionHistory';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PublicRoutes as PublicRoutesDirectives } from '../index';

const PublicRoutes = () => {
  const { AUTHSSO, LOGIN, FORGOTPASSWORD, CREATEPASSWORD, VERSIONHISTORY } =
    PublicRoutesDirectives;
  return (
    <Routes>
      <Route path="/" element={<Navigate to={'/login'} replace />} />
      <Route path={LOGIN} element={<SSOLogin />} />
      <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
      <Route path={CREATEPASSWORD} element={<CreatePassword />} />
      <Route path={VERSIONHISTORY} element={<VersionHistory />} />
      <Route path={AUTHSSO} element={<Authsso />} />
      <Route path="*" element={<Navigate to={'/login'} />} />
    </Routes>
  );
};

export default PublicRoutes;
