import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  createPasswordWrap: {
    '@media (max-width: 767px)': {
      width: '90%',
    },
    borderRadius: '0px !important',
    borderTop: '8px solid #BF0000',
    height: '580px',
    margin: '0 auto',
    padding: '0 60px',
    position: 'relative',
    width: '594px',
  },
  custominput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f3f3f3 !important',
      padding: '12.5px 14px 12.5px 20px !important',
    },
    width: '100%',
  },
  footercopyright: {
    color: '#979598 !important',
    fontFamily: 'Red Hat Display SemiBold !important',
    fontSize: '12px !important',
    lineHeight: '1.5',
    textAlign: 'center',
  },
  formheight: {
    '& .MuiLoadingButton-loadingIndicator': {
      top: '50%',
      transform: 'translateY(-50%)',
    },
    backgroundColor: '#BF0000 !important',
    borderRadius: '8px !important',
    color: '#FFFFFF !important',
    display: 'table !important',
    fontFamily: 'Red Hat Display Bold !important',
    fontSize: '16px !important',
    height: '40px',
    margin: '30px auto 65px !important',
    width: '380px',
  },
  info: {
    alignItems: 'center',
    backgroundColor: '#eee',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'red !important',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  loginwrap: {
    '.MuiOutlinedInput-notchedOutline': {
      border: '1px solid transparent !important',
    },
    '@media (max-width: 767px)': {
      width: '90%',
    },
    borderRadius: '0px !important',
    borderTop: '8px solid #BF0000',
    height: '460px',
    margin: '0 auto',
    padding: '0 60px',
    position: 'relative',
    width: '594px',
  },
  passwordText: {
    color: '#a19fa1',
    fontFamily: 'Red Hat Display SemiBold',
    fontSize: 12,
    margin: 0,
    padding: 0,
    paddingBottom: 2,
    paddingTop: 2,
  },
  passwordinput: {
    '& .MuiInputLabel-root': {
      fontFamily: 'Red Hat Display Regular !important',
      lineHeight: 'unset !important',
    },
    '& .MuiOutlinedInput-input': {
      backgroundColor: '#f3f3f3 !important',
      padding: '12.5px 50px 12.5px 20px !important',
    },
    width: '100%',
  },
}));

export default useStyles;
