import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addNewData, updateData } from 'services/CommonServices';
import { USER_RELOAD } from 'store/types/UserTypes';
import { AddIpWhitelist } from 'types';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addIPWhitelistSchema } from 'utils/ValidatorSchema';

import useStyles from '../private/PrivateStyle';

interface UserProps {
  row?: any;
  editId?: string;
  visible: boolean;
  onClose: () => void;
}

const style = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  minHeight: 200,
  padding: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '200px',
  transform: 'translate(-50%, -60px)',
  width: 450,
};

const Ipwhitelist = (props: UserProps) => {
  const classes = useStyles();
  const dispatch: any = useDispatch();

  const { handleSubmit, control, formState, reset, setValue } =
    useForm<AddIpWhitelist>({
      defaultValues: {
        ipName: '',
      },
      reValidateMode: 'onChange',
      resolver: yupResolver(addIPWhitelistSchema),
    });

  useEffect(() => {
    if (props.row) {
      setValue('ipName', props.row?.ipName);
    }
  }, [props.row, setValue]);

  const submitForm = (data: any) => {
    const ipPayload = {
      ipName: data['ipName'].trim(),
    };
    if (props.editId) {
      ipPayload['status'] = props.row?.status;
      updateData(props.editId, ipPayload, MASTER_ENDPOINT.IpWhiteList, true)
        .then((resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
          resetForm();
        })
        .catch((err: any) => {});
    } else {
      ipPayload['status'] = true;
      addNewData(ipPayload, MASTER_ENDPOINT.IpWhiteList, {}, true)
        .then((resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
          resetForm();
        })
        .catch((err: any) => {});
    }
  };

  const resetForm = () => {
    reset();
    props.onClose();
  };

  return (
    <Modal
      open={props.visible}
      // onClose={resetForm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <IconButton
          aria-label="Close"
          onClick={resetForm}
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
          }}>
          <CloseIcon />
        </IconButton>
        <Typography className="recrutor-title">
          {props.editId ? 'Edit' : 'Add'} IP Address
        </Typography>
        <form onSubmit={handleSubmit(submitForm)}>
          <Grid container columnSpacing={2} rowSpacing={1.5}>
            <Grid item md={12} sm={12}>
              <Typography>
                <label>IP Address</label>
                <span className="errorText">{'*'}</span>
                <Controller
                  control={control}
                  defaultValue=""
                  name="ipName"
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className={classes.custominput}
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                        error={formState.errors?.ipName?.message}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Typography
            component={'div'}
            className="d-flex-a flex-sb"
            sx={{ marginTop: '30px !important' }}>
            <Button className="cancel-btn" onClick={resetForm}>
              Cancel
            </Button>
            <Button className="save-btn" type={'submit'}>
              Save
            </Button>
          </Typography>
        </form>
      </Box>
    </Modal>
  );
};

export default Ipwhitelist;
