import React from 'react';

export default function AnalyticsCheckboxSvg({
  Selected,
}: {
  Selected?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={{ cursor: 'pointer' }}>
      <rect
        width="15"
        height="15"
        x="0.5"
        y="0.5"
        fill="#fff"
        stroke="#000"
        rx="1.5"
      />
      <rect
        width="12"
        height="12"
        x="2"
        y="2"
        fill={Selected ? '#BF0000' : 'none'}
        rx="2"
      />
    </svg>
  );
}
