import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Avatar, Button, ClickAwayListener, Fade, Paper } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import notification from 'assets/images/notification.svg';
import profileimg from 'assets/images/profileimg.png';
import SidebarLogo from 'assets/images/RiseItblack.svg';
import { PrivateRoute } from 'config';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { USER_FILTER_STATUS, USER_SEARCH } from 'store/types/UserTypes';
import { MASTER_ENDPOINT } from 'utils/Constant';

import Header from './Header';

const drawerWidth = 221;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#ffffff',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: drawerWidth,
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#ffffff',
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const openedImage = (theme: Theme): CSSObject => ({
  display: 'table',
  margin: '25px 20px 30px 35px',
  width: '146px',
});

const closeImage = (theme: Theme): CSSObject => ({
  display: 'table',
  margin: '30px auto 40px',
  width: '95%',
});

const loginimgopen = (theme: Theme): CSSObject => ({
  display: 'flex',
  justifyContent: 'space-evenly',
});

const loginimgclose = (theme: Theme): CSSObject => ({
  display: 'table',
  margin: '0 auto',
  textAlign: 'center',
});

const verticalbaropen = (theme: Theme): CSSObject => ({
  borderLeft: '1px solid #fff',
  height: '30px',
  marginTop: '5px',
  opacity: '0.4',
});

const verticalbarclose = (theme: Theme): CSSObject => ({
  borderBottom: '1px solid #fff',
  margin: '10px 0 8px',
  opacity: '0.4',
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#fff',
  transition: theme.transitions.create(['width', 'margin'], {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  width: '95.5%',
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  boxSizing: 'border-box',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  width: drawerWidth,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    '& .sidebar-inner': openedImage(theme),
    '& .sidebaravatar': loginimgopen(theme),
    '& .vertical-bar': verticalbaropen(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    '& .sidebar-inner': closeImage(theme),
    '& .sidebaravatar': loginimgclose(theme),
    '& .vertical-bar': verticalbarclose(theme),
  }),
}));
const DrawerHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: '#fff',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MiniDrawer = (props) => {
  const dispatch: any = useDispatch();
  const [imageId, setImageId] = React.useState('');
  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [open1, setOpen1] = React.useState(false);
  const [placement1, setPlacement1] = React.useState<PopperPlacementType>();

  const handleClick1 =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
      setOpen1((prev) => placement1 !== newPlacement || !prev);
      setPlacement1(newPlacement);
    };

  const { userInfo }: any = useAppSelector((store: any) => store.userLogin);

  const updateSatus = () => {
    dispatch({
      payload: 'all',
      type: USER_FILTER_STATUS,
    });
  };

  const searchClear = () => {
    dispatch({
      payload: '',
      type: USER_SEARCH,
    });
  };
  React.useEffect(() => {
    setImageId(userInfo?.imageId);
  }, [userInfo?.imageId]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {props.showHeader && (
        <AppBar
          position="fixed"
          open={true}
          style={{
            boxShadow: 'unset',
            width:
              props.from === 'viewjob'
                ? '100%'
                : `calc(100% - ${drawerWidth}px)`,
          }}>
          <Toolbar sx={{ display: 'inline' }}>
            <Typography variant="h6" noWrap component="div">
              <Header from={props.from} />
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {props.from !== 'viewjob' && (
        <Drawer className="siderbg" variant="permanent" open={true}>
          <Typography component={'div'} className="d-flex-ja flex-column">
            <img className="sidebar-inner" src={SidebarLogo} alt="Riseit" />
          </Typography>
          <List className="sidebar-menu">
            <Link to={PrivateRoute.DASHBOARD} className={'sidebarA'}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'dashboard' ? 'sideNavactive' : ''}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>DashBoard</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={PrivateRoute.JOBPOST}
              className={'sidebarA'}
              onClick={() => searchClear()}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'jobpost' ? 'sideNavactive' : ''}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>Job Postings</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={PrivateRoute.RECRUITER}
              className={'sidebarA'}
              onClick={() => {
                updateSatus();
                searchClear();
              }}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'recruiters' ? 'sideNavactive' : ''}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>Recruiters</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={PrivateRoute.MARKETER}
              className={'sidebarA'}
              onClick={() => {
                updateSatus();
                searchClear();
              }}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'marketers' ? 'sideNavactive' : ''}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>Marketers</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={PrivateRoute.SALES}
              className={'sidebarA'}
              onClick={() => {
                updateSatus();
                searchClear();
              }}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'sales' ? 'sideNavactive' : ''}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>Sales</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={PrivateRoute.CANDIDATE}
              className={'sidebarA'}
              onClick={() => searchClear()}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={props.from === 'candidate' ? 'sideNavactive' : ''}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>Candidates</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={PrivateRoute.SKILLS}
              className={'sidebarA'}
              onClick={() => searchClear()}>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                className={
                  props.from === 'skills' || props.from === 'customermanagement'
                    ? 'sideNavactive'
                    : ''
                }>
                <ListItemButton
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    minHeight: 48,
                    px: 2,
                  }}>
                  <ListItemText>
                    <span>Settings</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
          <Box className="login-area">
            <Typography component={'div'} className="sidebaravatar">
              <Button
                sx={{
                  border: 'unset !important',
                  color: 'unset !important',
                }}
                variant="text"
                onClick={handleClick1('bottom-end')}>
                <img src={notification} alt="Riseit" />
                {/* <Typography
                component={'div'}
                sx={{
                  background: '#DB2426',
                  borderRadius: '4px',
                  height: '8px',
                  position: 'absolute',
                  right: '8px',
                  top: '0px',
                  width: '8px',
                }}
              /> */}
              </Button>
              <Typography component={'div'} className="vertical-bar" />
              <Link to={PrivateRoute.MYPROFILE} className={'sidebarA'}>
                <Typography component={'div'} className="d-flex-a">
                  <Avatar
                    alt="prifile"
                    src={
                      imageId
                        ? `${process.env.REACT_APP_APIURL}${
                            MASTER_ENDPOINT.Files
                          }/${String(imageId)}`
                        : profileimg
                    }
                    sx={{
                      background: '#e7e7e7',
                      borderRadius: '50%',
                      height: 36,
                      width: 36,
                    }}
                  />
                  <ArrowDropDownIcon sx={{ fill: '#C5C5C5' }} />
                </Typography>
              </Link>
            </Typography>
          </Box>
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1 }}>
        {props.showHeader && <DrawerHeader />}
        <Typography paragraph component={'div'}>
          {props.childComProps}
        </Typography>
      </Box>
      {open1 && (
        <ClickAwayListener onClickAway={() => setOpen1(false)}>
          <Popper
            sx={{
              zIndex: 10000,
            }}
            open={open1}
            anchorEl={anchorEl1}
            placement={placement1}
            transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className="model-widget">
                  <Typography
                    sx={{
                      maxHeight: '500px',
                      overflowY: 'scroll',
                      width: '486px',
                    }}
                    className="request-payment-alert">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2">
                      Notification
                    </Typography>
                    <Typography
                      component={'div'}
                      sx={{
                        cursor: 'pointer !important',
                      }}
                      className="total-payment">
                      <Typography
                        sx={{
                          fontSize: '15px !important',
                        }}>
                        New recruiter added
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className="total-payment" />

                    <Typography className="model-btn" component={'div'}>
                      <Button variant="text" onClick={() => setOpen1(false)}>
                        Cancel
                      </Button>
                      <Button variant="contained">Clear All</Button>
                    </Typography>
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default MiniDrawer;
