import React, { useEffect, useState } from 'react';

const ScrollWrapper = ({ children }) => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenResolution = window.devicePixelRatio * 100;
      console.log('window.devicePixelRatio: ', window.devicePixelRatio);
      console.log('screenResolution: ', screenResolution);

      setShowScroll(screenResolution >= 100);
    };

    handleResize(); // Call the function on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`scroll-wrapper ${showScroll ? 'show-scrollbar' : ''}`}>
      {children}
    </div>
  );
};

export default ScrollWrapper;
