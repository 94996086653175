import React from 'react';

export default function DustBinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="58"
      fill="none"
      viewBox="0 0 58 58">
      <path
        fill="#BF0000"
        d="M51.424 7.455l-1.13-4.215c-.54-2.017-3.09-2.625-5.244-2.048L34.336 4.063l-.418-1.559a2.141 2.141 0 00-1.005-1.308A2.142 2.142 0 0031.277.98l-9.322 2.499a2.142 2.142 0 00-1.308 1.005c-.289.5-.366 1.08-.217 1.636l.417 1.558-10.714 2.871c-1.025.275-1.993.84-2.656 1.55-.814.871-1.12 1.882-.862 2.846l1.13 4.215A1.348 1.348 0 009.06 20.18c.126 0 .254-.018.383-.052l1.355-.363 3.079 33.513c.2 2.175 2.025 3.815 4.245 3.815H42.83c2.22 0 4.045-1.64 4.246-3.815l3.316-36.104a.906.906 0 00-.903-.989h-25.33l26.28-7.042a1.369 1.369 0 00.986-1.69zM22.18 5.65a.344.344 0 01.036-.26.343.343 0 01.208-.162l9.322-2.498a.342.342 0 01.26.036c.047.027.13.09.161.208l.418 1.558-9.987 2.676-.418-1.558zm26.315 12.349L45.27 53.113c-.114 1.236-1.164 2.168-2.441 2.168H18.123c-1.277 0-2.327-.932-2.44-2.168L12.575 19.29l4.817-1.291h31.102zm-39.113.27l-1.016-3.794c-.122-.455.22-.91.435-1.14.432-.462 1.105-.85 1.801-1.036L45.52 2.943c1.377-.369 2.819-.004 3.025.766l1.017 3.794L9.381 18.27zm9.673 26.834a.906.906 0 00.902.823h3.762a.906.906 0 00.902-.989l-1.85-20.15a.906.906 0 00-.903-.823h-3.761a.907.907 0 00-.903.989l1.851 20.15zm1.986-19.327l1.684 18.338h-1.94L19.1 25.776h1.941zm7.537 20.15h3.746c.5 0 .905-.404.906-.904l.051-20.15a.907.907 0 00-.906-.908h-3.746c-.5 0-.905.404-.906.904l-.05 20.15a.906.906 0 00.905.908zm.955-20.15h1.933l-.046 18.338h-1.933l.046-18.338zm7.029 20.15h3.761c.468 0 .86-.356.902-.823l1.851-20.15a.907.907 0 00-.902-.99h-3.762a.906.906 0 00-.902.824l-1.85 20.15a.906.906 0 00.902.99zm2.677-20.15h1.941l-1.684 18.338h-1.941l1.684-18.338z"
      />
    </svg>
  );
}
