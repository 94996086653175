// import { Button } from '@mui/material';
import React, { ReactElement } from 'react';

type ButtonProps = {
  name: string;
  onPress?: (src: any) => void;
  isStyle?: boolean;
  Icon?: any;
  width: string;
  svgStyle?: any;
};

export default function CommonUploadBtn(props: ButtonProps): ReactElement {
  const { name, onPress, Icon, width, svgStyle } = props;

  return (
    <div
      className="UploadBtnCommonStyle"
      onClick={onPress}
      style={width ? { minWidth: width } : {}}>
      {Icon && (
        <span className="uploadIconStyle" style={svgStyle ? svgStyle : {}}>
          <Icon />
        </span>
      )}
      <span className="uploadBtnCommon" style={{ flexShrink: 0 }}>
        {name}
      </span>
    </div>
  );
}
